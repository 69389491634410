import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import {Grid,Row,Col} from "react-bootstrap";

import Form, {
  ButtonItem,
  SimpleItem,
  Label,
  RequiredRule,
} from 'devextreme-react/form';

import {common} from 'react_library';

import Card from "components/Card/Card.jsx";

//import Button from "components/CustomButton/CustomButton.jsx";

class LoginPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.buttonOptions = {
      text: 'Login',
      type: 'default',
      useSubmitBehavior: true,    
    };

    this.state = this.initialState;

    this.handleSubmit = this.handleSubmit.bind(this);  
  }

  get initialState(){      
    return {
      cardHidden: true,
      login: {
        'username':'',
        'password':'',
        'aziendaId':''
      },
      homePage:"",
      redirectHome:false,
      redirectLock:false,
    };
  }  

  async componentWillMount() {      
    this._isMounted = true;

    const isLoggedIn=common.session.fn_isLogged();
    const isLockScreen=common.session.fn_isLockScreen();

    let aziendaId= await common.service.getAziendaId();
    let homePage= await common.service.getHomePage();    
    let debug= await common.service.getDebug();    

    if(isLoggedIn){  
      if(isLockScreen){
        this.setRedirectLock();
      }
      else{
        this.setRedirectHome(homePage);
      }
    }
    else{
      common.session.fn_saveSessionGetPost(this.props);

      //let isGestionale=common.session.fn_isGestionale();      
      //const isSetCliente= common.session.fn_isSetCliente();
      //const credentialInBackground=common.session.fn_credentialInBackground();

      localStorage.clear();
    }

    
    let login = this.state.login;
    if(debug=="true"){
      login.username="BEONE";
      login.password="BEONE";
    }    
    login.aziendaId=aziendaId;      

    setTimeout(
        function() {
          if(this._isMounted){
            let stato = this.state;
            stato.cardHidden=false;   
            stato.login=login;        
            this.setState(stato);
          }                   
        }.bind(this),
        700
    );        
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setRedirectHome(homePage){
    if(this._isMounted){
      let stato= this.state;
      stato.redirectHome=true;
      stato.homePage=homePage;    
      this.setState(stato);    
    }
  }

  setRedirectLock(){
    if(this._isMounted){
      let stato= this.state;
      stato.redirectLock=true;
      this.setState(stato);    
    }
  }

  render() {    
    let login =  this.state.login;    
    const redirectHome = this.state.redirectHome;
    const redirectLock=this.state.redirectLock;
    const homePage=this.state.homePage;

    this.usernameOption={value: login.username};
  
    this.passwordOptions = { mode: 'password', value: login.password };

    if(redirectHome){    
      return <Redirect to={homePage}/>       
    }      
    else if(redirectLock){
      return <Redirect to="/auth/lock-screen"/>       
    }
    else{
      return (
        <Grid>
          <Row>
            <Col md={4} sm={6} mdOffset={4} smOffset={3}>
              <form action="" onSubmit={this.handleSubmit}>
                  <Card
                  hidden={this.state.cardHidden}
                  textCenter
                  title="Login"
                  content={
                        <Form
                        formData={this.state.login}
                        readOnly={false}
                        showColonAfterLabel={true}
                        showValidationSummary={false}
                        labelLocation="top"
                        validationGroup="loginData"
                      >                        
                        <SimpleItem dataField="username" editorType="dxTextBox" editorOptions={this.usernameOption} >
                          <Label text="USERNAME"/>                        
                          <RequiredRule message="Username obbligatorio" />                
                        </SimpleItem>  
                        
                        <SimpleItem dataField="password" editorType="dxTextBox" editorOptions={this.passwordOptions}>
                          <Label text="PASSWORD"/>
                          <RequiredRule message="Password obbligatoria" />                
                        </SimpleItem>                                                                          

                        <ButtonItem horizontalAlignment="center" 
                            buttonOptions={this.buttonOptions}
                          />
                                
                      </Form>
                  }               
                  ftTextCenter
                />
              </form>
            </Col>
          </Row>
        </Grid>
      );
    }
  }


  handleSubmit(e) {
    let login=this.state.login;
    let thisClass=this;

    common.service.postData(this,'/access/getInfo', {data:login})
        .then(data => {     
          common.notify.notifyServerResponse(thisClass,data, "", function(result){thisClass.afterLogin(result)});          
    });

    e.preventDefault();
  }

  afterLogin(data){
    /*var hours = 24; // Reset when storage is more than 24hours
    var now = new Date().getTime();
    var setupTime = localStorage.getItem('setupTime');
    if (setupTime == null) {
        localStorage.setItem('setupTime', now)
    } else {
        if(now-setupTime > hours*60*60*1000) {
            localStorage.clear()
            localStorage.setItem('setupTime', now);
        }
    }*/


    const serverResponse= data["default"];

    serverResponse["password"]=this.state.login.password;

    // Setto in sessione le variabili di login
    common.session.fn_saveSession(serverResponse);

    // Location reload con refresh della pagina
    window.location.reload(true);
    //this.setRedirectHome();
  }

}

export default LoginPage;