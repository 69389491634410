"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  /**
   * Data una stringa estrae la prima lettera di ciascuna parola
   * @param {array} string:  stringa di input
   */
  getAcronym: function getAcronym(string) {
    var text = '';
    var arr = string.split(' ');

    for (var i = 0; i < arr.length; i++) {
      if (arr.length === 1) {
        text += arr[i].substr(0, 2);
      } else {
        text += arr[i].substr(0, 1);
      }
    }

    return text;
  },

  /**
   * Controlla se la stringa è vuota
   * @param {string} stringa
   * @returns 
   */
  isEmpty: function isEmpty(stringa) {
    if (stringa == null || stringa == "" || stringa == undefined) {
      return true;
    } else {
      return false;
    }
  },

  /**
   * Ritorna la stringa con la lettera maiuscola
   * @param {string} string 
   * @returns 
   */
  capitalizeFirstLetter: function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  replaceAll: function replaceAll(string, replace, replaceWith) {
    var regExpQuot = new RegExp(replace, "g");
    string = string.replace(regExpQuot, replaceWith);
    return string;
  }
};
exports.default = _default;