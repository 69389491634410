import { Col } from "react-bootstrap";

import React, { Component } from "react";
import UserCard from "components/Card/UserCard.jsx";

import errorImg from "assets/img/error.png";
import errorBg from "assets/img/error_bg.jpg";

class CardError extends Component {
  render() {
    return (
      <div className="main-content">
      <Col md={12}>
        <UserCard
          bgImage={errorBg}
          avatar={errorImg}
          name="Errore nel caricamento della pagina"                      
          description={this.props.message}
          socials=""
        />
      </Col>
    </div>
    );
  }
}

export default CardError;
