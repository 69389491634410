/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {common} from 'react_library';


//import Dashboard from "views/Dashboard.jsx";
import LoginPage from "views/User/LoginPage.jsx";
import LoginBackgroundPage from "views/User/LoginBackgroundPage.jsx";
import LoginRestorePage from "views/User/LoginRestorePage.jsx";
import LockScreenPage from "views/User/LockScreenPage.jsx";

import ProfileGet from "views/Account/ProfileGet.jsx";
import AccountGet from "views/Account/AccountGet.jsx";

import ErrorPage from "views/Error/ErrorPage.jsx";
import ErrorPermission from "views/Error/ErrorPermission.jsx"

import TicketGrid from "views/Ticket/TicketGrid.jsx";
import TicketGet from "views/Ticket/TicketGet.jsx";

import CustomerGrid from "views/Customer/CustomerGrid.jsx";
import CustomerGet from "views/Customer/CustomerGet.jsx";

import LicenseGet from "views/License/LicenseGet.jsx";
import LicenseView from "views/License/LicenseView.jsx";

import DemoList from "views/Demo/DemoList.jsx";
import DemoGet from "views/Demo/DemoGet.jsx";

import CourseList from "views/Course/CourseList.jsx";
import CourseGet from "views/Course/CourseGet.jsx";

import VersionList from "views/Version/VersionList.jsx";
import VersionGet from "views/Version/VersionGet.jsx";

import VideoList from "views/Video/VideoList.jsx";
import VideoGrid from "views/Video/VideoGrid.jsx";
import VideoGet from "views/Video/VideoGet.jsx";

import ProductList from "views/Product/ProductList.jsx";
import ProductGrid from "views/Product/ProductGrid.jsx";
import ProductGet from "views/Product/ProductGet.jsx";

import TrainingList from "views/Training/TrainingList.jsx";
import TrainingGrid from "views/Training/TrainingGrid.jsx";
import TrainingGet from "views/Training/TrainingGet.jsx";

import PriceListList from "views/PriceList/PriceListList.jsx";
import PriceListGrid from "views/PriceList/PriceListGrid.jsx";
import PriceListGet from "views/PriceList/PriceListGet.jsx";

import ContactList from "views/Contact/ContactList.jsx";
import ContactGrid from "views/Contact/ContactGrid.jsx";
import ContactGet from "views/Contact/ContactGet.jsx";

import ManualList from "views/Manual/ManualList.jsx";
import ManualGrid from "views/Manual/ManualGrid.jsx";
import ManualGet from "views/Manual/ManualGet.jsx";

import MaterialList from "views/Material/MaterialList.jsx";
import MaterialGrid from "views/Material/MaterialGrid.jsx";
import MaterialGet from "views/Material/MaterialGet.jsx";

import NewsList from "views/News/NewsList.jsx";
import NewsGrid from "views/News/NewsGrid.jsx";
import NewsGet from "views/News/NewsGet.jsx";

import TaxList from "views/Tax/TaxList.jsx";
import TaxGrid from "views/Tax/TaxGrid.jsx";
import TaxGet from "views/Tax/TaxGet.jsx";

import ProductNewsList from "views/ProductNews/ProductNewsList.jsx";
import ProductNewsGrid from "views/ProductNews/ProductNewsGrid.jsx";
import ProductNewsGet from "views/ProductNews/ProductNewsGet.jsx";

import CodeList from "views/Code/CodeList.jsx";
import CodeGrid from "views/Code/CodeGrid.jsx";
import CodeGet from "views/Code/CodeGet.jsx";

import Dashboard from "views/Dashboard/Dashboard.jsx";
import ProjectGrid from "views/Project/ProjectGrid";
import ActivityGrid from "views/Activity/ActivityGrid";
import InvoiceGrid from "views/Invoice/InvoiceGrid";


let sessionMenu= localStorage.getItem("menu");
let objMenu= JSON.parse(sessionMenu);

const funzionalita= localStorage.getItem("funzionalita");
let funzionalitaObj=JSON.parse(funzionalita);

let menu=[];


const ComponentsMenu = {
  TICKET_GRID: ["ticket/gridP", TicketGrid],    
  CUSTOMER_GRID: ["customer/gridP", CustomerGrid],    
  DEMO_LIST: ["demo/listP", DemoList],    
  COURSE_LIST: ["course/listP", CourseList],    
  VERSION_LIST: ["version/listP", VersionList],    
  VIDEO_GRID: ["contentVideo/gridP", VideoGrid],    
  VIDEO_LIST: ["contentVideo/listP", VideoList],    
  PRODUCT_GRID: ["contentProduct/gridP", ProductGrid],    
  PRODUCT_LIST: ["contentProduct/listP", ProductList],    
  TRAINING_GRID: ["contentTraining/gridP", TrainingGrid],    
  TRAINING_LIST: ["contentTraining/listP", TrainingList],    
  PRICELIST_GRID: ["contentPriceList/gridP", PriceListGrid],    
  PRICELIST_LIST: ["contentPriceList/listP", PriceListList],    
  CONTACT_GRID: ["contentContact/gridP", ContactGrid],    
  CONTACT_LIST: ["contentContact/listP", ContactList],    
  MANUAL_GRID: ["contentManual/gridP", ManualGrid],    
  MANUAL_LIST: ["contentManual/listP", ManualList],    
  MATERIAL_GRID: ["contentMaterial/gridP", MaterialGrid],    
  MATERIAL_LIST: ["contentMaterial/listP", MaterialList],    
  NEWS_GRID: ["contentNews/gridP", NewsGrid],    
  NEWS_LIST: ["contentNews/listP", NewsList],    
  PRONEWS_GRID: ["contentProductNews/gridP", ProductNewsGrid],    
  PRONEWS_LIST: ["contentProductNews/listP", ProductNewsList],    
  TAX_GRID: ["contentTax/gridP", TaxGrid],    
  TAX_LIST: ["contentTax/listP", TaxList],    
  CODE_GRID: ["contentCode/gridP", CodeGrid],    
  CODE_LIST: ["contentCode/listP", CodeList],   
  DASH_VIEW: ["dashboard/dashboardP", Dashboard],   
  PROJECT_GRID: ["project/gridP", ProjectGrid],   
  RAP_GRID: ["activity/gridP", ActivityGrid],   
  INVOICE_GRID: ["documentInvoice/gridP", InvoiceGrid],  
};

// Estraggo e valorizzo i menu
getMenu(objMenu, "", menu);




function getMenu(objMenu, father,menu){
  for(let key in objMenu)
  {        
      let insert=true;
      let menuItem = objMenu[key];
      if(menuItem["idSottomenu"]===undefined){
        menuItem["idSottomenu"]="";
      }

      if(menuItem["idSottomenu"]===father)
      {
        let menuRecord= {};
        menuRecord["icon"]=menuItem["icon"];
        menuRecord["name"]=menuItem["label"];
      
        if(common.array.existObjectByKey(objMenu, "idSottomenu", menuItem["idMenu"])){
            menuRecord["views"]=[];
            getMenu(objMenu, menuItem["idMenu"],menuRecord["views"]);        
            menuRecord["collapse"]=true;      
            menuRecord["path"]="/"+menuItem["idMenu"];       
        }
        else{     
          menuRecord["layout"]="/portal";        
      
          if(common.service.checkFunctionality(menuItem["funzionalita"]))
          {
            if(menuItem["funzionalita"]!=="" && ComponentsMenu[menuItem["funzionalita"]]!==undefined){
              let element= ComponentsMenu[menuItem["funzionalita"]];

              menuRecord["path"]="/"+element[0]; 
              menuRecord["component"]=element[1]; 
              //menuRecord["component"]=ComponentsMenu[menuItem["funzionalita"]]
            }
            else{
              menuRecord["path"]="/error-page";   
              menuRecord["component"]=ErrorPage;    
            }
          }
          else{
            insert=false;
            menuRecord["path"]="/error-permissions";   
            menuRecord["component"]=ErrorPermission;    
          }           
        }
        menuRecord["state"]="open"+menuItem["label"];     
        if(insert){
          menu.push(menuRecord);
        }        
      }    
  }
}

// Aggiunge le routes di base del sito
menu.push( 
  {
    viewMenu:false,
    path: "/login",
    layout: "/backAuth",
    name: "Login",  
    component: LoginBackgroundPage
  }, 
  {
    viewMenu:false,
    path: "/login",
    layout: "/auth",
    name: "Login",  
    component: LoginPage
  }, 
  {
    viewMenu:false,
    path: "/user-page",
    layout: "/portal",
    name: "Profilo",
    component: ProfileGet
  },  
  {
    viewMenu:false,
    path: "/account-page",
    layout: "/portal",
    parent:"/portal/user-page",
    name: "Account",
    component: AccountGet
  },  
  {
    viewMenu:false,
    path: "/restore-page",
    layout: "/auth",
    name: "Ripristina",
    component: LoginRestorePage
  },  
  {
    viewMenu:false,
    path: "/lock-screen",
    layout: "/auth",
    name: "Blocca schermo",
    component: LockScreenPage
  },
  {
    viewMenu:false,
    path: "/ticket/get",
    layout: "/portal",
    parent:"/portal/ticket/gridP",
    name: "Ticket",
    component: TicketGet
  },  
  {
    viewMenu:false,
    path: "/customer/get",
    layout: "/portal",    
    parent:"/portal/customer/gridP",
    name: "Clienti",
    component: CustomerGet
  },
  {
    viewMenu:false,
    path: "/licenseKey/get",    
    parent:"/portal/customer/getClientPortal",
    layout: "/portal",
    name: "Licenze di attivazione",
    component: LicenseGet
  },
  {
    viewMenu:false,
    path: "/licenseKey/view",    
    layout: "/portal",
    parent:"/portal/user-page",
    name: "Licenze",
    component: LicenseView
  },
  {
    viewMenu:false,
    path: "/demo/get",    
    layout: "/portal",
    parent:"/portal/demo/listP",
    name: "Richiesta demo",
    component: DemoGet
  },
  {
    viewMenu:false,
    path: "/course/get",    
    layout: "/portal",
    parent:"/portal/course/listP",
    name: "Corsi",
    component: CourseGet
  },
  {
    viewMenu:false,
    path: "/version/get",    
    layout: "/portal",
    parent:"/portal/version/listP",
    name: "Versioni",
    component: VersionGet
  },
  /*{
    viewMenu:false,
    path: "/priceList/ERPONE",    
    layout: "/portal",
    parent:"/portal/priceList/getPriceListPortal",
    name: "Listini",
    component: PriceErpone
  },
  {
    viewMenu:false,
    path: "/priceList/CALYPSO",    
    layout: "/portal",
    parent:"/portal/priceList/getPriceListPortal",
    name: "Listini",
    component: PriceCalypso
  },
  {
    viewMenu:false,
    path: "/priceList/EFE",    
    layout: "/portal",
    parent:"/portal/priceList/getPriceListPortal",
    name: "Listini",
    component: PriceEfe
  },
  {
    viewMenu:false,
    path: "/priceList/XORDER",    
    layout: "/portal",
    parent:"/portal/priceList/getPriceListPortal",
    name: "Listini",
    component: PriceXorder
  },
  {
    viewMenu:false,
    path: "/priceList/XWRM",    
    layout: "/portal",
    parent:"/portal/priceList/getPriceListPortal",
    name: "Listini",
    component: PriceWrm
  },
  {
    viewMenu:false,
    path: "/priceList/OPB2B",    
    layout: "/portal",
    parent:"/portal/priceList/getPriceListPortal",
    name: "Listini",
    component: PriceB2B
  },*/ 
  {
    viewMenu:false,
    path: "/contentVideo/get",    
    parent:"/portal/contentVideo/gridP",
    layout: "/portal",    
    name: "Video",
    component: VideoGet
  }, 
  {
    viewMenu:false,
    path: "/contentProduct/get",    
    parent:"/portal/contentProduct/gridP",
    layout: "/portal",    
    name: "Prodotti",
    component: ProductGet
  }, 
  {
    viewMenu:false,
    path: "/contentTraining/get",    
    parent:"/portal/contentTraining/gridP",
    layout: "/portal",    
    name: "Formazione",
    component: TrainingGet
  }, 
  {
    viewMenu:false,
    path: "/contentPriceList/get",    
    parent:"/portal/contentPriceList/gridP",
    layout: "/portal",    
    name: "Listini",
    component: PriceListGet
  }, 
  {
    viewMenu:false,
    path: "/contentContact/get",    
    parent:"/portal/contentContact/gridP",
    layout: "/portal",    
    name: "Contatti",
    component: ContactGet
  }, 
  {
    viewMenu:false,
    path: "/contentManual/get",    
    parent:"/portal/contentManual/gridP",
    layout: "/portal",    
    name: "Manuali",
    component:ManualGet
  }, 
  {
    viewMenu:false,
    path: "/contentMaterial/get",    
    parent:"/portal/contentMaterial/gridP",
    layout: "/portal",    
    name: "Materiale",
    component:MaterialGet
  },   
  {
    viewMenu:false,
    path: "/contentCode/get",    
    parent:"/portal/contentCode/gridP",
    layout: "/portal",    
    name: "Code-x-change",
    component:CodeGet
  },   
  {
    viewMenu:false,
    path: "/contentNews/get",    
    parent:"/portal/contentNews/gridP",
    layout: "/portal",    
    name: "News",
    component:NewsGet
  }, 
  {
    viewMenu:false,
    path: "/contentTax/get",    
    parent:"/portal/contentTax/gridP",
    layout: "/portal",    
    name: "Info fiscali",
    component:TaxGet
  }, 
  {
    viewMenu:false,
    path: "/contentProductNews/get",    
    parent:"/portal/contentProductNews/gridP",
    layout: "/portal",    
    name: "News prodotti",
    component:ProductNewsGet
  }, 
);


var routes= menu;

export default routes;
