"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var TokenClass = function TokenClass(appcode) {
  var userid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  var scope = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
  var odbc = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var permission = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];

  _classCallCheck(this, TokenClass);

  _defineProperty(this, "appcode", "");

  _defineProperty(this, "userid", "");

  _defineProperty(this, "scope", "");

  _defineProperty(this, "odbc", {});

  _defineProperty(this, "permission", []);

  this.appcode = appcode.trim();
  this.userid = userid.trim();
  this.scope = scope.trim();
  this.odbc = odbc;
  this.permission = permission;
};

var _default = TokenClass;
exports.default = _default;