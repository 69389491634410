import React, { Component } from "react";

class CardContent extends Component {
    render(){
      return (
        <div> 
          <div className="mdc-card">
            <div className="body">
              <h2 className="title">{this.props.titolo}</h2>
              <div className="video" hidden={this.props.video==="" || this.props.video===undefined}>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe className='embed-responsive-item' src={this.props.video} title={this.props.video}></iframe>
                </div>
              </div>
              <p className={"text "+this.props.align} dangerouslySetInnerHTML={{__html: this.props.testo}}></p>
              <div className="button" hidden={this.props.link==="" || this.props.link===undefined}><a href={this.props.link} download={this.props.linkName} className="btn btnContent">DOWNLOAD</a></div>
              <div className="image" hidden={this.props.image==="" || this.props.image===undefined}><img alt="" src={this.props.image}></img></div>              
            </div>
          </div>                                                                        
        </div>
        );
    } 

    clickElement(e){
      //his.props.action(this.props.parentKey);
    }
    
}


export default CardContent;