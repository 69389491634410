"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _notify = _interopRequireDefault(require("devextreme/ui/notify"));

var _dialog = require("devextreme/ui/dialog");

var _loader = _interopRequireDefault(require("./loader"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = {
  fn_notify: function fn_notify(message) {
    (0, _notify.default)({
      message: message
    }, 'success', 5000);
  },
  fn_error: function fn_error(message) {
    (0, _notify.default)({
      message: message
    }, 'error', 5000);
  },
  fn_warning: function fn_warning(message) {
    (0, _notify.default)({
      message: message
    }, 'warning', 5000);
  },
  fn_info: function fn_info(message) {
    (0, _notify.default)({
      message: message
    }, 'info', 5000);
  },
  fn_confirm: function fn_confirm(message) {
    var callbackOk = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
    var callbackError = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
    var myDialog = (0, _dialog.custom)({
      title: "Attenzione",
      messageHtml: message,
      buttons: [{
        text: "Si",
        onClick: function onClick(e) {
          return true;
        }
      }, {
        text: "No",
        onClick: function onClick(e) {
          return false;
        }
      }]
    }); //let result = confirm(message, "Attenzione");      

    myDialog.show().then(function (dialogResult) {
      if (dialogResult) {
        if (callbackOk) {
          callbackOk();
        }
      } else {
        if (callbackError) {
          callbackError();
        }
      }
    });
  },
  notifyServerResponse: function notifyServerResponse(component, data, messageOk) {
    var callbackOk = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    var messageError = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
    var callbackError = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
    var returnMsg = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;

    _loader.default.hideLoadPanel(component);

    if (data === undefined) {
      var errorMsg = "Errore di collegamento con il server. Contatta l'amministratore del sito";

      if (returnMsg) {
        return errorMsg;
      } else {
        this.fn_error(errorMsg);
        if (callbackError) callbackError();
      }
    } else if (data.code === 0) {
      if (callbackOk) callbackOk(data["data"]);

      if (messageOk !== "") {
        this.fn_notify(messageOk);
      }
    } else {
      if (returnMsg) {
        return data.message;
      } else {
        if (messageError !== "") {
          this.fn_error(messageError);
        } else {
          this.fn_error(data.message);
        }

        if (callbackError) {
          callbackError(data);
        }
      }
    }
  },
  notifyFileResponse: function notifyFileResponse(component, data, messageOk) {
    var callbackOk = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    var messageError = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
    var callbackError = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;

    _loader.default.hideLoadPanel(component);

    var self = this;

    if (data === undefined) {
      self.fn_error("Errore di collegamento con il server. Contatta l'amministratore del sito");
      if (callbackError) callbackError();
    } else {
      // Se il file è di tipo json controllo se contiene un messaggio di errore
      if (data.type === "application/json") {
        var reader = new FileReader();
        reader.readAsText(data);

        reader.onloadend = function () {
          var content = JSON.parse(reader.result);

          if (content.message !== undefined && content.message !== "") {
            if (messageError !== "") {
              self.fn_error(messageError);
            } else {
              self.fn_error(content.message);
            }
          } else {
            callbackOk(data);

            if (messageOk !== "") {
              self.fn_notify(messageOk);
            }
          }
        };
      } else {
        callbackOk(data);

        if (messageOk !== "") {
          self.fn_notify(messageOk);
        }
      }
    }
  }
};
exports.default = _default;