"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  getBase64: function getBase64(file, cb) {
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      cb(reader.result);
    };

    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
};
exports.default = _default;