import React from "react";
import { Grid, Col, Row } from "react-bootstrap";
import PieChart, {
  Series,
  Label,
  Connector,
  Size,
  Export,
  Legend
} from 'devextreme-react/pie-chart';

import Card from "../../components/Card/Card.jsx";
import StatsCard from "../../components/Card/StatsCard.jsx";
import {common} from 'react_library';
import Loader from "../../myComp/Loader";
import PlusComponent from "../../myComp/PlusComponent";
import TileView from 'devextreme-react/tile-view';
import RenderNewsItem from './RenderNewsItem.js';
import RenderVideoItem from './RenderVideoItem.js';

class Dashboard extends PlusComponent {
  constructor(props) {
    super(props, "dashboard");
  }

  componentDidMount(){  
    this.refreshPagina();   
  }

  refreshPagina(){
    this.getData(false, {}, true);
  }

  refreshState(data){    
    let stato = this.state;  
    stato.dashboard=data;
    this.setState(stato);
  }

  get initialState(){       
    return {
      dashboard: {               
      },
    };
  }

  removeFromDashboardConfirm(widgetUuid, action){
    let thisClass=this;
    common.notify.fn_confirm("Sei sicuro di voler eliminare il componente dalla dashboard?", () => thisClass.removeFromDashboard(widgetUuid, action));    
  }

  removeFromDashboard(widgetUuid, action){   
    let thisClass=this;
    common.service.postData(this,"/"+action+"/"+widgetUuid)
        .then(data => {
          common.notify.notifyServerResponse(thisClass,data, "Dato memorizzato correttamente", function(){thisClass.refreshPagina(thisClass)});          
    });     
  }

  render() {
    const {dashboard} = this.state;                  

    var dataTicket = [];
    var dataProgetti = [];

    let ticketAperti =0;
    let ticketChiusi =0;
    let ticketRisolti=0;

    if(dashboard.ticket!==undefined){
      for(let i =0; i<dashboard.ticket.length; i++){        
        let valore= dashboard.ticket[i]["somma"];
        let stato= dashboard.ticket[i]["ticketStatus"];
        
        if(stato===2){
          ticketChiusi+=valore;
        }
        else if(stato===5){
          ticketRisolti+=valore;
        }
        else{
          ticketAperti+=valore;
        }    
      }

      dataTicket.push({"label":"Chiusi", "valore":ticketChiusi});
      dataTicket.push({"label":"Aperti", "valore":ticketAperti});
      dataTicket.push({"label":"Risolti", "valore":ticketRisolti});
    }


    let openProject =0;
    let closeProject =0;

    if(dashboard.progetti!==undefined){
      for(let i =0; i<dashboard.progetti.length; i++){        
        let valore= dashboard.progetti[i]["somma"];
        let stato= dashboard.progetti[i]["pmProjectStatus"];

        if(stato===1 || stato===3){
          closeProject+=valore;
        }    
        else{
          openProject+=valore;
        }    
      }

      dataProgetti.push({"label":"Aperti", "valore":openProject});
      dataProgetti.push({"label":"Chiusi", "valore":closeProject});
    }

    let numeroEventi=0;
    if(dashboard.corsi!==undefined){
      numeroEventi=dashboard.corsi.length;
    }

    let numeroProdotti=0;
    if(dashboard.prodotti!==undefined){
      numeroProdotti=dashboard.prodotti.length;
    }

    let newsItem=[];
    if(dashboard.news!==undefined && common.service.checkFunctionality("NEWS_LIST")){
      for(let i =0; i<dashboard.news.length; i++){    
        dashboard.news[i]["elementType"]="NEWS";    
        dashboard.news[i]["permissionRemove"]=common.service.checkFunctionality("NEWS_GRID");
        dashboard.news[i]["actionRemoveDashboard"]="contentNews/removeDashboard";
        dashboard.news[i]["component"]=this;
        newsItem.push(dashboard.news[i]);
      }
      
    }

    if(dashboard.productNews!==undefined  && common.service.checkFunctionality("PRONEWS_LIST")){
      for(let i =0; i<dashboard.productNews.length; i++){        
        dashboard.productNews[i]["elementType"]="PRODOTTI";
        dashboard.productNews[i]["permissionRemove"]=common.service.checkFunctionality("PRONEWS_GRID");
        dashboard.productNews[i]["actionRemoveDashboard"]="contentProductNews/removeDashboard";
        dashboard.productNews[i]["component"]=this;
        newsItem.push(dashboard.productNews[i]);
      }      
    }

    if(dashboard.tax!==undefined  && common.service.checkFunctionality("TAX_LIST")){
      for(let i =0; i<dashboard.tax.length; i++){      
        dashboard.tax[i]["elementType"]="FISCALE";  
        dashboard.tax[i]["permissionRemove"]=common.service.checkFunctionality("TAX_GRID");
        dashboard.tax[i]["actionRemoveDashboard"]="contentTax/removeDashboard";
        dashboard.tax[i]["component"]=this;
        newsItem.push(dashboard.tax[i]);
      }      
    }

    newsItem.sort(function (a, b) {
      var keyA = new Date(a.creationDate),
          keyB = new Date(b.creationDate);
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });


    let videoItem=[];
    if(dashboard.video!==undefined && common.service.checkFunctionality("VIDEO_LIST")){
      for(let i =0; i<dashboard.video.length; i++){      
        if(dashboard.video[i]["widgetType"]==="VIDEO"){       
          dashboard.video[i]["permissionRemove"]=common.service.checkFunctionality("VIDEO_GRID");
          dashboard.video[i]["actionRemoveDashboard"]="contentVideo/removeDashboard";       
          dashboard.video[i]["component"]=this;
          videoItem.push(dashboard.video[i]);
        }
      }      
    }
      
    return (
      <div className="main-content">
        <Loader visible={this.state.loadPanelVisible}></Loader>
        <Grid fluid>
          <Row>
            <div hidden={!common.service.checkFunctionality("TICKET_GRID")}>
              <Col lg={3} sm={6}>
                <StatsCard
                  bigIcon={<i className="fa fa-headphones text-warning" />}
                  statsAction="/ticket/gridP"
                  controller={this}
                  statsText="Ticket aperti"
                  statsValue={ticketAperti}
                  statsIconText="Richieste di assistenza"
                />
              </Col>
            </div>

            <div hidden={!common.service.checkFunctionality("PROJECT_GRID")}>
              <Col lg={3} sm={6}>
                <StatsCard
                  bigIcon={<i className="fa fa-briefcase text-success" />}
                  statsAction="/project/gridP"
                  controller={this}
                  statsText="Progetti aperti"
                  statsValue={openProject}
                  statsIconText="Lavori in corso"
                />
              </Col>
            </div>

            <div hidden={!common.service.checkFunctionality("VERSION_LIST")}>
              <Col lg={3} sm={6}>
                <StatsCard
                  bigIcon={<i className="fa fa-download text-danger" />}
                  statsAction="/version/listP"
                  statsText="Prodotti"
                  controller={this}
                  statsValue={numeroProdotti}
                  statsIconText="Prodotti collegati"
                />
              </Col>
            </div>


            <div hidden={!common.service.checkFunctionality("COURSE_LIST")}>
              <Col lg={3} sm={6}>
                <StatsCard
                  bigIcon={<i className="fa fa-calendar text-info" />}
                  statsAction="/course/listP"
                  statsText="Prossimi eventi"
                  controller={this}
                  statsValue={numeroEventi}
                  statsIconText="In programmazione"
                />
              </Col>
            </div>
          </Row>
          
          <Row>
            <div hidden={!common.service.checkFunctionality("TICKET_GRID")}>
              <Col md={3} className="dashboard-card">
                <Card
                  title="Ticket"
                  category="Avanzamento di stato dei ticket"
                  content={
                    <PieChart
                      id="pie"
                      dataSource={dataTicket}
                      palette="Bright"
                    >
                      <Legend
                        orientation="horizontal"
                        itemTextPosition="right"
                        horizontalAlignment="center"
                        verticalAlignment="bottom"
                        columnCount={4} />

                        <Series
                          argumentField="label"
                          valueField="valore"
                        >
                        <Label visible={true}>
                          <Connector visible={true} width={1} />
                        </Label>
                      </Series>
              
                      <Size width={350} height={300}/>
                      <Export enabled={false} />
                    </PieChart>
                  }                
                  stats={
                    <div>
                      <i className="fa fa-question" /> Richieste di assistenza
                    </div>
                  }
                />
              </Col>
            </div>

            <div hidden={!common.service.checkFunctionality("PROJECT_GRID")}>
              <Col md={3} className="dashboard-card">
                <Card
                  title="Progetti"
                  category="Avanzamento di stato dei progetti"
                  content={
                    <PieChart
                      id="pie"
                      type="doughnut"
                      dataSource={dataProgetti}
                      palette="Material"
                    >
                      <Legend
                        orientation="horizontal"
                        itemTextPosition="right"
                        horizontalAlignment="center"
                        verticalAlignment="bottom"
                        columnCount={4} />

                        <Series
                          argumentField="label"
                          valueField="valore"
                        >
                        <Label visible={true}>
                          <Connector visible={true} width={1} />
                        </Label>
                      </Series>
              
                      <Size width={350} height={300}/>
                      <Export enabled={false} />
                    </PieChart>
                  }                
                  stats={
                    <div>
                      <i className="fa fa-briefcase" /> Lavori in corso
                    </div>
                  }
                />
              </Col>
            </div>

            <div hidden={!common.service.checkFunctionality("VERSION_LIST")}>
              <Col md={3} className="dashboard-card">
                <Card
                  title="Versioni"
                  category="Prodotti attivi e date di rilascio"
                  content={
                    <table className="table">
                      <tbody>
                      {dashboard.prodotti!==undefined && dashboard.prodotti.length>0 ? dashboard.prodotti.map((prodotto, i) => {                    
                        let ultimoRilascio= "";
                        let ultimaVersione="";
                        let nomeVersione="";

                        if(prodotto["versions"]!==undefined && prodotto["versions"][0] !==undefined){
                          ultimoRilascio=prodotto["versions"][0]["datarilascio_str"];
                          ultimaVersione=prodotto["versions"][0]["uuid"];
                          nomeVersione=prodotto["versions"][0]["nomeversione"];
                        }
                        return (
                          <tr key={i}>                      
                            <td><span className="linkSpan" onClick={() => this.goToVersion(ultimaVersione)}><i className="fa fa-windows" /><b>{prodotto["titolo"]}</b></span><br></br><span className="stats">{nomeVersione}</span></td>
                            <td className="td-actions text-right stats">    
                              {ultimoRilascio}             
                            </td>
                          </tr>
                        );
                      }): <tr><td>Nessun prodotto associato</td></tr>}
                      </tbody>
                    </table>
                  }
                  stats={
                    <div>
                      <i className="fa fa-refresh" /> Ultimi rilasci
                    </div>
                  }              
                />
              </Col>
            </div>

            <div hidden={!common.service.checkFunctionality("COURSE_LIST")}>
              <Col md={3} className="dashboard-card">
                <Card
                  title="Eventi"
                  category="Prossimi eventi in programma"
                  content={
                    <table className="table">
                      <tbody>
                      {dashboard.corsi!==undefined && dashboard.corsi.length>0 ? dashboard.corsi.map((evento, i) => {
                        let timerGiorni="00";
                        let timerOre="00";
                        //let timerMinuti="00";                      
                  
                        timerGiorni = evento.timerGiorni > 0 ? timerGiorni= evento.timerGiorni : "00";
                        timerOre = evento.timerOre > 0 ? timerOre= evento.timerOre : "00";
                        //timerMinuti = evento.timerMinuti > 0 ? timerMinuti= evento.timerMinuti : "00";

                        return (
                          <tr key={i}>                      
                            <td><i className="fa fa-history" />  <span className="linkSpan" onClick={() => this.goToEvent(evento["uuid"])}>{evento["corsoTitolo"]}</span></td>
                            <td className="td-actions text-right stats">                      
                            <span>{timerGiorni}</span> days <span>{timerOre}</span> hr
                            </td>
                          </tr>
                        );
                      }): <tr><td>Nessun evento in programma</td></tr>}
                      </tbody>
                    </table>
                  }
                  stats={
                    <div>
                      <i className="fa fa-calendar" /> In programmazione
                    </div>
                  }
                />
              </Col>
            </div>            
          </Row>

          
          <div hidden={newsItem.length<=0}>
            <Row>
              <Col md={12}>
                <Card
                  content={
                      <TileView
                        items={newsItem}
                        itemRender={RenderNewsItem}
                        height={310}
                        baseItemHeight={300}
                        baseItemWidth={200}
                        width='100%'
                        itemMargin={10}
                        direction="horizontal"
                      />
                  }
                />
              </Col>
            </Row>  
          </div>


          <div hidden={videoItem.length<=0}>
            <Row>
              <Col md={12}>
                <Card
                  content={
                      <TileView
                        items={videoItem}
                        itemRender={RenderVideoItem}
                        height={290}
                        baseItemHeight={280}
                        baseItemWidth={400}
                        width='100%'
                        itemMargin={10}
                        direction="horizontal"
                      />
                  }
                />
              </Col>
            </Row>  
          </div>

        </Grid>
      </div>
    );
  }
    
  goToEvent(uuid){
    this.props.history.push('/portal/course/get?uuid='+uuid);   
  }

  goToVersion(uuid){
    if(uuid!==""){
      this.props.history.push('/portal/version/get?uuid='+uuid);   
    }
  }

}



export default Dashboard;