import React from 'react';
import Form, {
  SimpleItem,
  Label,
  GroupItem
} from 'devextreme-react/form';
import { Grid, Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";
import ArrayStore from 'devextreme/data/array_store';
import 'devextreme-react/autocomplete';
import Loader from "../../myComp/Loader";
import PlusComponent from "../../myComp/PlusComponent";


class LicenseView extends PlusComponent {
  soggetto=null;

  constructor(props) {
    super(props, "licenseKey");

    const query = new URLSearchParams(this.props.location.search);
    this.soggetto = query.get('soggetto');   
  }

  componentDidMount(){
    this.getData(true);
  }

  refreshState(data){    
    let stato = this.state;
    stato.license=data.default[0];
    this.setState(stato);
  }

  get initialState(){       
    return {
      license: {},
      moduli:[],
    };
  }

  getModuliProdotto(){
    let stato = this.state;
  
    let prodottoid= stato.license.prodottoid;    

    if(prodottoid!=="" && stato.license.moduli!==undefined && stato.license.moduli[prodottoid]!==undefined){
      stato.moduli=stato.license.moduli[prodottoid];
    }
    else{
      stato.moduli=[];
    }

    this.setState(stato);    
  }


  render() {        
      const {license} = this.state;
      const {moduli}=this.state;
            
      let dataSourceProdotto = new ArrayStore({
        data: license.prodotti,
        key: 'prodottoid'
      });

      this.prodottoOption={
        dataSource:dataSourceProdotto,
        displayExpr:"prodottoid",
        valueExpr:"prodottoid",
        disabled:true,
        onValueChanged:this.getModuliProdotto.bind(this),
      };  
      

      return (
        <div className="main-content">
          <Loader visible={this.state.loadPanelVisible}></Loader>
          <div className="content">
          <Grid fluid>
            <Row>
              <Col md={8}>
                <Card
                  title="Moduli attivi"
                  ctAllIcons              
                  content={    
                    <div>
                        <form action="" onSubmit={this.handleSubmit} encType="multipart/form-data">
                          <Form
                            formData={license}
                            readOnly={false}
                            showColonAfterLabel={true}
                            showValidationSummary={false}
                            labelLocation="top"
                            validationGroup="accountData"
                          >
                            
                            <GroupItem colCount={1}>                           
                              <SimpleItem dataField="prodottoid" editorType="dxSelectBox" editorOptions={this.prodottoOption}>
                                <Label text="Prodotto"/>
                              </SimpleItem>                             
                            </GroupItem>                            

                            <GroupItem colCount={2}>        
                              <table className="tableData">
                                <thead>                                
                                  <tr className="row_gv_header">
                                    <td>Modulo</td>
                                    <td>Licenze attive</td>
                                  </tr>
                                </thead>
                                <tbody>{moduli.map((value, index) => {
                                      let uuid=value["uuid"];
                                      let moduloId=value["moduloId"];
                                      let titolo=value["titolo"];
                                      let attive=0;    
                                      
                                      if(this.state.license.moduli_attivi!==undefined && this.state.license.moduli_attivi[moduloId]){
                                        attive=this.state.license.moduli_attivi[moduloId]["moduloQTA"];                                        
                                      }

                                      return (
                                        <tr className="row_gv_modulo" key={uuid}>
                                          <td>{titolo}</td>                                         
                                          <td>{attive}</td>
                                        </tr>);
                                    })}</tbody>
                              </table>                        
                            </GroupItem>                                                                     
                          </Form>           
                        </form>                                   
                      </div>
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      );   
  }  
}

export default LicenseView;