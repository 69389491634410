"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _message = _interopRequireDefault(require("./message"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var ResultClass = function ResultClass(code) {
  var message = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  var data = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  _classCallCheck(this, ResultClass);

  _defineProperty(this, "code", _message.default.OK);

  _defineProperty(this, "message", "");

  _defineProperty(this, "data", {});

  this.code = code[0];

  if (_typeof(message) === 'object') {
    message = JSON.stringify(message);
  }

  if (message === "") {
    message = code[1];
  }

  this.message = message;
  this.data = data;
};

var _default = ResultClass;
exports.default = _default;