import React from 'react';
import Form, {
    ButtonItem,
    SimpleItem,
    Label,
    RequiredRule,GroupItem,EmailRule, StringLengthRule
  } from 'devextreme-react/form';
import { Grid, Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";
import {common} from 'react_library';
import ArrayStore from 'devextreme/data/array_store';
import { NavLink } from "react-router-dom";
import Loader from "../../myComp/Loader";
import PlusComponent from "../../myComp/PlusComponent";

import keyActive from "assets/img/icon_p2_key_green.png";
import keyNotActive from "assets/img/icon_p2_key.png";

class CustomerGet extends PlusComponent {
  
  constructor(props) {
    super(props, "customer");
  }

  componentDidMount(){
    this.getData(true);    
  }

  refreshState(data){    
    let stato = this.state;
    stato.client=data.default[0];
    this.setState(stato);
  }


  get initialState(){       
    return {
      client: {               
      },
    };
  }

  render() {        
      const {client} = this.state;
            
      let dataSourceProvincie = new ArrayStore({
        data: client.provincie,
        key: 'uuid'
      });

      this.provincieOption={
        dataSource:dataSourceProvincie,
        displayExpr:"display",
        valueExpr:"valore",
      };  
      
      return (
        <div className="main-content">
          <Loader visible={this.state.loadPanelVisible}></Loader>
          <div className="content">
          <Grid fluid>
            <Row>
              <Col md={8}>
                <Card
                  title="Cliente"
                  ctAllIcons              
                  content={    
                    <React.Fragment>
                      <form action="" onSubmit={this.handleSubmit} encType="multipart/form-data">
                        <Form
                          formData={client}
                          readOnly={false}
                          showColonAfterLabel={true}
                          showValidationSummary={false}
                          labelLocation="top"
                          validationGroup="accountData"
                        >
                          
                          <GroupItem colCount={2}>
                            <SimpleItem dataField="ragsoc" editorType="dxTextBox" editorOptions={this.disableOption}>
                              <Label text="Ragione sociale"/>
                              <RequiredRule message="Ragione sociale obbligatoria" />                
                            </SimpleItem>  

                            <SimpleItem dataField="piva" editorType="dxTextBox" editorOptions={this.disableOption}>
                              <Label text="Partita iva"/>
                              <RequiredRule message="Partita iva obbligatoria" />    
                              <StringLengthRule message="La partita iva deve contenere almeno 11 caratteri" min={11} />            
                            </SimpleItem>  
                          </GroupItem>

                          <GroupItem colCount={1}>
                            <SimpleItem dataField="indir" editorType="dxTextBox">
                              <Label text="Indirizzo"/>
                            </SimpleItem> 
                          </GroupItem>
                          
                          <GroupItem colCount={3}>
                            <SimpleItem dataField="cap" editorType="dxTextBox">
                              <Label text="Cap"/>                              
                            </SimpleItem> 

                            <SimpleItem dataField="local" editorType="dxTextBox">
                              <Label text="Località"/>
                            </SimpleItem> 

                            <SimpleItem dataField="prov" editorType="dxSelectBox" editorOptions={this.provincieOption}>
                              <Label text="Provincia"/>
                            </SimpleItem> 
                          </GroupItem>

                          <GroupItem colCount={2}>                                                      
                            <SimpleItem dataField="telefono" editorType="dxTextBox">
                              <Label text="Telefono"/>
                            </SimpleItem>

                            <SimpleItem dataField="email" editorType="dxTextBox">
                              <Label text="Email"/>
                              <EmailRule message="Email non valida" />                       
                            </SimpleItem>
                          
                          </GroupItem>

                          <GroupItem colCount={6}>  
                            <ButtonItem horizontalAlignment="left" location="right"
                              buttonOptions={this.buttonSaveOptions}
                            />
                          </GroupItem>                  
                        </Form>                    
                      
                      </form>
                    </React.Fragment>
                    }
                  />
                </Col>

                {!this.new ?
                  <Col md={4}>
                  <Card
                    title="Licenze"
                    ctAllIcons              
                    content={    
                      <div>                        
                        { client.license!==undefined ? client.license.map((value, index) => {
                            let uuid=value["requestId"];
                            let prodottoId = value["prodottoid"];
                            let datacrea = common.date.writePreviewDateTimeByDB(value["creationDate"]);
                            let rilasciata = value["rilasciata"];
                            let dataRilascio = common.date.writePreviewDateTimeByDB(value["rilasciodata"]);

                            return (
                              <div className="listaChiavi" key={uuid}>
                                <div className="divRichiesta">
                                  <div className="myCard">
                                    <div className="card-header">
                                      <div className="media align-items-center">
                                          <div className="media-left">
                                            <img className="rounded-circle" width="50" src={rilasciata===1 ? keyActive : keyNotActive} alt=""></img>
                                          </div>
                                          <div className="media-body">
                                            <h4 className="card-title ragioneSociale">
                                              {prodottoId.toUpperCase()}
                                            </h4>
                                            <p className="card-subtitle">
                                              DATA CREAZIONE: <span className="piva">{datacrea}</span></p>
                                              <p className="card-subtitle">
                                                {rilasciata===1 ?
                                                  <span className='rilasciata'>RILASCIATA {dataRilascio}</span>
                                                  :
                                                  <span className='nonRilasciata'>DA RILASCIARE</span>}
                                              </p>
                                          </div>
                                          <div className="media-right" hidden={this.checkFunctionality("LICENSE_DETAIL")}>
                                            <div className="btn btn-secondary btn-sm calendarButtons btnFunctions_ btnLink" onClick={() => this.goToKey(uuid, rilasciata)}>
                                              <i className="fa fa-angle-right"></i>                                              
                                            </div>
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                  
                              </div>
                            )              
                        }) :''}

                        <div hidden={this.checkFunctionality("LICENSE_NEW")}>
                            <NavLink
                                to={{pathname:"/portal/licenseKey/get", search: "?soggetto="+this.uuid}}
                                className="btn btn-warning btn-wd btn-sm"
                                activeClassName="active"
                            >Nuovo</NavLink>         
                          </div>
                      </div>
                      }
                    />
                  </Col>
                :''}

              </Row>
            </Grid>
          </div>
        </div>
      );   

  }

  goToKey(uuid, rilasciata){
      this.props.history.push('/portal/licenseKey/get?soggetto='+this.uuid+'&uuid='+uuid);   
  }

  handleSubmit(e) {
    let client=this.state.client;

    let data ={};
    data.ragsoc=client.ragsoc;
    data.piva=client.piva;
    data.indir=client.indir;
    data.cap=client.cap;
    data.local=client.local;
    data.prov = client.prov;
    data.telefono=client.telefono;
    data.email = client.email;    

    if(this.new){
      data.acquisizione_descr="P";
    }

    this.save(data, "Cliente memorizzato correttamente");
    
    e.preventDefault();
  }
}

export default CustomerGet;