import React, { Component} from "react";
import { FileUploader } from 'devextreme-react';

class File extends Component {

  render(){  
    let allegatiInseriti=0;
    if(this.props.allegati!==undefined){
      this.props.allegati.forEach(element => {
        let mode=element["mode"];
        if(mode!=="DELETE"){
          allegatiInseriti++;
        }
      });      
    }


    let superClass=this;
      return (
        <div>
          <div className="fileuploader-container" hidden={this.props.multiple===false && allegatiInseriti>0}>
            <FileUploader name={this.props.name} labelText="" multiple={this.props.multiple} accept={this.props.accept}
              uploadMode="useForm" onValueChanged={this.props.changeFile.bind(this, this.props.name, this.props.type)} value={[]} selectButtonText={this.props.buttonName}/>        
          </div>

          {(this.props.allegati!==undefined && this.props.allegati.length>0) ?
            this.props.allegati.map(function(value, index){
              let mode=value["mode"];
              
              return (
                <span key={index}  hidden={mode==="DELETE" ? true : false}>
                  <div className="div-file" >
                    <div>
                      {value["name"]}
                      <i className="fa fa-close" onClick={superClass.props.deleteFile.bind(superClass,superClass.props.name, index)}></i>                
                    </div>
                  </div>
                </span>
              )
            })              
          : ''
          }
        </div>
      );
    }
}

export default File;

/*
class File extends Component {
    allegati=[];
    new=[];

    constructor(props) {
      super(props);      

      this.state={
        currentAllegati:[],
        origAllegati:[]
      }
      this.uploaderRef = React.createRef();

    }
  
    componentWillReceiveProps(nextProps) {    
      if(nextProps.value!=this.state.currentAllegati){      
        this.uploaderRef.current.instance.reset();              
      }      

      if(nextProps.value!=undefined){
        this.allegati=nextProps.value;  
      }
      else{
        this.allegati=[];
      }     
      
      let origAllegati=nextProps.orig;
      if(origAllegati===undefined){
        origAllegati=[];
      }
      // Quando entravo la prima volta sul popup mi sbiancava gli allegati
      //if(this.props.orig===undefined)
      //  origAllegati=[];

      this.setState({origAllegati:origAllegati});
    }

    componentDidMount(){       
      let origAllegati=this.props.orig;
      if(this.props.orig===undefined)
        origAllegati=[];

      this.setState({origAllegati:origAllegati});
      if(this.props.value!=undefined){
        this.allegati=this.props.value;  
      }
      else{
        this.allegati=[];
      }     
      
      this.uploaderRef.current.instance.reset();      
    }
   
    render(){         
      this.type= this.props.type;
      
      if(this.type==undefined){
        this.type="";
      }

      let superClass=this;
      
      return (
          <div>
            <div className="fileuploader-container">
              <FileUploader ref={this.uploaderRef} name={this.props.name} selectButtonText="Carica file" labelText="" multiple={this.props.multiple} accept={this.props.accept}
                uploadMode="useForm" onValueChanged={this.fileChange.bind(this)} selectButtonText={this.props.buttonName}/>        
            </div>

            {(this.state.origAllegati!=undefined && this.state.origAllegati.length>0) ?
              this.state.origAllegati.map(function(value, index){
                let mode=value["mode"];
                return (
                  <span key={index}  hidden={mode==="DELETE" ? true : false}>
                    <div className="div-file" >
                      <div>
                        {value["name"]}
                        <i className="fa fa-close" onClick={superClass.fileDelete.bind(superClass,index)}></i>                
                      </div>
                    </div>
                  </span>
                )
              })              
            : ''
            }
          </div>
        );
    } 
    
    fileDelete(index, e){

      /*let record = common.array.getObjectByKey(this.allegati, "uuidDocument", uuidDocument);
      record["mode"]="DELETE";    

      let orig= this.state.origAllegati;
      orig = common.array.removeObjectByKey(orig, "uuidDocument", uuidDocument);      
      this.setState({origAllegati: orig});
      */
/*
      let uuidDocument = this.allegati[index]["uuidDocument"];
      let orig=[];
      
      if(uuidDocument==undefined){
        this.allegati.splice(index, 1);
        orig=[...this.state.origAllegati].splice(index,1);
      }
      else{
        this.allegati[index]["mode"]="DELETE";        
        orig = common.array.removeObjectByKey(this.state.origAllegati, "uuidDocument", uuidDocument);      
      }

      this.setState({origAllegati: orig});      

      this.setStateAllegati();

      e.preventDefault();

    }

    setStateAllegati(){      
      let all= [...this.state.origAllegati].concat(this.new);      
      this.setState({"currentAllegati":all});   
      this.props.action(all, this.props.name);          
    }

    fileChange(e){                  
      this.new=[];
      let files=e.component.option('value');
    
      for (let key in files){
        common.file.getBase64(files[key], (result) => {
          let allegato={};
          allegato.name=files[key]['name'];
          allegato.size=files[key]['size'].toString();            
          allegato.type=files[key]['type'];     
          allegato.keyTipologia=this.type;          
          allegato.content=result;         
          
          this.new.push(allegato);
          
          this.setStateAllegati();
        });     
     }    
    }      
}


export default File;*/