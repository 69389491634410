import React from 'react';
import PlusComponent from "../../myComp/PlusComponent";
import ContentView from "../../myComp/ContentView";

class ProductNewsList extends PlusComponent {
  constructor(props) {
    super(props, "contentProductNews");         
  }

  render(){     
    return (
        <div>
          <ContentView {...this.props} title="News prodotti" controller="contentProductNews"></ContentView>
        </div>
      )
    }
}

export default ProductNewsList;