import React from 'react';
import PlusComponent from "./PlusComponent";
import ScrollView from 'devextreme-react/scroll-view';
import File from "./File";
import List from 'devextreme-react/list';

import Form, {
  ButtonItem,  
  GroupItem,
  SimpleItem,
  Label,  RequiredRule
} from 'devextreme-react/form';
import { Popup } from 'devextreme-react';

class ContentWidget extends PlusComponent {  

  constructor(props) {
    super(props, props.controller);         
    this.onSelectedRoleChange = this.onSelectedRoleChange.bind(this);
    this.onSelectedAppChange = this.onSelectedAppChange.bind(this);
    this.handleSubmitWidget=this.handleSubmitWidget.bind(this);
    this.handlePopupHidden=this.handlePopupHidden.bind(this);
  }

  get initialState(){       
    return {    
      widget:{},
      selectedRoleItems:[],
      selectedAppItems:[],
      contentImmagini:[],
      contentAllegati:[],
      viewUrl: false,
      showAction:false,      
      showImmagine:false,
      showAllegati:false,
      showDescrizione:false,
      showPagina:false,
      showUrl:false,
    };  
  }

  componentWillReceiveProps(nextProps) {              
    let widget=nextProps.widget;

    let selectRole=[];
    if(widget["ruoli"]===undefined){
      for (let key in nextProps.ruoli){
        selectRole.push(nextProps.ruoli[key]["uuid"]);
      }
    }
    else if(widget["ruoli"]!==undefined){
      for (let key in widget["ruoli"]){
          let ruolo = widget["ruoli"][key];
          selectRole.push(ruolo);
      }
    }

    
    let selectApp=[];
    if(widget["app"]===undefined){
      for (let key in nextProps.applicativi){
        selectApp.push(nextProps.applicativi[key]["applicazioneId"]);
      }
    }
    else if(widget["app"]!==undefined){
      for (let key in widget["app"]){
          let app = widget["app"][key];
          selectApp.push(app);
      }
    }    


    let contentAllegati=widget["widgetAllegati"];
    let contentImmagini=widget["widgetImmagini"];

    let result=this.setFieldByAzione(widget.widgetType, widget.widgetAzione);
    let result2=this.setFieldByTemplate(widget.widgetType, widget.widgetTemplate);
    
    this.setState({
      widget:widget, 
      selectedRoleItems:selectRole, 
      contentImmagini:contentImmagini,
      contentAllegati:contentAllegati,
      showImmagine:result2.showImmagine,
      showAllegati:result2.showAllegati,
      showDescrizione:result2.showDescrizione,
      showPagina:result.showPagina,
      showUrl:result.showUrl,
      showAction: result.showAction,
      selectedAppItems:selectApp});
  }

  changeWidgetTemplate(){
    let widget= this.state.widget;      
    let result = this.setFieldByTemplate(widget.widgetType, widget.widgetTemplate);
    this.setState(result);
  }



  setFieldByTemplate(type, template){
    let showImmagine=false;
    let showDescrizione=false;
    let showAllegati=false;

    switch (type) {
      case "TEXT":
        showDescrizione=true;        
        break;        
    
      case "IMG":   
        showImmagine=true;   
        switch (template){
          case "CARD":          
            showDescrizione=true;            
          break;    
          default:
          break;      
        }
      break;

      case "LINK":
        switch (template){         
          case "LIST":
            showImmagine=true;
            showDescrizione=true;            
          break;      
          default:
          break;          
        }
      break;

      case "ATTACH":
        showAllegati=true;

        switch (template){
          case "CARD":
            showDescrizione=true;
          break;       
          case "LIST":
            showImmagine=true;
            showDescrizione=true;
          break;    
          default:
          break;       
        }
      break;

      case "VIDEO":
        switch (template){
          case "CARD":
            showDescrizione=true;            
          break;  
          default:
          break;              
        }
      break;      
       
      default:
        break;
    }
  
    return {showImmagine: showImmagine, showDescrizione:showDescrizione, showAllegati:showAllegati};
  }

  changeWidgetAzione(){
    let widget= this.state.widget;    
    let result= this.setFieldByAzione(widget.widgetType, widget.widgetAzione); 
    this.setState(result);
  }

  setFieldByAzione(type,azione){
    let showUrl=false;
    let showPagina=false;
    let showAction=false;

    switch (type) {
      case "LINK":
        showAction=true;
        switch(azione){
          case "NEW":
          case "CUR":      
            showUrl=true;            
          break;
          case "PAG":
            showPagina=true;        
          break;
          default:
            break;          
        }
      break;

      case "VIDEO":
        showUrl=true;
      break;

      default:
        break;
    }

    return {showUrl: showUrl, showPagina:showPagina, showAction: showAction};
  }


  render(){    
    const widget= this.state.widget;   

    let dataTemplate={};
    let dataAzione=[{"key":"NEW", "value":"Apri link su una nuova pagina"}, {"key":"PAG", "value":"Apri pagina"}, {"key":"CUR", "value":"Apri link su pagina corrente"}];
    
    switch (widget.widgetType) {
      case "TEXT":
        dataTemplate=[{"key":"CARD", "value":"Card"}, {"key":"TEXT", "value":"Testo"}, {"key":"PARAGRAPH", "value":"Paragrafo"}];
        
        break;
    
      case "LINK":
        dataTemplate=[{"key":"BUTTON", "value":"Bottone"}, {"key":"TEXT", "value":"Testo"}, {"key":"LIST", "value":"Lista"}, {"key":"IFRAME", "value": "IFRAME"}];        
        break;

      case "VIDEO":
        dataTemplate=[{"key":"CARD", "value":"Card"}, {"key":"BUTTON", "value":"Bottone"}, {"key":"PREVIEW", "value":"Anteprima video"}];
        break;

      case "IMG":
        dataTemplate=[{"key":"CARD", "value":"Card"}, {"key":"IMAGE", "value":"Immagine"}, {"key":"ICON", "value":"Icona"}];
      break;

      case "ATTACH":
        dataTemplate=[{"key":"BUTTON", "value":"Bottone"}, {"key":"TEXT", "value":"Testo"}, {"key":"CARD", "value":"Card"}, {"key":"LIST", "value":"Lista"}];        
        break;
        
      default:
        break;
    }

    return (      
      <Popup
      width={1250}
      height={800}
      showTitle={true}
      title="Widget"
      dragEnabled={false}
      closeOnOutsideClick={true}
      visible={this.props.visible}
      onHiding={this.handlePopupHidden.bind(this)}
    > 

        <ScrollView width='100%' height='100%'>
        
        <form action="" onSubmit={this.handleSubmitWidget} encType="multipart/form-data">
          <Form
            formData={widget}
            readOnly={false}
            showColonAfterLabel={true}
            showValidationSummary={false}
            labelLocation="top"
            validationGroup="contentWidget"
          >

          <GroupItem colCount={1} caption="Dati">
            <GroupItem colCount={2}>
              <SimpleItem dataField="widgetTitolo">
                <Label text="Titolo"/>                                
                <RequiredRule message="Titolo obbligatorio" />                
              </SimpleItem>    
             
              
              <SimpleItem dataField="widgetTemplate" editorType="dxSelectBox" editorOptions={{
                  dataSource: dataTemplate,
                  displayExpr:"value",     
                  onValueChanged:this.changeWidgetTemplate.bind(this),               
                  valueExpr:"key"}}>
                <Label text="Template"/>
                <RequiredRule message="Template obbligatorio" />                
              </SimpleItem>        
            </GroupItem>

            <GroupItem colCount={1} visible={this.state.showDescrizione}>      
              <SimpleItem dataField="widgetDescrizione" editorType="dxTextArea"  editorOptions={{height:100}}>
                <Label text="Descrizione"/>                
              </SimpleItem>              
            </GroupItem>    

            <GroupItem colCount={2}>
              <SimpleItem dataField="widgetShowDashboard" editorType="dxCheckBox">
                <Label text="Visualizza in dashboard"/>                                                          
              </SimpleItem>  
            </GroupItem>

            <GroupItem colCount={2}>
              <GroupItem visible={this.state.showAction}>
                <SimpleItem dataField="widgetAzione" editorType="dxSelectBox" 
                editorOptions={{
                      dataSource: dataAzione,
                      displayExpr:"value",
                      onValueChanged:this.changeWidgetAzione.bind(this),               
                      valueExpr:"key"}}>
                  <Label text="Azione"/>                
                </SimpleItem>              
              </GroupItem>                    
              
              <GroupItem visible={this.state.showPagina}>
                <SimpleItem dataField="widgetPagina" editorType="dxSelectBox"  editorOptions={{
                      dataSource: this.props.pagine,
                      displayExpr:"contentTitle",                    
                      valueExpr:"uuid"}}>
                  <Label text="Pagina"/>
                </SimpleItem>                
              </GroupItem>           
            
              <GroupItem visible={this.state.showUrl}>
                <SimpleItem dataField="widgetUrl" >
                  <Label text="Url (http://....)"/>                                
                </SimpleItem>  
              </GroupItem>
            
            </GroupItem>
                          
          </GroupItem>                        
                        
            <GroupItem colCount={1} caption="Immagine" visible={this.state.showImmagine}>      
              <File 
                  changeFile={this.fileFormChange} 
                  deleteFile={this.fileFormDelete}
                  allegati={this.state.contentImmagini}
                  name="contentImmagini"
                  buttonName="Carica immagine"
                  multiple={false}
                  accept="image/*"     
                  type="IMAGE"                    
                >                
                </File>                       
            </GroupItem> 

            <GroupItem colCount={1} caption="Allegati" visible={this.state.showAllegati}>                        
              <File 
                changeFile={this.fileFormChange} 
                deleteFile={this.fileFormDelete}
                allegati={this.state.contentAllegati}
                name="contentAllegati"
                buttonName="Carica allegato"
                multiple={false}
                accept="*/*"     
                type="LINKS"               
              >                
              </File>
            </GroupItem> 

            <GroupItem colCount={1}>
              <GroupItem colCount={2}>
                  <GroupItem colCount={1} caption="Applicazioni">                  
                    <List
                      dataSource={this.props.applicativi}
                      height={250}
                      keyExpr="applicazioneId"
                      selectedItemKeys={this.state.selectedAppItems}
                      showSelectionControls={true}
                      selectionMode="all"
                      displayExpr="applicazioneId"
                      onOptionChanged={this.onSelectedAppChange}
                      >
                    </List>                         
                  </GroupItem> 

                  <GroupItem colCount={1} caption="Ruoli">                  
                             
                 <List
                  dataSource={this.props.ruoli}
                  height={250}
                  keyExpr="uuid"
                  selectedItemKeys={this.state.selectedRoleItems}
                  showSelectionControls={true}
                  selectionMode="all"
                  displayExpr="ruoloDescrizione"
                  onOptionChanged={this.onSelectedRoleChange}
                  >
                </List>                 
                  </GroupItem> 
              </GroupItem>      
               
             
          </GroupItem>

          <GroupItem>
            <ButtonItem horizontalAlignment="left"
              buttonOptions={this.buttonSaveOptions}
            />
          </GroupItem>
        </Form>      
                                   
      </form>
      </ScrollView>   
      </Popup>
    )
  }  

  handlePopupHidden(){    
    this.props.onHidePopup();
  }

  onSelectedRoleChange(args){      
    if(args.name === 'selectedItemKeys') { 
      if(JSON.stringify(args.value)!==JSON.stringify(args.previousValue)){
        this.setState({
          selectedRoleItems: args.value
        });
    }
    }
  }

  onSelectedAppChange(args){
    if(args.name === 'selectedItemKeys') {        
      if(JSON.stringify(args.value)!==JSON.stringify(args.previousValue)){
        this.setState({
          selectedAppItems: args.value
        });
      }
    }
  }
  
  handleSubmitWidget(e){
    let data= this.state.widget;    
    if(data.widgetShowDashboard===undefined || data.widgetShowDashboard===false || data.widgetShowDashboard===0){
      data.widgetShowDashboard=0;
    }
    else{
      data.widgetShowDashboard=1;
    }

    data["ruoli"]=this.state.selectedRoleItems;
    data["app"]=this.state.selectedAppItems;
    data["widgetImmagini"]=this.state.contentImmagini;
    data["widgetAllegati"]=this.state.contentAllegati;

    e.preventDefault();

    this.props.onSaveClick(data);    
  }
}




/**
 * <File buttonName="Carica allegati"
                  accept="*" 
                  name="contentAllegati" 
                  multiple={false} 
                  orig={widget.widgetAllegati}
                  value={this.state.contentAllegati}
                  action={this.handleFile} 
                  type="LINKS"></File>     
 */

export default ContentWidget;
