import React from 'react';
import { Grid, Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";
import {common} from 'react_library';
import Loader from "../../myComp/Loader";
import PlusComponent from "../../myComp/PlusComponent";

class VersionGet extends PlusComponent {
  API_DOWNLOAD="";

  constructor(props) {
    super(props, "version");
    this.API_DOWNLOAD= "/"+this.controller+"/downloadFile";
  }

  componentDidMount(){  
    this.getData(true);    
  }

  refreshState(data){    
    let stato = this.state;
    stato.version=data.default[0];
    this.setState(stato);
  }

  get initialState(){       
    return {
      version: {               
      },
    };
  }

  render() {        
      const {version} = this.state;    
          
      
      if(version.versioneid!==undefined){
        let versione_tit = version.versione_tit;
        let versione_desc = version.versione_desc;
        let prodotto = version.prodotto_tit;
        let prodotto_desc = version.prodotto_desc;
        let titolo = version.download_tit;
        let descrizione = version.download_desc;
        let dataRilascio=version.datarilascio_str;

        if(versione_desc !== ""){
          versione_tit=versione_tit+ " - "+versione_desc;
        }

      
        return (
          <div className="main-content">
            <Loader visible={this.state.loadPanelVisible}></Loader>
            <div className="content">
              <Grid fluid>
                <Row className="rowContent">
                  <Col md={8}>
                    <Card
                        title={titolo}
                        category={descrizione}
                        ctAllIcons                                    
                        content={    
                          <div>
                            <strong>Prodotto</strong> {prodotto+ " - "+prodotto_desc}<br></br>
                            <strong>Data rilascio</strong> {dataRilascio}<br></br>
                            <strong>Aggiornamento versione</strong> {versione_tit}<br></br>
                          </div>
                        }
                        >                                            
                    </Card>   
                  </Col>    

                  <div hidden={version.installazione===undefined || version.installazione.length===0}>
                    <Col md={8} >
                      <Card
                          title="Installazione"                        
                          ctAllIcons                                    
                          content={    
                            <div>
                              {version.installazione!==undefined ? version.installazione.map((file, index) => {
                                let fileid = file["FileId"];
                                let filename = file["filename"];
                                let filetitle = file["filetitle"];
                                let directory=file["updateId"];

                                return (
                                <div key={index}>
                                  <span className="linkSpan" onClick={this.getFile.bind(this, fileid, filename, directory)}>{filetitle}</span>
                                </div>);
                              }) 
                              : ""}
                            </div>
                          }
                          >                                            
                      </Card>   
                    </Col>   
                  </div>

                  <div hidden={version.files===undefined || version.files.length===0}>
                    <Col md={8}>
                      <Card
                          title="Files"                        
                          ctAllIcons                                    
                          content={    
                            <div>
                              {version.files!==undefined ? version.files.map((file, index) => {
                                let fileid = file["FileId"];
                                let filename = file["filename"];
                                let filetitle = file["filetitle"];
                                let directory=file["updateId"];

                                return (
                                <div key={index}>
                                  <span className="linkSpan" onClick={this.getFile.bind(this, fileid, filename, directory)}>{filetitle}</span>
                                </div>);
                              }) 
                              : ""}
                            </div>
                          }
                          >                                            
                      </Card>   
                    </Col>   
                  </div>

                  {version.servers!==undefined ? version.servers.map((server, index) => {
                    let name= server["titoloServer"];
                    let versione= server["titoloVersione"];

                    return (
                      <Col md={8} key={index}>
                        <Card
                            title={"Download "+name+" ver. "+versione}                        
                            ctAllIcons                                    
                            content={    
                              <div>
                                {server.files!==undefined ? server.files.map((file, index) => {
                                  let fileid = file["FileId"];
                                  let filename = file["filename"];
                                  let filetitle = file["filetitle"];
                                  let directory=file["updateId"];

                                  return (
                                  <div key={index}>
                                     <span className="linkSpan" onClick={this.getFile.bind(this, fileid, filename, directory)}>{filetitle}</span>
                                  </div>);
                                }) 
                                : ""}
                              </div>
                            }
                            >                                            
                        </Card>   
                      </Col>  
                    ); 
                  }) : ""}
                </Row>                                                 
              </Grid>
            </div>
          </div>
        );   
      }
      else return(
        <div className="main-content">
          <Loader visible={this.state.loadPanelVisible}></Loader>
        </div>
      );
  }

  getFile(fileid, filename, directory, e){
    let file={};
    file["uuid"]=fileid;
    file["filename"]=filename;
    file["dir"]=directory;

    let thisClass= this;
    common.service.postFile(this, this.API_DOWNLOAD, {data:file})
      .then(result => {
        common.notify.notifyFileResponse(thisClass, result, "", function(){
          const href = window.URL.createObjectURL(result);
          let a = document.createElement('a');
          a.href = href;
          a.download = filename;
          a.click();      
        });        
    });  

    e.preventDefault();
  }


}

export default VersionGet;
