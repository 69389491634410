import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import AdminLayout from "../layouts/Admin.jsx";
import {common} from 'react_library';

const PrivateRoute = ({ component: Component, ...rest }) => {

  // Add your own authentication on the below line.
  //const isLoggedIn = AuthService.isLoggedIn()

  const isLoggedIn=common.session.fn_isLogged();
  const isLockScreen=common.session.fn_isLockScreen();

  return (
    <Route
      {...rest}    
      render={props =>      
        isLoggedIn ? (
          isLockScreen ? (            
            <Redirect push to={{ pathname: '/auth/lock-screen', state: { from: props.location } }} />
          ) : (
            <AdminLayout {...props} /> 
          )
        ) : (          
            <Redirect push to={{ pathname: '/auth/login', state: { from: props.location } }} />
          )                 
      }
    />
  )
}

export default PrivateRoute