import React from 'react';
import { Grid, Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";
import {common} from 'react_library';
import 'devextreme-react/autocomplete';
import Loader from "./Loader";
import ContentWidget from "./ContentWidget";
import PlusComponent from "./PlusComponent";
import ScrollView from 'devextreme-react/scroll-view';
import Sortable from 'devextreme-react/sortable';
import NumberBox from 'devextreme-react/number-box';
import SelectBox from 'devextreme-react/select-box';

import Form, {
  ButtonItem,  
  GroupItem,
  SimpleItem,
  Label,  RequiredRule
} from 'devextreme-react/form';
import { Popup } from 'devextreme-react';


class ContentSettings extends PlusComponent {  
  constructor(props) {
    super(props, props.controller);         
  }

  get initialState(){       
    return {
      content: {},   
      popupRowVisible: false,           
      popupAddWidgetVisible: false,    
      currentWidget:{},
      popupVisible:false,
      currentWidgetRef:{},
      contentImmagine:[],
      currentRow:{"sezioni":[]},
      currentIndex:0,      
      currentSection:[],
    };  
  }

  componentDidMount() {
    this.getData(true);       
  }
  
  render(){      
    let content = this.state.content;
    return (   
      <div className="main-content">
        <Loader visible={this.state.loadPanelVisible}></Loader>
        <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={this.props.title}
                ctAllIcons              
                content={    
                  <div>
                    <Form
                      formData={content}
                      readOnly={false}
                      showColonAfterLabel={true}
                      showValidationSummary={false}
                      labelLocation="top"
                    >

                    <GroupItem colCount={1} caption="Dati">
                        <SimpleItem dataField="contentTitle">
                          <Label text="Titolo"/>                                
                          <RequiredRule message="Titolo obbligatorio" />                
                        </SimpleItem>      

                        <SimpleItem dataField="contentHome" editorType="dxCheckBox">
                          <Label text="Home"/>                                                          
                        </SimpleItem>                    
                      </GroupItem>
                    </Form>                    
                    <br></br>
                    <div>
                      <button className="btn btn-secondary" onClick={this.addRow.bind(this)}>Aggiungi riga</button>
                      <button className="btn btn-secondary" onClick={this.addWidget.bind(this)}>Aggiungi widget</button>
                    </div>

                    <Sortable
                          id="list"                       
                          onDragStart={this.onDragRowStart.bind(this)}
                          onReorder={this.onReorderRow.bind(this)}
                          allowDropInsideItem={true}
                        >

                      {content.rows!==undefined && content.rows.map((row, index) => {
                        let visibile = row["rigaVisibile"];
                      
                        return (  
                          <div key={"row_"+index} className="col-lg-12" >
                            <div className="right contentButton">
                                <span title="Modifica"><i className="fa fa-edit" onClick={this.editRow.bind(this, index)}></i></span>
                                <span title="Visualizza/Nascondi"><i onClick={this.visibleRow.bind(this, index)} className={visibile===1 ? "fa fa-minus-circle" : "fa fa-plus-circle"}></i></span>                                                               
                                <span title="Cancella"><i className="fa fa-remove" onClick={this.deleteRow.bind(this, index)}></i></span>
                              </div>
                              <div className={"contentRow " + (visibile===0 ? "contentRowHide" : "") }>                           
                                {row["sezioni"]!==undefined && row["sezioni"].map((sezione, indexSection) => {
                                    let width=sezione["sezioneLarghezza"];
                                    let classSelct=sezione===this.state.currentSection ? " contentSectionSelect" : "";

                                    return (                                      
                                      <div key={"section_"+indexSection} className={"contentSection col-lg-"+width+classSelct} onClick={this.setCurrentSection.bind(this, sezione)}>
                                        <Sortable                                                         
                                          group="sezione"
                                          data={{"row":index, "sezione":indexSection}}
                                          onDragStart={this.onDragStart.bind(this)}
                                          onReorder={this.onWidgetDrop.bind(this)}
                                          onAdd={this.onWidgetDrop.bind(this)}                                      
                                        >
                                          {sezione["widget"]!==undefined && sezione["widget"].map((widget, indexWidget) => {
                                              let titolo=widget["widgetTitolo"];
                                              let type=widget["widgetType"];

                                              let classType="";
                                              classType = type==="LINK" ? "fa-link" : type==="ATTACH" ? "fa-file" : type==="TEXT" ? "fa-font" : 
                                              type==="VIDEO" ? "fa-file-video-o" : type==="IMG" ? "fa-image" : "" ;
                                        
                                              return (                                                                                        
                                                <div key={"section_"+indexWidget} className={"contentWidget"}>
                                                  <div className="contentWidgetButton">
                                                    <span onClick={this.editWidget.bind(this, index, indexSection, indexWidget)}>Modifica</span>                                                  
                                                    <span className="brown" onClick={this.deleteWidget.bind(this, index, indexSection, indexWidget)}>Cancella</span>                                                                                                     
                                                  </div>
                                                  
                                                  <span className={"contentWidgetType"}><i className={"fa " + classType} title={type}></i></span>{titolo}&nbsp;
                                                </div>
                                              );                                              

                                          })}
                                          </Sortable>
                                      </div>
                                    );
                                })}                                
                            </div>                            
                          </div>                                                                                          
                        );

                      })}
                    </Sortable>

                    <div className="right">
                      <button className="btn btn-success" onClick={this.saveContent.bind(this)}>Salva</button>
                    </div>

                    <Popup
                      width={1250}
                      height={800}
                      showTitle={true}
                      title="Riga"
                      dragEnabled={false}
                      closeOnOutsideClick={true}
                      visible={this.state.popupRowVisible}
                      onHiding={this.handlePopupHidden.bind(this)}
                      contentRender={this.renderPopupRow.bind(this)}
                    />   

                    <Popup
                      width={1250}
                      height={800}
                      showTitle={true}
                      title="Aggiungi widget"
                      dragEnabled={false}
                      closeOnOutsideClick={true}
                      visible={this.state.popupAddWidgetVisible}
                      onHiding={this.handlePopupHidden.bind(this)}
                      contentRender={this.renderPopupAddWidget.bind(this)}
                    />  

                    <ContentWidget  applicativi={content.applicativi} {...this.props} ruoli={content.ruoli} widget={this.state.currentWidget} pagine={content.pagine}
                     onSaveClick={this.modifyWidget.bind(this)} visible={this.state.popupVisible} onHidePopup={this.hidePopup.bind(this)}></ContentWidget>
                    
                  </div>
                }/>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    )
  }   
  
  onDragStart(e) {
    e.itemData=this.state.content.rows[e.fromData.row].sezioni[e.fromData.sezione].widget[e.fromIndex];
  }

  onWidgetDrop(e){
    this.updateWidget(e.fromData, e.itemData, e.fromIndex, -1);
    this.updateWidget(e.toData, e.itemData, -1, e.toIndex);
  }

  reorder(items, item, fromIndex, toIndex) {
    if(fromIndex >= 0) {
      items = [...items.slice(0, fromIndex), ...items.slice(fromIndex + 1)];
    }

    if(toIndex >= 0) {
      items = [...items.slice(0, toIndex), item, ...items.slice(toIndex)];
    }

    return items;
  }

  updateWidget(listIndex, itemData, fromIndex, toIndex) {
    const content = this.state.content;
    let rows= content.rows.slice();    

    if(rows[listIndex.row].sezioni[listIndex.sezione].widget===undefined){
      rows[listIndex.row].sezioni[listIndex.sezione].widget=[];
    }

    rows[listIndex.row].sezioni[listIndex.sezione].widget = this.reorder(rows[listIndex.row].sezioni[listIndex.sezione].widget, itemData, fromIndex, toIndex);

    content.rows=rows;

    this.setState({
      content:content
    });
  }

  
  hidePopup(){
    this.setState({"popupVisible":false});
  }

  modifyWidget(data, e){
    let widget = this.state.currentWidgetRef;

    widget["widgetTitolo"]=data["widgetTitolo"];    
    widget["widgetDescrizione"]=data["widgetDescrizione"];
    widget["widgetShowDashboard"]=data["widgetShowDashboard"];
    widget["widgetTemplate"]=data["widgetTemplate"];
    widget["widgetUrl"]=data["widgetUrl"];
    widget["widgetAzione"]=data["widgetAzione"];
    widget["app"]=data["app"];
    widget["ruoli"]=data["ruoli"];
    widget["widgetAllegati"]=data["widgetAllegati"];
    widget["widgetImmagini"]=data["widgetImmagini"];
    widget["widgetPagina"]=data["widgetPagina"];

    this.setState({"currentWidgetRef":widget, "popupVisible":false});   
  }

  deleteWidget(riga, sezione, widget,e){
    let stato=this.state.content;   
    stato.rows[riga].sezioni[sezione].widget.splice(widget, 1);
    this.setState({"content": stato});
  }


  editWidget(riga, sezione, widget,e){   
    let currentWidget= this.state.content.rows[riga].sezioni[sezione].widget[widget];
    let currentWidgetRef= this.state.content.rows[riga].sezioni[sezione].widget[widget];

    this.setState({"currentWidget": JSON.parse(JSON.stringify(currentWidget)), "popupVisible":true, "currentWidgetRef":currentWidgetRef});
  }


  setCurrentSection(sezione, e){    
    this.setState({currentSection:sezione});
  }

  renderPopupAddWidget(){
    const widgets= this.state.content.widget;     

    return (
      <ScrollView width='100%' height='100%'>

        <div className="dx-fieldset contentSection  col-lg-12 no-margin-rl">
          <div className="dx-fieldset-header">Nuovo widget</div>
            <div className="col-lg-4 contentWidget" onClick={this.addWidgetSection.bind(this, "", "TEXT")}>
              <i className="contentWidgetType fa fa-font"></i>Nuovo paragrafo           
            </div>

            <div className="col-lg-4 contentWidget" onClick={this.addWidgetSection.bind(this, "", "LINK")}>
              <i className="contentWidgetType fa fa-link"></i>Nuovo link           
            </div>

            <div className="col-lg-4 contentWidget" onClick={this.addWidgetSection.bind(this, "", "VIDEO")}>
              <i className="contentWidgetType fa fa-file-video-o"></i>Nuovo video                   
            </div>

            <div className="col-lg-4 contentWidget" onClick={this.addWidgetSection.bind(this, "", "IMG")}>
              <i className="contentWidgetType fa fa-image"></i>Nuova immagine
            </div>

            <div className="col-lg-4 contentWidget" onClick={this.addWidgetSection.bind(this, "", "ATTACH")}>
              <i className="contentWidgetType fa fa-file"></i>Nuovo allegato 
            </div>
        </div>

        <div className="dx-fieldset contentSection col-lg-12 no-margin-rl">
          <div className="dx-fieldset-header">Widget esistente</div>
          {widgets!==undefined && widgets.map((widget, index) => {
            let uuidWidget=widget["uuid"];
            let titolo=widget["widgetTitolo"];
            let type=widget["widgetType"];

            let classType="";
            classType = type==="LINK" ? "fa-link" : type==="ATTACH" ? "fa-file" : type==="TEXT" ? "fa-font" : 
            type==="VIDEO" ? "fa-file-video-o" : type==="IMG" ? "fa-image" : "" ;

            return (
              <div key={"widget_"+index} className="col-lg-4 contentWidget" onClick={this.addWidgetSection.bind(this, uuidWidget, "")}>
                <i className={"contentWidgetType fa " + classType} title={type}></i>{titolo}&nbsp;
              </div>
            );
          })}
        </div>         
      </ScrollView>
    );
  }

  addWidgetSection(uuid, type, e){        
    let currentSection = this.state.currentSection;     
    if(currentSection["widget"]===undefined){
      currentSection["widget"]=[];
    }

    if(uuid===""){
      currentSection["widget"].push({"uuid":"", "widgetType":type, "widgetTitolo":""});
    }
    else{
      let widget=common.array.getObjectByKey(this.state.content.widget, "uuid", uuid);
      currentSection["widget"].push(JSON.parse(JSON.stringify(widget)));
    }
    
    this.setState({currentSection: currentSection, popupAddWidgetVisible: false});
    
    e.preventDefault();
  }

  renderPopupRow() {
    const currentRow = this.state.currentRow;     
    let numSections=currentRow["sezioni"].length.toString();

    let dataSourceSection=["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

    return (
      <ScrollView width='100%' height='100%'>
        <form action="" onSubmit={this.handleSubmitRow.bind(this)} encType="multipart/form-data">
          <Form
            formData={currentRow}
            readOnly={false}
            showColonAfterLabel={true}
            showValidationSummary={false}
            labelLocation="top"
            validationGroup="contentRow"
          >

        <GroupItem className="col-lg-12">
          <span>Numero sezioni</span>
          <SelectBox
            items={dataSourceSection}
            value={numSections}
            onValueChanged={this.sectionChange.bind(this)}
          />
          </GroupItem>

          <GroupItem >
            <div className="popupSection">
              {currentRow["sezioni"]!==undefined && currentRow["sezioni"].map((sezione, index) => {
                  let width=sezione["sezioneLarghezza"];

                  return (                  
                    <div key={"section_"+index} className={"contentSection col-lg-"+width} >                      
                      <NumberBox
                        max={12}
                        value={width}
                        width="50"                      
                        onValueChanged={this.sectionChangeWidth.bind(this,index)}
                      />                  
                    </div>
                  );

              })}
            </div>
          </GroupItem>

          <GroupItem>
            <ButtonItem horizontalAlignment="left"
              buttonOptions={this.buttonSaveOptions}
            />
          </GroupItem>
        </Form>                                      
      </form>
      </ScrollView>
    );
  }

  handleSubmitRow(e) {    
    let state = this.state;   
    let content=this.state.content;
    let currentRow=state.currentRow;
    let index=state.currentIndex;

    if(content.rows===undefined){
      content.rows=[];
    }
   
    if(currentRow["type"]==="ADD"){
      currentRow.type="";
      content.rows.push(currentRow);
    }
    else{
      content.rows[index]=currentRow;
    }

    this.setState({
      popupRowVisible: false,
      content:content,
      currentIndex:0,
    });

    e.preventDefault();
  }

  saveContent(){
    let content=this.state.content;
    if(content.contentHome===false){
      content.contentHome=0;
    }
    else{
      content.contentHome=1;
    }

    this.save(content, "Contenuto memorizzato correttamente");    
  }


  sectionChangeWidth(index, e){
    let currentRow = this.state.currentRow;   

    currentRow["sezioni"][index]["sezioneLarghezza"]=e.value;

    let columns=0;
    for(let i=0; i<currentRow["sezioni"].length; i++){
      columns+= parseInt(currentRow["sezioni"][i]["sezioneLarghezza"]);
    }

    if(columns>12){
      currentRow["sezioni"][index]["sezioneLarghezza"]=e.value+(12-columns);
    }
  
    this.setState({
      currentRow: currentRow
    });    
  }

  sectionChange(e){
    this.renderPopupRow = this.renderPopupRow.bind(this);

    let currentRow = this.state.currentRow; 

    let lunghezza=currentRow["sezioni"].length;

   
    if(lunghezza<e.value){
      let num=e.value-lunghezza;
      for(let i=0; i<num; i++){
        currentRow["sezioni"].push({uuid:"", sezioneLarghezza:1, sezioneVisibile:1});
      }      
    } else if(lunghezza>e.value){
      let num=lunghezza-e.value;
      for(let i=0; i<num; i++){
        currentRow["sezioni"].pop();
      }
    }

    if(e.value===12){
      for(let i=0; i<e.value; i++){
        currentRow["sezioni"][i]["sezioneLarghezza"]=1;
      }
    }

    this.setState({
      currentRow: currentRow
    });

    this.sectionChangeWidth(0, {"value":12});

  }
  
  handlePopupHidden() {
    this.setState({
      popupAddWidgetVisible: false,
      popupWidgetVisible:false,
      popupRowVisible: false
    });
  }


  visibleRow(index, e){
    let content = this.state.content;
    let rows=content.rows;  

    let row = rows[index];
    if(row.rigaVisibile===1)
      row.rigaVisibile=0;
    else 
      row.rigaVisibile=1;

    content.rows=rows;
    this.setState({
      content:content
    });

    e.preventDefault();
  }

  addRow(e){
    let ordine=0;
    if(this.state.content.rows!==undefined){
      ordine= this.state.content.rows.length+1;
    }

    this.setState({
      popupRowVisible: true,
      currentIndex: 0,
      currentRow:{"uuid":"","sezioni":[], "rigaVisibile":1, "type":"ADD", "rigaOrdine":ordine},
    });
  }

  addWidget(e){
    if(this.state.currentSection!=="" && this.state.currentSection!=null){
      this.setState({
        popupAddWidgetVisible: true,      
      });
    }
    else{
      common.notify.fn_error("Seleziona una sezione prima di procedere con l'aggiunta del widget");
    }
  }

  editRow(index,e){    
    let rowJSON= JSON.stringify(this.state.content.rows[index]);

    let row = JSON.parse(rowJSON);

    this.setState({
      popupRowVisible: true,
      currentRow:row,
      currentIndex: index,
    });
  }

  deleteRow(index, e){
    let content = this.state.content;
    let rows=this.state.content.rows;  

    rows=common.array.removeObjectByKey(rows, "uuid", rows[index]["uuid"]);
    
    content.rows=rows;
    this.setState({
      content:content
    });

    e.preventDefault();
  }

  /**
   * Dopo aver estratto il record dal db ritorna i dati
   * @param {object} data 
   */
  refreshState(data){
    let stato=this.state;    
    stato.content=data[0];
    this.setState(stato);
  }

  onDragRowStart(e) {
    e.itemData = this.state.content.rows[e.fromIndex];
  }

  onReorderRow(e) {
    let content = this.state.content;

    let rows=this.state.content.rows;  

    rows = [...rows.slice(0, e.fromIndex), ...rows.slice(e.fromIndex + 1)];
    rows = [
      ...rows.slice(0, e.toIndex),
      e.itemData,
      ...rows.slice(e.toIndex)
    ];
    
    for (let i =0; i<rows.length ; i++){
        rows[i]["rigaOrdine"]=i;
    }

    content.rows=rows;

    this.setState({
      content: content
    });
  }
}

export default ContentSettings;