import React from 'react';
import { Grid, Row, Col } from "react-bootstrap";
import 'devextreme-react/autocomplete';
import Loader from "./Loader";
import PlusComponent from "./PlusComponent";
import CardContent from "./CardContent";

class ContentView extends PlusComponent {    
  constructor(props) {
    super(props, props.controller);          
  }

  get initialState(){       
    return {
      list: [],   
      befpage: [],
    };  
  }

  componentDidMount() {
    this.getList({data:{"pk":""}});
  }

  refreshPage(pagina){     
    this.getList({data:{pk:pagina}});
  }

  refreshList(data){    
    let stato = this.state; 
    stato.list=data;   
    const pagina = stato.list[0];

    if(pagina!==undefined && pagina.contentTitle!=="" && pagina.contentTitle!==undefined){
      this.props.changeTitle(pagina.contentTitle);
      if(stato.befpage[pagina.uuid] !==undefined){
        this.props.changeLinkTitle(this.changePageLink.bind(this, stato.befpage[pagina.uuid]));
      }
    }
    this.setState(stato);    
  }

  changePageLink = page=>{
    this.refreshPage(page);
  }

  render(){     
    const { list } = this.state;
    const pagina = list[0];   

    return (      
      <div className="main-content">
          <Loader visible={this.state.loadPanelVisible}></Loader>    
          <div className="content">
            <Grid fluid>
                <div>
                {list!==undefined && list.length>0 ?                  
                  pagina.rows!==undefined && pagina.rows.length>0 ? pagina.rows.map((riga, indexRow) => {     
                      let rigaVisibile = riga["rigaVisibile"];
                      return(
                        <div key={indexRow} hidden={!rigaVisibile===1}>
                          <Row className="rowContent">
                            {riga.sezioni.length>0 ? riga.sezioni.map((sezione, indexSezione)=>{
                              return (
                                <Col key={indexSezione} md={sezione["sezioneLarghezza"]}>
                                  {sezione.widget.length>0 ? sezione.widget.map((widget, indexWidget)=>{
                                    let type=widget["widgetType"];
                                    let template= widget["widgetTemplate"];
                                    let titolo= widget["widgetTitolo"];
                                    let descrizione= widget["widgetDescrizione"];
                                    let allegati =widget["widgetAllegati"];
                                    let immagini =widget["widgetImmagini"];
                                    let link=widget["widgetUrl"];
                                    let pagina=widget["widgetPagina"];
                                    let azione=widget["widgetAzione"];

                                    let immagine="";

                                    if(immagini.length>0){
                                      immagine= "data:"+immagini[0]["type"]+";base64," + immagini[0]["content"];                                      
                                    }
                                    
                                    let allegato="";
                                    let nomeAll="";

                                    if(allegati.length>0){
                                      allegato= "data:"+allegati[0]["type"]+";base64," + allegati[0]["content"];
                                      nomeAll=allegati[0]["descrizione"];
                                    }
                                  
                                    switch (type) {
                                      case "ATTACH":                                      
                                        switch (template) {
                                          case "BUTTON":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">                                                
                                                <a href={allegato} download={nomeAll} className="btn btnContent">{titolo.toUpperCase()}</a>                                              
                                              </div>
                                            );
                                        
                                          case "TEXT":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">
                                                <a download={nomeAll} href={allegato} className="linkContent">{titolo.toUpperCase()}</a>
                                              </div>
                                            );

                                          case "CARD":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">
                                                <CardContent titolo={titolo} testo={descrizione} link={allegato} linkName={nomeAll}></CardContent>                                                
                                              </div>
                                            );

                                          case "LIST":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">                                                  
                                                <div className="row linkListContent">
                                                  <div className="col-image">
                                                    <div>
                                                      <img alt="" src={immagine}></img>
                                                    </div>
                                                  </div>
                                                  <div className="col-text">
                                                    <h4>
                                                      <a href={allegato} download={nomeAll} className="linkContent">{titolo} <i className="fa fa-paperclip"></i></a>                                                   
                                                    </h4> 
                                                    <p>{descrizione}</p>
                                                  </div>
                                                  
                                                </div>                                                                                                  
                                              </div>
                                            );

                                          default:
                                            break;
                                        }
                                        break;

                                      case "TEXT":                                      
                                        switch (template) {                                                                                  
                                          case "TEXT":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">
                                                <div className="textContent">
                                                  <h2>{titolo.toUpperCase()}</h2>
                                                  <p dangerouslySetInnerHTML={{__html: descrizione}}></p>
                                                </div>
                                              </div>
                                            );

                                          case "PARAGRAPH":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">
                                                <div className="textContent">
                                                  <p dangerouslySetInnerHTML={{__html: descrizione}}></p>
                                                </div>
                                              </div>
                                            );

                                          case "CARD":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">
                                                <CardContent titolo={titolo} testo={descrizione} link=""></CardContent>                                                
                                              </div>
                                            );

                                          default:
                                            break;
                                        }
                                      break;

                                      case "IMG":                                      
                                        switch (template) {                                                                                  
                                          case "IMAGE":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">
                                                <div className="imageContent">
                                                  <img alt="" src={immagine}></img>
                                                </div>
                                              </div>
                                            );

                                          case "CARD":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">
                                                <CardContent titolo={titolo} testo={descrizione} link="" image={immagine}></CardContent>                                                
                                              </div>
                                            );

                                          case "ICON":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">
                                                <div className="align-center">
                                                   <img alt="" src={immagine}></img>
                                                </div>
                                              </div>
                                            );

                                          default:
                                            break;
                                        }
                                      break;


                                      case "VIDEO":                                      
                                        switch (template) {                                                                                  
                                          case "BUTTON":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">     
                                                <a href={link} className="btn btnContent" rel="noopener noreferrer" target="_blank">{titolo.toUpperCase()}</a>                                                  
                                              </div>
                                            );

                                          case "PREVIEW":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">
                                                <div className="imageContent">
                                                  <div className="embed-responsive embed-responsive-16by9">
                                                      <iframe title={link} className='embed-responsive-item' src={link}></iframe>
                                                  </div>
                                                </div>
                                              </div>
                                            );

                                          case "CARD":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">
                                                <CardContent titolo={titolo} testo={descrizione} link="" image="" video={link}></CardContent>                                                
                                              </div>
                                            );

                                          default:
                                            break;
                                        }
                                      break;



                                      case "LINK":                                      
                                        switch (template) {                                                                                  
                                          case "BUTTON":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">     
                                                {azione==="PAG" ? 
                                                <span onClick={this.changePage.bind(this, pagina)} className="btn btnContent linkSpanButton">{titolo.toUpperCase()}</span>                                                  
                                                : 
                                                <a href={link} className="btn btnContent" target={azione==="NEW" ? "_blank" :""}>{titolo.toUpperCase()}</a>                                                  
                                                }
                                                
                                              </div>
                                            );

                                          
                                          case "TEXT":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">     
                                                {azione==="PAG" ? 
                                                  <span onClick={this.changePage.bind(this, pagina)} className="linkContent linkSpan">{titolo.toUpperCase()}</span>                                                  
                                                : 
                                                  <a href={link} className="linkContent" target={azione==="NEW" ? "_blank" :""}>{titolo.toUpperCase()}</a>                                                  
                                                }
                                              </div>
                                            );

                                          case "IFRAME":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">     
                                                <iframe width="100%" height="800" title={link} src={link} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>    
                                              </div>
                                            );
                                        
                                          case "LIST":
                                            return (
                                              <div key={indexWidget} className="col-lg-12 widgetContent">                                                  
                                                <div className="row linkListContent">
                                                  <div className="col-image">
                                                    <div>
                                                      <img alt="" src={immagine}></img>
                                                    </div>
                                                  </div>
                                                  <div className="col-text">
                                                    <h4>
                                                      {azione==="PAG" ? 
                                                        <span onClick={this.changePage.bind(this, pagina)} className="linkContent linkSpan">{titolo}</span>
                                                        : 
                                                        <a href={link} className="linkContent" target={azione==="NEW" ? "_blank" :""}>{titolo}</a>                                                  
                                                      }
                                                    </h4>
                                                    <p>{descrizione}</p>
                                                  </div>
                                                  
                                                </div>                                                                                                  
                                              </div>
                                            );

                                          default:
                                            break;
                                        }
                                      break;
                                    
                                      default:
                                        break;
                                    }

                                    return (
                                      <div key={indexWidget}>
                                      </div>
                                    );
                                  }) : ""}
                                </Col>
                              )
                            }) : ""}
                          </Row>
                        </div>
                      );
                    })
                  : ""
                : ''}   
                </div>           
            </Grid>
          </div>
        </div>
      );
  }   
  
  changePage(pagina, e){  
    let stato = this.state;
    stato.befpage[pagina]=this.state.list[0].uuid;
    this.setState(stato);
    this.refreshPage(pagina);    
    e.preventDefault();
  }

}

export default ContentView;