import React from 'react';
import PlusComponent from "../../myComp/PlusComponent";
import ContentView from "../../myComp/ContentView";

class TrainingList extends PlusComponent {
  constructor(props) {
    super(props, "contentTraining");         
  }

  render(){     
    return (
        <div>
          <ContentView {...this.props} title="Formazione" controller="contentTraining"></ContentView>
        </div>
      )
    }
}

export default TrainingList;