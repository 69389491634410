import React from 'react';
import PlusComponent from "../../myComp/PlusComponent";
import ContentSettings from "../../myComp/ContentSettings";

class ProductNewsGet extends PlusComponent {
  constructor(props) {
    super(props, "contentProductNews");         
  }

  render(){     
    return (
        <div>
          <ContentSettings {...this.props} controller="contentProductNews"></ContentSettings>
        </div>
      )
    }
}

export default ProductNewsGet;