import React, { Component } from "react";
import {common} from 'react_library';

class History extends Component {
    constructor(props) {
      super(props);
             
      this.state={};
    }

    render(){
      return (
          <div className="history">
            <div className="panelTimeline">
                <ul className="timeline">
                    {this.props.data.length>0 ?
                    <li><i className="fa fa-clock-o bg-gray firstClock"></i></li> :''
                    }                    
                    {this.props.data.map((value, index) => {
                        let li_icon="";
                        let titolo="";
                        let descrizione="";
                        let icon_detail="";
                        let li_date="";

                        if(value.activity_subject===undefined){
                            let utente = value.iUtenteCreazioneNome;
                            let data = common.date.writePreviewDateTimeByDB(value.creationDate);
                            let destinatario=value.destinatarioNome;
                            let note = value.note;
                            let key=value.uuid;
                            let files=value.files;

                            li_date = utente + " - " + data;
                            let tipo=value.tipo;                        
                            
                            if (tipo === "SOLLECITO" || tipo === "INTEGR" || tipo==="A") {
                                if (tipo === "SOLLECITO") {
                                    li_icon = "fa-exclamation-circle bg-red";
                                    if (destinatario !== "") {
                                        titolo= "Sollecito per " + destinatario;
                                    }
                                    else {
                                        titolo="Sollecito";
                                    }
                                }
                                else if (tipo === "INTEGR") {
                                    li_icon = "fa-sticky-note bg-yellow";
                                    if (destinatario !== "") {
                                        titolo="Integrazione per " + destinatario;
                                    }
                                    else {
                                        titolo="Integrazione";                                    
                                    }
                                }                                
                                else if (tipo === "A") {
                                    li_icon = "fa-paperclip bg-purple";
                                    titolo="Allegato";                                                                                                                   
                                }
        
                                descrizione=note;       
                                

                                /*
                                var allegati = [];

                                if (v_allegati !== "") {
                                    v_allegati = v_allegati.replaceAll("\\]\\[", ",").replace("[", "").replace("]", "");
                                    var list = v_allegati.split(",");

                                    $.each(list, function (index, uuid) {
                                        var selectStringAllegato = "SELECT xdDocumentServerFilename as name FROM xd_documents WHERE uuid = " + uuid.normUuidFromString();
                                        var jsonResultAllegato = fn_serviceBySelect_json(selectStringAllegato);

                                        if (jsonResultAllegato !== undefined && jsonResultAllegato.length >= 0) {
                                            allegati.push(jsonResultAllegato[0]["name"]);
                                        }
                                    });
                                }

                                if (allegati !== "") {
                                    obj_description.append("<br><br>");
        
                                    var countAllegati = "";
        
                                    $.each(allegati, function (index, name) {
        
                                        var l_view = $(document.createElement("span")).addClass("btnAttach").html("Allegato " + countAllegati);
        
                                        l_view.click(function () {
                                            var urlLink = URL_SITE + "/" + VAR_FOLDER_PUBLIC + "public/documenti/" + name;
                                            window.open(urlLink, "_blank");
                                        });
        
                                        countAllegati++;
        
                                        obj_description.append(l_view);
        
                                    });
                                }*/
                            }
                            else if (tipo === "NEW") {
                                li_icon = "fa-star bg-blue";
        
                                titolo="Creazione del ticket";
                            }
                            else if (tipo === "CLOSE") {
                                li_icon = "fa-stop bg-grey";
        
                                titolo="Chiuso";
                            }
                            else if(tipo=="RESP"){
                                li_icon = "fa-user bg-purple";
                                titolo="Preso in carico da " + note;                                
                            }
                            else if (tipo === "RESOLVE") {
                                li_icon = "fa-check bg-green";
        
                                titolo="Risolto";
                                descrizione=note;       
                            }

                            return <li key={key}>
                            <i className={"fa "+li_icon}></i>

                                <div className="timeline-item">
                                    <span className="time">
                                        <i className="fa fa-clock-o"></i>{li_date}     
                                        {icon_detail}                           
                                    </span>
                                    <h3 className="timeline-header">{titolo}</h3>
                                    {descrizione!=="" ?
                                        <div className="timeline-body description">{descrizione}</div>
                                    :''}
                                    {files!==undefined ? files.map(function(allegato, indexAllegato){
                                        let type=allegato["type"];
                                        let content=allegato["content"];
                                        let nomeAllegato=allegato["descrizione"];
                                        let link= "data:"+type+";base64," + content;        
                                        
                                        return (                                             
                                            <div key={indexAllegato} className="div-file">                                                
                                            <a href={link} download={nomeAllegato}>{nomeAllegato.toUpperCase()}</a>                                              
                                            </div>);
                                    }) :""}
                                </div>                        
                            </li>       
                        }
                        else{
                            let tipologia= value.tipologia;
                            let oggetto=value.activity_subject;
                            let stato= value.activity_status;
                            let data = common.date.writePreviewDateTimeByDB(value.activity_startdate);
                            let destinatario=value.activity_to_display;
                            let descrizione=value.activity_description;
                            let consuntivazioni= value.consuntivazioni;
                            let show = value.showDetailHistory;
                            let activityId=value.uuid;

                            titolo = tipologia;                                

                            if (stato === 2) {
                                li_icon = "fa-close bg-green";
                            }
                            else {
                                li_icon = "fa-folder-open-o bg-orange";
                            }
        
                            titolo= " Azione "+ tipologia;
        
                            li_date = destinatario + " il " + data;

                            if(show!==1){
                                descrizione="";
                            }
                            
                            let showBlock=this.state["block"+activityId]!==undefined && this.state["block"+activityId]===true;
                            let iconBlock = showBlock ? "fa-angle-up" : "fa-angle-down";
                        
                            return <li key={activityId}>
                                <i className={"fa "+li_icon}></i>

                                <div className="timeline-item">
                                    <span className="time">
                                        <i className="fa fa-clock-o"></i>{li_date}     
                                        <i className={"fa " + iconBlock} onClick={() => this.hideComponent("block"+activityId)}></i>                        
                                    </span>
                                    <h3 className="timeline-header">{titolo}</h3>
                                    { showBlock ?
                                        <div className="timeline-body">
                                            <div><b>Oggetto: </b>{oggetto}</div>
                                            <div>{descrizione}</div>                                            
                                                {consuntivazioni.map((consuntivazione, index2) => {                                                
                                                    let utente = consuntivazione["activity_to_display"];
                                                    let data = common.date.writePreviewDateTimeByDB(consuntivazione["activity_startdate"]);
                                                    let descrizione= consuntivazione["activity_description"];    

                                                    return (
                                                        <div>
                                                            {
                                                                index2===0 ? <br></br> : ''
                                                            } 
                                                            <div className="consuntivazione"># {utente} il {data}</div>
                                                            <div className="description">{descrizione}</div>
                                                        </div>
                                                    )
                                                    
                                                })}

                                        </div>
                                    :''}
                                </div>                        
                            </li>       
                        }                                                    
                    })}
                    {this.props.data.length>0 ?
                        <li><i className="fa fa-clock-o bg-gray"></i></li>
                        :''
                    }  
                </ul>
            </div>
            </div>
        );
    }   

    hideComponent(name) {
        let stato = this.state;

        if(stato[name]===undefined){
            stato[name]=true;
        }
        else{
            stato[name]=!stato[name];
        }

        this.setState(stato);        
      }

}


export default History;