import React from 'react';
import PlusComponent from "../../myComp/PlusComponent";
import ContentSettings from "../../myComp/ContentSettings";

class ContactGet extends PlusComponent {
  constructor(props) {
    super(props, "contentContact");         
  }

  render(){     
    return (
        <div>
          <ContentSettings {...this.props} controller="contentContact"></ContentSettings>
        </div>
      )
    }
}

export default ContactGet;