import React from "react";
import PlusComponent from "../../myComp/PlusComponent";
import PageGrid from "../../myComp/PageGrid";
import Loader from "../../myComp/Loader";

class ContactGrid extends PlusComponent {
    constructor(props) {
      super(props, "contentContact");
   
      this.state = {
        grid: null,
      };  
    }
   
    componentDidMount() {
      this.getGrid();
    }
    
    updateGrid(){
      this.getGrid();
    }

    render(){   
      return (        
        <div className="main-content">
          <Loader visible={this.state.loadPanelVisible}></Loader>           
          <PageGrid title="Elenco pagine" data={this.state.grid} controller="contentContact" functionality="CONTACT" eventUpdate={this.updateGrid.bind(this)}></PageGrid>
        </div>
      );
    }     
}

export default ContactGrid;