"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  IS_OK: 0,
  OK: [0, ''],
  ERR_OPEN_DB: [-1, "Errore nell'apertura del db"],
  ERR_CLOSE_DB: [-2, "Errore nella chiusura del db"],
  ERR_OPEN_TRANS: [-3, "Errore nell'apertura della transazione"],
  ERR_EXECUTE_QUERY: [-4, "Errore nell'esecuzione della query"],
  ERR_EXECUTE_CONN_CLOSE: [-5, "Si sta cercando di eseguire una query con la connessione del db chiusa"],
  ERR_EXECUTE_QUERY_EMPTY: [-6, "Si sta cercando di eseguire una query vuota"],
  ERR_COMMIT_TRANS: [-7, "Errore nella commit della transazione"],
  ERR_ROLLBACK_TRANS: [-8, "Errore nella rollback della transazione"],
  ERR_PAGE: [-9, "Metodo non presente a sistema"],
  ERR_DATE_TYPE: [-10, "Tipo di dato non corretto"],
  ERR_WHERE_TYPE: [-11, 'Condizione where non corretta'],
  ERR_MODEL_PK: [-12, 'Impossibile aggiornare il record in quanto non esiste la chiave primaria'],
  ERR_EMPTY_DATA: [-13, 'Oggetto data non definito'],
  ERR_EMPTY_WHERE: [-14, 'Oggetto where non definito'],
  ERR_MODEL_FILE: [-15, "Errore nella generazione del file di model"],
  ERR_MODEL_FILE_EXIST: [-16, "Il model che si sta tentando di generare esiste già"],
  ERR_TABLE_NOT_EXIST: [-17, "La tabella selezionata non è presente all'interno del DB"],
  ERR_CONTROLLER_FILE_EXIST: [-18, "Il controller che si sta tentando di generare esiste già"],
  ERR_CONTROLLER_FILE: [-19, "Errore nella generazione del file di controller"],
  ERR_QUERIES_NAME: [-20, "Queries non presente a sistema"],
  ERR_QUERIES_EMPTY: [-21, "Nome della queries non specificato"],
  ERR_QUERIES_COLUMN_LIST: [-22, "Errore nell'estrazione delle colonne della queries"],
  ERR_AZIENDA_LOGIN: [-23, "L'azienda non è presente a sistema"],
  ERR_CREDENZIALI_LOGIN: [-24, "Le credenziali non sono valide"],
  ERR_CREATION_FILE: [-25, "Errore nella creazione del file"],
  ERR_INSERT_DOC_LINKS: [-26, "Errore nel caricamento dei link documenti"],
  ERR_ENCRIPTION: [-100, "Errore di crittografia"],
  ERR_DECRIPTION: [-101, "Errore di decrittazione"],
  ERR_INVALID_TOKEN: [-102, "Token di autenticazione non valido"],
  ERR_INVALID_TOKEN_DATE: [-103, "Data del token di autenticazione non valida"],
  ERR_INVALID_TOKEN_INFO: [-104, "Informazioni del token di autenticazione non valide"],
  ERR_EXPIRED_TOKEN: [-105, "Token di autenticazione scaduto"],
  ERR_ACTIVATION_KEY_LEN: [-110, "Lunghezza della chiave di attivazione non corretta"],
  ERR_DUPLICATE_ACTIVATION_KEY: [-111, "Rilevata la presenza di chiavi di attivazione duplicate"],
  ERR_UNAVAILABLE_ACTIVATION_KEYS: [-112, "Dispostivo disabilitato e nessuna chiave di attivazione disponibile"],
  ERR_AVAILABLE_ACTIVATION_KEYS: [-113, "Dispostivo disabilitato, ma con chiavi di attivazione disponibili"],
  ERR_ALREADY_DISABLED_DEVICE: [-114, "Il dispositivo non risultava abilitato"],
  ERR_PERMISSION_DENIED: [-115, "Non hai i privilegi sufficienti per effettuare l'operazione"],
  ERR_UNDEFINED_ODBC: [-116, "Nessun ODBC definito"]
};
exports.default = _default;