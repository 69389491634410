"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  /**
   * Ritorna true in caso di e-mail valida. 
   * @param {string} email : email da valutare
   */
  validaEmail: function validaEmail(email) {
    var ok = true; //if (!email.match(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})$/)) {

    if (!email.match(/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})$/)) {
      ok = false;
    }

    ;
    return ok;
  }
};
exports.default = _default;