import React from "react";
import PlusComponent from "../../myComp/PlusComponent";
import PageGrid from "../../myComp/PageGrid";
import Loader from "../../myComp/Loader";
import { Switch } from "devextreme-react";

class TicketGrid extends PlusComponent {
    constructor(props) {
      super(props, "ticket");
       
      if(localStorage.getItem("switchValueTicket")==undefined || localStorage.getItem("switchValueTicket")==null){
        localStorage.setItem("switchValueTicket", true);        
      }
     
      this.state = {
        grid: null,
      };  
    }
    
   // how to make registration form in react?
    componentDidMount() {    
      console.log("component mount")
      this.getDati();      
    }
    
    updateGrid(){
      this.getDati();
    }

    getDati(){
      let aperto = 0;
      let switchState= localStorage.getItem("switchValueTicket");
      if(switchState==='true'){

        aperto=1;
      }      

      this.getGrid({"aperto":aperto});      
    }


    switchChange(e) {
      localStorage.setItem("switchValueTicket", e.value);

      this.updateGrid();
    }
   
    render(){  
      let title="Elenco ticket";
      let switchState= localStorage.getItem("switchValueTicket");
      var isTrueSet = (switchState === 'true');


      if(isTrueSet){
        title="Elenco ticket aperti";
      }

      return (          
        <div className="main-content">
          <div className="dx-field margin-right">
            <div className="dx-field-value">
              <Switch
                value={isTrueSet}
                switchedOffText="Nascondi ticket chiusi"
                switchedOnText="Visualizza ticket chiusi"
                width="200px"        
                onValueChanged={this.switchChange.bind(this)}
              />
            </div>
          </div>
          <Loader visible={this.state.loadPanelVisible}></Loader>    
          <PageGrid title={title} data={this.state.grid} controller="ticket" functionality="TICKET" eventUpdate={this.updateGrid.bind(this)}></PageGrid>
        </div>
      );
    }     


}



export default TicketGrid;