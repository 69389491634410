import React from 'react';
import Form, {
  SimpleItem,
  Label,
  GroupItem, RequiredRule, ButtonItem
} from 'devextreme-react/form';
import { NumberBox} from 'devextreme-react';
import { Grid, Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";
import {common} from 'react_library';
import ArrayStore from 'devextreme/data/array_store';
import 'devextreme-react/autocomplete';
import Loader from "../../myComp/Loader";
import PlusComponent from "../../myComp/PlusComponent";



class LicenseGet extends PlusComponent {  
  soggetto=null;

  constructor(props) {
    super(props,"licenseKey");
  
    const query = new URLSearchParams(this.props.location.search);
    this.soggetto = query.get('soggetto');  
  }

  componentDidMount(){
    this.getData(true);
  }

  refreshState(data){    
    let stato = this.state;
    stato.license=data.default[0];
    this.setState(stato);
  }

  get initialState(){       
    return {
      license: {},
      moduli:[],
    };
  }

  getModuliProdotto(){
    let stato = this.state;
  
    let prodottoid= stato.license.prodottoid;    

    if(prodottoid!=="" && stato.license.moduli!==undefined && stato.license.moduli[prodottoid]!==undefined){
      stato.moduli=stato.license.moduli[prodottoid];
    }
    else{
      stato.moduli=[];
    }

    this.setState(stato);    
  }


  render() {        
      const {license} = this.state;
      const {moduli}=this.state;

            
      const disabled= !((this.new && common.service.checkFunctionality("LICENSE_NEW")) || (!this.new && common.service.checkFunctionality("LICENSE_UPDATE")));

      let dataSourceProdotto = new ArrayStore({
        data: license.prodotti,
        key: 'prodottoid'
      });

      this.textAreaOptions={
        disabled:!this.new,
        minHeight:"100"
      }

      this.prodottoOption={
        dataSource:dataSourceProdotto,
        displayExpr:"prodottoid",
        valueExpr:"prodottoid",
        disabled:!this.new,
        onValueChanged:this.getModuliProdotto.bind(this),
      };  
      

      return (
        <div className="main-content">
          <Loader visible={this.state.loadPanelVisible}></Loader>
          <div className="content">
          <Grid fluid>
            <Row>
              <Col md={8}>
                <Card
                  title="Chiavi di attivazione"
                  ctAllIcons              
                  content={    
                    <div>
                        <form action="" onSubmit={this.handleSubmit} encType="multipart/form-data">
                          <Form
                            formData={license}
                            readOnly={false}
                            showColonAfterLabel={true}
                            showValidationSummary={false}
                            labelLocation="top"
                            validationGroup="accountData"
                          >
                            
                            <GroupItem colCount={1}>                           
                              <SimpleItem dataField="prodottoid" editorType="dxSelectBox" editorOptions={this.prodottoOption}>
                                <Label text="Prodotto"/>
                                <RequiredRule message="Prodotto obbligatorio"></RequiredRule>
                              </SimpleItem>                             
                            </GroupItem>

                            <GroupItem colCount={1}>       
                              <SimpleItem dataField="note" editorType="dxTextArea" editorOptions={this.textAreaOptions}>
                                  <Label text="Note"/>
                              </SimpleItem>
                            </GroupItem>

                            <GroupItem colCount={2}>        
                              <table className="tableData">
                                <thead>                                
                                  <tr className="row_gv_header">
                                    <td></td>    
                                    <td>Descrizione</td>
                                    <td>Licenze</td>
                                    <td>Attive</td>
                                  </tr>
                                </thead>
                                <tbody>{moduli.map((value, index) => {
                                      let uuid=value["uuid"];
                                      let moduloId=value["moduloId"];
                                      let titolo=value["titolo"];
                                      let attive=0;    
                                      
                                      if(this.state.license.moduli_attivi!==undefined && this.state.license.moduli_attivi[moduloId]){
                                        attive=this.state.license.moduli_attivi[moduloId]["moduloQTA"];                                        
                                      }

                                      return (
                                        <tr className="row_gv_modulo" key={uuid}>
                                          <td>{moduloId}</td>
                                          <td>{titolo}</td>
                                          <td>
                                            <NumberBox step={0} name={"modulo_"+moduloId} disabled={disabled} min={0} format="###0" />      
                                          </td>
                                          <td>{attive}</td>
                                        </tr>);
                                    })}</tbody>
                              </table>                        
                            </GroupItem>                          

                            {!disabled ?
                              <GroupItem colCount={6}>  
                                <ButtonItem horizontalAlignment="left" location="right"
                                  buttonOptions={this.buttonSaveOptions}
                                />
                              </GroupItem> : ''}                  
                          </Form>           
                        </form>           
                        {this.state.license.licensecode!=="" && this.state.license.licensecode!==undefined ?
                          <div className="licenseCode">
                            <b>Chiave di attivazione:</b><br></br>
                            {this.state.license.licensecode}
                          </div>
                        : ''}
                      </div>
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      );   
  }  
  
  handleSubmit(e) {

    let license=this.state.license;

    let data={};
    data.note=license.note;
    data.prodottoid=license.prodottoid;
    data.soggettoId=this.soggetto;
    data.moduli={};
    
    for (let i = 0 ; i < e.target.elements.length; i++){
      let name= e.target.elements[i].name;
      if(name.indexOf("modulo_")>=0){               
        data.moduli[name.replace("modulo_","")]=e.target.elements[name].value;               
      }      
    }
    
    if(this.new){
      data.keyAggiuntiva=0;
    }

    this.save(data, "Chiave d'attivazione generata con successo");
    
    e.preventDefault();
  }

  backGrid(thisClass, result){    
    let uuid=result["data"]["default"];   
    this.props.history.push(`/portal/licenseKey/get?soggetto=${this.soggetto}&uuid=${uuid}`);   
    this.props.history.go();
  }
}

export default LicenseGet;