/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { PureComponent  } from "react";
import { FormGroup, FormControl } from "react-bootstrap";

import Button from "components/CustomButton/CustomButton.jsx";

import avatar from "assets/img/default-avatar.png";
import {common} from 'react_library';

class LockScreenPage extends PureComponent  {
  constructor(props) {
    super(props);
    
    const isLoggedIn=common.session.fn_isLogged();
    if(!isLoggedIn){
      this.props.history.push('/auth/login');
    }
  }

  render() {
    return (
      <form className="ng-untouched ng-pristine ng-valid">
        <div className="user-profile">
          <div className="author">
            <img alt="..." className="avatar" src={avatar} />
          </div>
          <h4>{localStorage.getItem("utenteNome")}</h4>
          <FormGroup>
            <FormControl type="password" inputRef={(ref) => {this.textPassword = ref}} placeholder="Enter Password" autoComplete="off"/>
          </FormGroup>
          <Button wd neutral round onClick={this.unlock.bind(this)}>
            Sblocca
          </Button>       
          <Button onClick={this.logout.bind(this)} className="btn-margin-left btn-wd btn-round btn-neutral">
            Logout
          </Button>        
        </div>
      </form>
    );
  }


  logout(){
    common.session.fn_logout(this);
  }

  unlock(){    
    common.session.fn_unlock(this, this.textPassword.value);    
  }
}

export default LockScreenPage;
