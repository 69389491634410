import React from "react";
import PlusComponent from "../../myComp/PlusComponent";
import PageGrid from "../../myComp/PageGrid";
import Loader from "../../myComp/Loader";

class InvoiceGrid extends PlusComponent {
    constructor(props) {
      super(props, "documentInvoice");
   
      this.state = {
        grid: null,
      };  
    }
   
    componentDidMount() {
      this.getGrid();
    }
    
    updateGrid(){
      this.getGrid();
    }

    render(){     
        return (
          <div className="main-content">
            <Loader visible={this.state.loadPanelVisible}></Loader>   
            <PageGrid title="Elenco Fatture" data={this.state.grid} controller="documentInvoice" functionality="INVOICE" eventUpdate={this.updateGrid.bind(this)}></PageGrid>
          </div>
        );
    }     
}

export default InvoiceGrid;