"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var _default = {
  /**
   * Dato un array di oggetti controlla se esiste almeno un oggetto con quella chiave/valore
   * @param {array} array : array di oggetti
   * @param {string} key : chiave dell'oggetto
   * @param {string} value : valore da ricercare
   */
  existObjectByKey: function existObjectByKey(array, key, value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return true;
      }
    }

    return false;
  },

  /**
   * Dato un array di oggetti estrae l'elemento con quella chiave/valore
   * @param {array} array : array di oggetti
   * @param {string} key : chiave dell'oggetto
   * @param {string} value : valore da ricercare
   */
  getObjectByKey: function getObjectByKey(array, key, value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }

    return null;
  },

  /**
  * Dato un array di oggetti estrae la lunghezza degli elementi con quella chiave/valore
  * @param {array} array : array di oggetti
  * @param {string} key : chiave dell'oggetto
  * @param {string} value : valore da ricercare
  */
  getLengthByKey: function getLengthByKey(array, key, value) {
    var count = 0;

    for (var i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        count++;
      }
    }

    return count;
  },

  /** Dato un array di oggetti rimuove l'elemento con quella chiave/valore 
   * @param {array} array : array di oggetti
   * @param {string} key : chiave dell'oggetto
   * @param {string} value : valore da ricercare
  */
  removeObjectByKey: function removeObjectByKey(array, key, value) {
    var result = _toConsumableArray(array);

    for (var i = 0; i < result.length; i++) {
      if (result[i][key] === value) {
        result.splice(i, 1);
        break;
      }
    }

    return result;
  },

  /** Dato un array di oggetti ritorna un clone dell'array
   * @param {array} array : array di oggetti
  */
  cloneArray: function cloneArray(array) {
    return array.map(function (x) {
      return Object.assign({}, x);
    });
  }
};
exports.default = _default;