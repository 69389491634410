import React from 'react';
import {common} from 'react_library';

export default function RenderNewsItem(data) {
  return (
    <div className="journal">
        {data.permissionRemove ?
          <span className="spanRemove"><i className="fa fa-minus white pointer" onClick={() => data.component.removeFromDashboardConfirm(data.uuid, data.actionRemoveDashboard)}></i></span>
        : ''}
        <span>{common.date.writePreviewDateByDB(data.creationDate)}</span>
        <h1>{data.elementType}</h1>
        <h2>{data.widgetTitolo}</h2>
        <p>{data.widgetDescrizione}</p>
    </div>
  );
}