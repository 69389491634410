import React from 'react';
import PlusComponent from "../../myComp/PlusComponent";
import ContentView from "../../myComp/ContentView";

class CodeList extends PlusComponent {
  constructor(props) {
    super(props, "contentCode");         
  }

  render(){     
    return (
        <div>
          <ContentView {...this.props} title="Code-x-change" controller="contentCode"></ContentView>
        </div>
      )
    }
}

export default CodeList;