import React from 'react';
import Form, {
    ButtonItem,
    SimpleItem,
    Label,
    GroupItem,
    RequiredRule,CustomRule
  } from 'devextreme-react/form';
import { Grid, Row, Col} from "react-bootstrap";
import Card from "components/Card/Card";
import { FileUploader } from 'devextreme-react';
import 'devextreme-react/autocomplete';
import ArrayStore from 'devextreme/data/array_store';
import Loader from "../../myComp/Loader";
import PlusComponent from "../../myComp/PlusComponent";
import History from '../../myComp/History';
import File from "../../myComp/File";

import {common} from 'react_library';
import ticket from "assets/img/ticket.png";

const API_GET_EMAIL = '/ticket/getEmailByType';


class TicketGet extends PlusComponent {
  constructor(props) {
    super(props, "ticket");

    this.fileChange = this.fileChange.bind(this);
    this.fileChangeIntegration = this.fileChangeIntegration.bind(this);
    this.handleSubmitI = this.handleSubmitI.bind(this);    
    this.getEmail = this.getEmail.bind(this);  
    this.changeCliente = this.changeCliente.bind(this);  
    this.changeContatto= this.changeContatto.bind(this);

    this.buttonOptions = {
      text: 'Invia',
      type: 'success',     
      useSubmitBehavior: true
    };
  }

  componentDidMount(){
    this.getTicket();
  }

  getTicket(){
    this.getData(true);    
  }
  
  refreshState(data){    
    let stato = this.state;    

    stato.ticket=data[0];
    
    stato.ticket["creationDate"]=common.date.writePreviewDateTimeByDB(stato.ticket["creationDate"]);
    stato.ticket["ticketChiusura"]=common.date.writePreviewDateTimeByDB(stato.ticket["ticketChiusura"]);

    if(this.new){
      stato.ticket["ticketContattoDescrizione"]=common.session.fn_getUserNomeUtente();

      stato.ticket["ticketDestinatarioContatto"]=common.session.fn_getUserEmail();
      stato.ticket["ticketFlagUrgenza"]=0;
      stato.ticket["ticketContattoId"]=common.session.fn_getUserContact();
    }
    
    if(this.new && stato.ticket.clienti.length===1){    
      stato.ticket["ticketSoggettoId"]=stato.ticket.clienti[0]["valore"];
      stato.ticket["contattiSoggetto"]=stato.ticket.contatti[stato.ticket["ticketSoggettoId"]];
    }   

     
    stato.ticketAllegatiI=[];
    stato.ticketAllegati=[];

    this.setState(stato);
  }

  changeContatto(){
    let stato = { ...this.state.ticket };
    if(stato.ticketContattoId!="" && stato.ticketContattoId!=undefined){
      let contatto= common.array.getObjectByKey(stato.contattiSoggetto, "uuid", stato.ticketContattoId);
      stato.ticketContattoDescrizione=contatto.iNominativo;

      let emailAccount= common.session.fn_getUserEmail();
      let emailContatto = contatto.email;

      let email="";
      let contains= emailAccount.indexOf(emailContatto);      

      email=emailAccount;
      if(emailAccount!="" && emailContatto!="" && contains<0){        
        email+=";";
      }

      if(contains<0){
        email= email + emailContatto;
      }

      stato.ticketDestinatarioContatto=email;
      this.setState({ticket:stato});
    }

  }

  changeCliente(){
    let ticket= this.state.ticket;    

    if(ticket.ticketSoggettoId!==undefined  && ticket.ticketSoggettoId!==""){      
      ticket.contattiSoggetto= ticket.contatti[ticket.ticketSoggettoId];
      this.setState({ticket:ticket});
    }

    this.getEmail();
  }

  getEmail(){
    let ticket= this.state.ticket;    
    let thisClass = this;       
        
    if(this.new && ticket.ticketTipologia!=="" && ticket.ticketTipologia!==undefined && ticket.ticketSoggettoId!=="" && ticket.ticketSoggettoId!==undefined){
      common.service.postData(this, API_GET_EMAIL, {data:{"tipologiaId":ticket.ticketTipologia, "soggettoId": ticket.ticketSoggettoId}})
        .then(data => {        
          common.notify.notifyServerResponse(thisClass,data, "",function(data){thisClass.refreshEmail(data)});          
        });
    }
  }

  refreshEmail(data){    
    let stato = { ...this.state.ticket };
    stato.ticketDestinatariMailApertura=data["email"];

    this.setState({ticket:stato});
  
  }

  get initialState(){
    return {
      ticket: {},      
      integration:{ "allegati":[]},
    };
  }

  render() {
    const {ticket} = this.state;

    let integration = this.state.integration;
 

    let dataSourceClienti = new ArrayStore({
      data: ticket.clienti,
      key: 'valore'
    });    

    this.clientiOption={
      dataSource:dataSourceClienti,
      displayExpr:"display",
      valueExpr:"valore" ,
      value:ticket.ticketSoggettoId,
      onValueChanged:this.changeCliente,
      disabled: !this.new
    };

    let dataSourceContatti = new ArrayStore({
      data: ticket.contattiSoggetto,
      key: 'valore'
    });  
   
      
    this.contattiOption={
      dataSource:dataSourceContatti,
      displayExpr:"iNominativo",
      valueExpr:"uuid" ,
      value:ticket.ticketContattoId,
      onValueChanged:this.changeContatto,
      disabled: !this.new
    };

    let dataSourceTipologie = new ArrayStore({
      data: ticket.tipologie,
      key:'valore',
    })

    this.tipologieOption={
      dataSource:dataSourceTipologie,
      displayExpr:"display",
      valueExpr:"valore",
      onValueChanged:this.getEmail,
      disabled: !this.new
    };

    
    let dataSourceUrgenza = new ArrayStore({
      data: [{"valore":0, "display":"Normale"}, {"valore":1, "display":"Urgente"}, {"valore":2, "display":"Bloccante"}],
      key:'valore',
    });

    this.textAreaOption={ 
      disabled: !this.new, 
      height:"150"
    };

    this.ugenzaOption={
      dataSource:dataSourceUrgenza,
      displayExpr:"display",
      valueExpr:"valore",
      disabled: !this.new
    };

    this.ugenzaOptionNotDisabled={
      dataSource:dataSourceUrgenza,
      displayExpr:"display",
      valueExpr:"valore",
    };

    if(ticket.history===undefined){
      ticket.history=[];
    }

    return (
      <div className="main-content">
        <Loader visible={this.state.loadPanelVisible}></Loader>

        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={7}>
                <Card
                  title="Dati ticket"
                  ctAllIcons              
                  content={    
                    <React.Fragment>
                      <form action="" onSubmit={this.handleSubmit} encType="multipart/form-data">
                        <Form
                          formData={ticket}
                          readOnly={false}
                          showColonAfterLabel={true}
                          showValidationSummary={false}
                          labelLocation="top"
                          validationGroup="ticketData1"                      
                        >
                          <GroupItem cssClass="first-group" colCount={5}>
                              <SimpleItem render={avatarRender}>
                              </SimpleItem>
                              <GroupItem colSpan={2}>                     
                                <SimpleItem dataField="ticketId" editorOptions={{disabled: true}}>
                                    <Label text="Numero" />
                                </SimpleItem>
                                            
                                <SimpleItem dataField="ticketChiusura" editorOptions={{disabled: true}}>
                                  <Label text="Data chiusura" />
                                </SimpleItem>     
                              </GroupItem>
                              <GroupItem colSpan={2}>     
                              <SimpleItem dataField="creationDate" editorOptions={{disabled: true}}>
                                    <Label text="Data richiesta" />
                              </SimpleItem> 
                                                                                                                              
                              </GroupItem>
                          </GroupItem>
                          <GroupItem colCount={2}>    
                                <SimpleItem dataField="ticketTipologia" editorType="dxSelectBox" editorOptions={this.tipologieOption}>
                                  <Label text="Tipologia"/>
                                  <RequiredRule message="Tipologia obbligatoria" />                
                                </SimpleItem>     
                                                
                                <SimpleItem dataField="ticketFlagUrgenza" editorType="dxSelectBox" editorOptions={this.ugenzaOption}>
                                    <Label text="Urgenza"/>
                                    <RequiredRule message="Urgenza obbligatoria" />                
                                </SimpleItem>     
  
                            </GroupItem>
                          <GroupItem colCount={2}>                                     
                            <SimpleItem dataField="ticketSoggettoId" editorType="dxSelectBox" editorOptions={this.clientiOption}>
                              <Label text="Cliente"/>
                              <RequiredRule message="Cliente obbligatorio" />                
                            </SimpleItem> 

                          
                            <SimpleItem dataField="ticketDestinatariMailApertura" editorType="dxTextBox" editorOptions={{disabled: true}}>
                              <Label text=" E-mail"/>
                              <CustomRule  validationCallback={this.validateEmail} message="Le e-mail non sono valide"/>                            
                            </SimpleItem>

                          </GroupItem>
                          <GroupItem colCount={2}>                               
                            <SimpleItem dataField="ticketContattoId" editorType="dxSelectBox" editorOptions={this.contattiOption}>
                              <Label text="Contatto"/>
                            </SimpleItem> 

                            <SimpleItem dataField="ticketContattoDescrizione" editorType="dxTextBox" editorOptions={this.disableOption}>
                              <Label text="Contatto (Nome e cognome)"/>
                              <RequiredRule message="Contatto obbligatorio" />                
                            </SimpleItem>

                          </GroupItem>

                          <GroupItem colCount={1}>    
                            <SimpleItem visible={this.new} dataField="ticketDestinatarioContatto" editorType="dxTextBox" editorOptions={this.disableOption}>
                              <Label text=" E-mail contatto (separate da ;) "/>
                              <RequiredRule message="E-mail obbligatoria" />       
                              <CustomRule validationCallback={this.validateEmail} message="Le e-mail non sono valide"/>                            
                            </SimpleItem> 
                            </GroupItem>
  
                            <GroupItem colSpan={5}>                                     
                            <SimpleItem dataField="ticketTitolo" editorType="dxTextBox" editorOptions={this.disableOption}>
                              <Label text="Oggetto"/>
                              <RequiredRule message="L' oggetto è obbligatorio" />                
                            </SimpleItem>                             
                            <SimpleItem dataField="ticketDescrizione" editorType="dxTextArea" editorOptions={this.textAreaOption}>
                              <Label text="Descrizione"/>
                              <RequiredRule message="La descrizione è obbligatoria" />                
                            </SimpleItem>  
                          </GroupItem>

                          <GroupItem visible={this.new}>
                            <File 
                              changeFile={this.fileFormChange} 
                              deleteFile={this.fileFormDelete}
                              allegati={this.state.ticketAllegati}
                              name="ticketAllegati"
                              buttonName="Carica immagine"
                              multiple={true}
                              accept="image/*"     
                              type=""                    
                            >                
                          </File> 
                          </GroupItem>
                          
                          <GroupItem visible={this.new}>
                            <ButtonItem horizontalAlignment="left"
                              buttonOptions={this.buttonSaveOptions}
                            />
                          </GroupItem>

                        </Form>                      
                      </form>
                    </React.Fragment>
                  }
                />
              </Col>
                
              <div hidden={this.new || ticket.ticketStatus===2}>
                <Col md={5}>
                  <Card
                    title="Integra il ticket"
                    content={                
                      <form action="" onSubmit={this.handleSubmitI} encType="multipart/form-data">
                        <Form
                          formData={integration}
                          readOnly={false}
                          showColonAfterLabel={true}
                          showValidationSummary={false}
                          labelLocation="top"
                          validationGroup="ticketData"
                        >
                          <GroupItem>              
                            <SimpleItem dataField="testoIntegrazione" editorType="dxTextArea" editorOptions={{height:"150"}}>
                              <Label text="Testo"/>
                              <RequiredRule message="Testo obbligatorio" />                
                            </SimpleItem>            
                            <SimpleItem dataField="sollecito" editorType="dxCheckBox">
                              <Label text="Sollecito"/>
                            </SimpleItem>                 
                            <SimpleItem dataField="flagUrgenza" editorType="dxSelectBox" editorOptions={this.ugenzaOptionNotDisabled}>
                                  <Label text="Urgenza"/>
                            </SimpleItem>     
                          </GroupItem>

                          
                          <GroupItem>
                            <File 
                              changeFile={this.fileFormChange} 
                              deleteFile={this.fileFormDelete}
                              allegati={this.state.ticketAllegatiI}
                              name="ticketAllegatiI"
                              buttonName="Carica immagine"
                              multiple={true}
                              accept="image/*"     
                              type=""                    
                            >                
                          </File> 
                          </GroupItem>

                        

                          <GroupItem colCount={1}>  
                            <ButtonItem horizontalAlignment="left" location="right"
                              buttonOptions={this.buttonOptions} />
                          </GroupItem>                  
                        </Form>                                      
                      </form>
                    }
                  />
                </Col>
              </div>

              <div hidden={!(!this.new && ticket.history.length>0)}>            
                <Col md={12}>
                  <Card
                    title="Storia"
                    content={                
                      <History data={ticket.history}></History>
                    }
                  />
                </Col>
              </div>
            </Row>
          </Grid>
        </div>
      </div>
    );
  }

  /*
    <GroupItem>
                            <div className="fileuploader-container">
                                <FileUploader name="ticketAllegati" selectButtonText="Carica file" labelText="" multiple={true} accept="image/*"
                                uploadMode="useForm" onValueChanged={this.fileChangeIntegration} value={this.state.integration.allegati} />        
                            </div> 
                          </GroupItem> 
                          
                          
                          
                          
                          <GroupItem visible={this.new}>
                            <div className="fileuploader-container">
                                <FileUploader name="ticketAllegati" labelText="" multiple={true} accept="image/*" 
                                uploadMode="useForm" onValueChanged={this.fileChange} selectButtonText="Carica allegato"/>        
                            </div> 
                          </GroupItem>   
                          
                          
                          
                          */

  checkComparison() {
    return true;
  }  

  handleSubmit(e) {    
    let ticket=this.state.ticket;

    if(ticket.ticketDestinatarioContatto!=="" && ticket.ticketDestinatarioContatto!==undefined){
      ticket.ticketDestinatariMailApertura+="; "+ticket.ticketDestinatarioContatto;
    }

    let data={};
    data.ticketTipologia=ticket.ticketTipologia;
    data.ticketSoggettoId=ticket.ticketSoggettoId;
    data.ticketDestinatariMailApertura=ticket.ticketDestinatariMailApertura;
    data.ticketTitolo=ticket.ticketTitolo;
    data.ticketDescrizione=ticket.ticketDescrizione;
    //data.ticketAllegati=ticket.ticketAllegati;
    data.ticketFlagUrgenza= ticket.ticketFlagUrgenza;
    data.ticketContattoDescrizione=ticket.ticketContattoDescrizione;
    data.ticketContattoId= ticket.ticketContattoId;
    //data.utenteId= common.session.fn_getUserContact();
    data.ticketContattoEmail= ticket.ticketDestinatarioContatto;

    data.ticketAllegati=this.state.ticketAllegati;

    

    this.save(data, "Ticket inoltrato correttamente");

    e.preventDefault();
  }
    
  validateEmail(e) {  
    if(e.value!==""){
      var listaEmail = e.value.split(";");
      var okEmail = true;
    
      listaEmail.forEach(value => {    
        if (!common.email.validaEmail(value.trim())) {
          okEmail = false;
        }
      });  
    
      return okEmail;
    }
    else{
      return true;
    }
  }

  fileChange(e){
      let stato= this.state;      

      let files=e.component.option('value');

      stato.ticket.ticketAllegati=[];

      for (let key in files){
        common.file.getBase64(files[key], (result) => {
          let allegato={};
          allegato.name=files[key]['name'];
          allegato.size=files[key]['size'];          
          allegato.type=files[key]['type'];          
          allegato.content=result;         

          stato.ticket.ticketAllegati.push(allegato);
        });   
     }

      this.setState(stato);          
  }  


  fileChangeIntegration(e){
    let stato = this.state;    

    let files=e.component.option('value');

    stato.integration.ticketAllegati=[];

    for (let key in files){
        common.file.getBase64(files[key], (result) => {
        let allegato={};
        allegato.name=files[key]['name'];
        allegato.size=files[key]['size'];          
        allegato.type=files[key]['type'];          
        allegato.content=result;         

        stato.integration.ticketAllegati.push(allegato);
      });   
   }

   stato.integration.allegati=files;

    this.setState(stato);          
  }


  handleSubmitI(e) {  
    let data=this.state.integration;
    data.ticketAllegati=this.state.ticketAllegatiI;
    //data.utenteId= common.session.fn_getUserContact();
    let thisClass=this;
        
    common.service.postData(this,'/ticket/integration/'+this.uuid, {data:data})
        .then(data => {
          common.notify.notifyServerResponse(thisClass,data, "Integrazione inviata", function(){thisClass.resetPage(thisClass)});          
    });     
    e.preventDefault();
  }

  resetPage(){
    let stato = this.state;
    stato.integration={"allegati":[]};
    this.setState(stato);

    this.getTicket();
  }  
}


function avatarRender() {
  return (
    <img className="form-avatar" src={ticket} alt=""></img>
  );
}


export default TicketGet;