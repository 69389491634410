import React from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  GroupItem,
} from 'devextreme-react/form';

import Card from "components/Card/Card.jsx";
import UserCard from "components/Card/UserCard.jsx";
import DxGrid from "../../myComp/DxGrid";
import { NavLink } from "react-router-dom";

import avatar from "assets/img/default-avatar_old.png";
import company from "assets/img/company.jpg";

import bgAssistenza from "assets/img/assistenza.jpg";
import {common} from 'react_library';

import PlusComponent from "../../myComp/PlusComponent";
import File from "../../myComp/File";
import Loader from "../../myComp/Loader";


import keyActive from "assets/img/icon_p2_key_green.png";
import keyNotActive from "assets/img/icon_p2_key.png";

let logo="";

class ProfileGet extends PlusComponent {
  contentImmagine="";

  constructor(props) {
    super(props, "account");

    this.state={
      "user":{},
      "accountLogo":[],
    }  

    this.disabledOption={
      "disabled":true
    }
  }

  componentDidMount(){
     this.getProfile();
  }

  getProfile(){
    this.uuid= common.session.fn_getUserUuid();

    this.getData(true, {"data":{parent:""}});
       
  }

  refreshState(data){    
    let stato = this.state;
    stato.user=data.default[0];
    stato.accountLogo=stato.user.wrmLoginFoto;
    this.contentImmagine=stato.user.wrmLoginFoto;
    this.setState(stato);
  }


  render() {
    let user=this.state.user;
    let content="";

    if(user["wrmLoginBase"]!==1 && user["wrmLoginBase"]!==undefined){
      this.props.history.push(`/portal/account-page?parent=${user["wrmLoginParent"]}&uuid=${common.session.fn_getUserUuid()}`);
    }


    if(user["allegati"]!==undefined && user["allegati"][0]!==undefined){
      content = user["allegati"][0]["content"];
    }
    
    
    if(this.contentImmagine!=="" && this.contentImmagine!==undefined && this.contentImmagine[0]!==undefined && this.contentImmagine[0]["content"]!==undefined){
      logo= "data:"+this.contentImmagine[0]["type"]+";base64," + this.contentImmagine[0]["content"];                                       
    }
    else{
      logo="";
    }

    if(user["wrmLoginBase"]!==undefined){
      return (
        <div className="main-content">
          <Grid fluid>
            <Row>
              <Col md={8}>
                <Card
                  title="I miei dati"
                  content={          
                    <form action="" onSubmit={this.handleSubmit} encType="multipart/form-data">      
                      <Form formData={user} labelLocation="top">
                        <GroupItem cssClass="first-group" colCount={5}>
                          <SimpleItem render={avatarRender}>
                              </SimpleItem>
                            <GroupItem colSpan={4}>                     
                              <SimpleItem dataField="ragioneSociale" editorOptions={this.disabledOption}>
                                  <Label text="Ragione sociale" />
                              </SimpleItem>
                              <SimpleItem dataField="wrmLoginEmail" editorOptions={this.disabledOption}>
                                  <Label text="E-mail" />
                              </SimpleItem>                      
                            </GroupItem>
                          </GroupItem>
                          <GroupItem cssClass="second-group" colCount={2}>
                            <GroupItem>
                              <SimpleItem dataField="indirizzo" editorOptions={this.disabledOption}>
                                  <Label text="Indirizzo" />
                              </SimpleItem>
                              <SimpleItem dataField="piva" editorOptions={this.disabledOption}>
                                  <Label text="Partita iva" />
                              </SimpleItem>
                            </GroupItem>
                            <GroupItem>
                              <SimpleItem dataField="localita" editorOptions={this.disabledOption}>
                                  <Label text="Località" />
                              </SimpleItem>
                              <SimpleItem dataField="telefono" editorOptions={this.disabledOption}>
                                  <Label text="Telefono" />
                              </SimpleItem>                      
                            </GroupItem>                    
                        </GroupItem>
                        <GroupItem colCount={1}>
                          <GroupItem>
                          <GroupItem colCount={1} cssClass="first-group" visible={user["wrmLoginBase"]===1 && !this.checkFunctionality("LOGO_NEW")}>
                            <Label text="Foto profilo" />
                            <File 
                              changeFile={this.fileFormChange} 
                              deleteFile={this.fileFormDelete}
                              allegati={this.state.accountLogo}
                              name="accountLogo"
                              buttonName="Carica immagine"
                              multiple={false}
                              accept="image/*"     
                              type=""                    
                            >                
                            </File>      
                            
                          </GroupItem>
                          
                          <ButtonItem horizontalAlignment="left" location="right"
                            buttonOptions={this.buttonSaveOptions}
                          /> 
                          </GroupItem>
                        </GroupItem>
                        <GroupItem colCount={6} visible={user["wrmLoginBase"]===1 && !this.checkFunctionality("LOGO_NEW")}>  
                          
                        </GroupItem> 
                    </Form>
                  </form>
                  }
                />
              </Col>
                          
              <div hidden={user["accountManager"]===""}>
                <Col md={4}>
                  <UserCard
                    bgImage={bgAssistenza}
                    avatar={avatar}
                    image={content}
                    name={user["accountManager"]}
                    userName="Account manager"
                    description=""
                    socials=""
                  />
                </Col>
              </div>
            
              <div hidden={user["wrmLoginBase"]!==1}>
                <Col md={8}>
                  <Card
                    title="Accessi"
                    content={       
                      <div>
                        <div className="btnTopGrid" hidden={this.checkFunctionality("ACCESS_NEW")}>                      
                          <NavLink
                                to={{pathname:"/portal/account-page", search: "?parent="+common.session.fn_getUserUuid()}}
                                className="btn btn-secondary"
                                activeClassName="active"
                          >Nuovo</NavLink>         
                        
                        </div>

                        {user["accounts"]!==undefined ? 
                          <DxGrid 
                            data={user["accounts"]} 
                            data-key="uuid"
                            gridUpdate = {this.handleToUpdate.bind(this)}
                            delete={{permission:"ACCESS_DELETE"}}                               
                            update={{permission: "ACCESS_UPDATE", 
                                    pathname:"/portal/account-page",
                                    search: `?parent=${common.session.fn_getUserUuid()}&uuid=#KEY#`}}>                                
                          </DxGrid> : ''}
                      </div>
                    }
                  />
                </Col>
              </div>

              {user["license"]!==undefined && user["license"].length>0 ?
                  <Col md={4}>
                    <Card
                      title="Licenze prodotti"
                      ctAllIcons              
                      content={    
                        <div>                        
                          { user.license!==undefined ? user.license.map((value, index) => {
                              let uuid=value["requestId"];
                              let prodottoId = value["prodottoid"];
                              let datacrea = common.date.writePreviewDateTimeByDB(value["creationDate"]);
                              let rilasciata = value["rilasciata"];
                              //let dataRilascio = common.date.writePreviewDateTimeByDB(value["rilasciodata"]);

                              return (
                                <div className="listaChiavi" key={uuid}>
                                  <div className="divRichiesta">
                                    <div className="myCard">
                                      <div className="card-header">
                                        <div className="media align-items-center">
                                            <div className="media-left">
                                              <img alt="" className="rounded-circle" width="50" src={rilasciata===1 ? keyActive : keyNotActive}></img>
                                            </div>
                                            <div className="media-body">
                                              <h4 className="card-title ragioneSociale">
                                                {prodottoId.toUpperCase()}
                                              </h4>
                                              <p className="card-subtitle">
                                                DATA CREAZIONE: <span className="piva">{datacrea}</span></p>                                             
                                            </div>
                                            <div className="media-right">
                                              <div hidden={this.checkFunctionality("LICENSE_DETAIL")} className="btn btn-secondary btn-sm calendarButtons btnFunctions_ btnLink" onClick={() => this.goToKey(user.uuid, uuid)}>
                                                <i className="fa fa-angle-right"></i>                                              
                                              </div>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>                                  
                                </div>
                              )              
                          }) :''}                      
                        </div>
                        }
                      />
                    </Col>
                  :''}                
            </Row>
          </Grid>
        </div>
      );
    }
    else{
      return (<div className="main-content"><Loader visible={this.state.loadPanelVisible}></Loader></div>);
    }
  }

  handleSubmit(e) {    
    let thisClass=this;
    
    let data ={};
    data.wrmLoginFoto=this.state.accountLogo;
           
    common.service.postData(this,'/account/update/'+this.state.user.uuid, {data:data})
        .then(data => {
          common.notify.notifyServerResponse(thisClass,data, "Account memorizzato correttamente", function(){thisClass.getProfile(thisClass)});          
    });     

    e.preventDefault();
  }


  goToKey(soggettoId, uuid){
    this.props.history.push('/portal/licenseKey/view?soggetto='+soggettoId+'&uuid='+uuid);   
  }

  handleToUpdate(){
    this.getProfile();
  }
}

function avatarRender() {
  return (
    <img alt="" className="form-avatar" src={logo==="" ? company : logo}></img>
  );
}

export default ProfileGet;