"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _number = _interopRequireDefault(require("./number"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _default = {
  /**
   * Dato un numero di bytes ritorna una stringa formattata con valore e unità di misura più leggibili 
   * @param {integer} val: numero di bytes
   * @returns 
   */
  formatBytesIntoString: function formatBytesIntoString(val) {
    if (val < 1023) {
      return val + " Byte";
    } else if (val >= 1024 && val < 1048576) {
      return _number.default.roundVal(val / 1024) + " KB";
    } else if (val >= 1048576 && val < 1073741824) {
      return _number.default.roundVal(val / 1048576) + " MB";
    } else if (val >= 1073741824 && val < 1099511628000) {
      return _number.default.roundVal(val / 1073741824) + " GB";
    } else {
      return _number.default.roundVal(val / 1099511628000) + " TB";
    }
  },

  /**
   * Dato un tipo di documento dalla tabella xd_documents formatta l'output in tipi generici 
   * @param {string} input: tipo di documento da formattare
   * @returns 
   */
  formatDocumentType: function formatDocumentType(input) {
    var estensioniImmagini = ["gif", "jpg", "jpeg", "png", "webp"];
    var estensioniVideo = ["avi", "divx", "flv", "mp4", "mpeg", "mpg", "wmv", "xvid"];
    var estensioniWord = ["doc", "docx", "word"];
    var estensioniExcel = ["xlsx", "xls", "csv"];
    var estensioniPdf = ["pdf"];
    var estensioniTxt = ["txt", "plain", "html"];
    var estensioniZip = ["zip"];
    var output = "";

    if (input !== "" || input !== null || input !== undefined) {
      output = "File";
      estensioniImmagini.forEach(function (el) {
        if (input.toLowerCase().includes(el)) {
          output = "Immagine";
        }
      });
      estensioniVideo.forEach(function (el) {
        if (input.toLowerCase().includes(el)) {
          output = "Video";
        }
      });
      estensioniWord.forEach(function (el) {
        if (input.toLowerCase().includes(el)) {
          output = "Word";
        }
      });
      estensioniExcel.forEach(function (el) {
        if (input.toLowerCase().includes(el)) {
          output = "Excel";
        }
      });
      estensioniPdf.forEach(function (el) {
        if (input.toLowerCase().includes(el)) {
          output = "PDF";
        }
      });
      estensioniTxt.forEach(function (el) {
        if (input.toLowerCase().includes(el)) {
          output = "File di Testo";
        }
      });
      estensioniZip.forEach(function (el) {
        if (input.toLowerCase().includes(el)) {
          output = "Zip";
        }
      });
    }

    return output;
  },

  /**
   * Funzione che accetta un documento da formattare e tre booleani per decidere l'output (torna un oggetto con coppie key/value in base ai booleani) 
   * @param {Object} documentObj: documento da formattare
   * @param {Boolean} needSize: true se l'oggetto di ritorno deve contenere le dimensioni formattate 
   * @param {Boolean} needType: true se l'oggetto di ritorno deve contenere il tipo del documento formattato 
   * @param {Boolean} needIcon: true se l'oggetto di ritorno deve contenere l'icona del documento 
   * @returns 
   */
  formatDocumentObj: function formatDocumentObj(documentObj, needSize, needType, needIcon) {
    var formattedObj = {};

    if (needSize) {
      formattedObj.size = this.formatBytesIntoString(documentObj.size);
    }

    if (needType) {
      formattedObj.type = this.formatDocumentType(documentObj.name);
    }

    if (needIcon) {
      formattedObj.icon = {
        className: "",
        textColor: ""
      };

      if (this.formatDocumentType(documentObj.name) === "Immagine") {
        formattedObj.icon.className = "ri-image-2-fill";
        formattedObj.icon.textColor = "text-body-secondary";
      }

      if (this.formatDocumentType(documentObj.name) === "Zip") {
        formattedObj.icon.className = "ri-folder-zip-line";
        formattedObj.icon.textColor = "text-body-secondary";
      }

      if (this.formatDocumentType(documentObj.name) === "PDF") {
        formattedObj.icon.className = "ri-file-pdf-fill";
        formattedObj.icon.textColor = "text-danger";
      }

      if (this.formatDocumentType(documentObj.name) === "Video") {
        formattedObj.icon.className = "ri-video-line";
        formattedObj.icon.textColor = "text-body-secondary";
      }

      if (this.formatDocumentType(documentObj.name) === "Excel") {
        formattedObj.icon.className = "ri-file-excel-line";
        formattedObj.icon.textColor = "text-success";
      }

      if (this.formatDocumentType(documentObj.name) === "Word") {
        formattedObj.icon.className = "ri-file-word-line";
        formattedObj.icon.textColor = "text-info";
      }

      if (this.formatDocumentType(documentObj.name) === "File di Testo") {
        formattedObj.icon.className = "ri-file-list-line";
        formattedObj.icon.textColor = "text-body-secondary";
      }

      if (this.formatDocumentType(documentObj.name) === "File") {
        formattedObj.icon.className = "ri-file-3-line";
        formattedObj.icon.textColor = "text-body-secondary";
      }
    }

    return formattedObj;
  }
};
exports.default = _default;