import React from 'react';
import PlusComponent from "../../myComp/PlusComponent";
import ContentView from "../../myComp/ContentView";

class ManualList extends PlusComponent {
  constructor(props) {
    super(props, "contentManual");         
  }

  render(){     
    return (
        <div>
          <ContentView {...this.props} title="Manuali" controller="contentManual"></ContentView>
        </div>
      )
    }
}

export default ManualList;