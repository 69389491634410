import React from 'react';
import Form, {
    ButtonItem,
    SimpleItem,
    Label,
    RequiredRule,GroupItem
  } from 'devextreme-react/form';
import { Grid, Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";
import { FileUploader } from 'devextreme-react';
import {common} from 'react_library';
import Loader from "../../myComp/Loader";
import PlusComponent from "../../myComp/PlusComponent";

class DemoGet extends PlusComponent {
  constructor(props) {
    super(props, "demo");
    
    this.fileChange = this.fileChange.bind(this);  
  }

  componentDidMount(){  
    this.getData();    
  }

  refreshState(data){    
    let stato = this.state;
    stato.demo=data.default[0];
    this.setState(stato);
  }

  get initialState(){       
    return {
      demo: {               
      },
    };
  }

  render() {        
      const {demo} = this.state;                  
      
      return (
        <div className="main-content">
          <Loader visible={this.state.loadPanelVisible}></Loader>
          <div className="content">
          <Grid fluid>
            <Row>
              <Col md={8}>
                <Card
                  title="Richiesta demo"
                  ctAllIcons              
                  content={    
                    <React.Fragment>
                      {
                        !this.new ? 
                        <div>
                          {demo.stato==="1" ?
                            <div>
                              <div className="col-lg-12 confermato">
                                <div className="alert alert-success" role="alert">
                                <strong>Demo confermata</strong> per il { common.date.writePreviewDateByDB(demo.dataconfermata)}             
                                </div>             
                              </div>
                            </div>                          
                            :''}

                          {demo.stato==="2" ?
                            <div>
                              <div className="col-lg-12 nonConfermato">
                                <div className="alert alert-danger" role="alert">
                                  <strong>Demo non confermata</strong>                          
                                </div>             
                              </div>
                            </div>                          
                            :''}
                          </div>
                        :''
                      }

                      <form action="" onSubmit={this.handleSubmit} encType="multipart/form-data">
                        <Form
                          formData={demo}
                          readOnly={false}
                          showColonAfterLabel={true}
                          showValidationSummary={false}
                          labelLocation="top"
                          validationGroup="demoData"
                        >                          
                          
                          <GroupItem colCount={1}>  
                            <SimpleItem dataField="richiestaDa" editorType="dxTextBox" editorOptions={this.disableOption}>
                              <Label text="Richiesta da"/>
                              <RequiredRule message="Campo richiesta da obbligatorio" />                
                            </SimpleItem>                              

                            <SimpleItem dataField="richiesta" editorType="dxTextArea" editorOptions={this.disableOption}>
                              <Label text="Richiesta"/>
                              <RequiredRule message="Richiesta obbligatoria" />                                  
                            </SimpleItem>  
                          </GroupItem>

                          <GroupItem colCount={2}>  
                            <SimpleItem dataField="ragionesociale" editorType="dxTextBox" editorOptions={this.disableOption}>
                              <Label text="Ragione sociale"/>
                              <RequiredRule message="Ragione sociale obbligatoria" />                                  
                            </SimpleItem>  

                            <SimpleItem dataField="partitaIva" editorType="dxTextBox" editorOptions={this.disableOption}>
                              <Label text="Partita iva"/>
                              <RequiredRule message="Partita iva obbligatoria" />                                  
                            </SimpleItem>  
                          </GroupItem>
                          
                          <GroupItem colCount={1}>  
                            <SimpleItem dataField="presso" editorType="dxTextArea" editorOptions={this.disableOption}>
                              <Label text="Presso"/>
                              <RequiredRule message="Presso obbligatoria" />                                  
                            </SimpleItem>  
                          </GroupItem>

                          <GroupItem colCount={2}>  
                            <SimpleItem dataField="data1" editorType="dxDateBox" editorOptions={this.dateScadenza}>
                              <Label text="Data"/>
                              <RequiredRule message="Data obbligatoria" />                                  
                            </SimpleItem>  
                            
                            <SimpleItem dataField="data2" editorType="dxDateBox" editorOptions={this.dateScadenza}>
                              <Label text="Data"/>
                              <RequiredRule message="Data obbligatoria" />                                  
                            </SimpleItem>  
                          </GroupItem>

                          <GroupItem colCount={1}>  
                            <SimpleItem dataField="notainterna" editorType="dxTextArea" editorOptions={this.disableOption}>
                              <Label text="Note"/>                            
                            </SimpleItem>  
                          </GroupItem>

                          {this.new ?
                            <GroupItem>
                              <div className="fileuploader-container">
                                  <FileUploader name="demoAllegati" labelText="" multiple={true} accept="image/*" 
                                  uploadMode="useForm" onValueChanged={this.fileChange}  selectButtonText="Carica allegato"/>        
                              </div> 
                            </GroupItem>   
                          :''}
                          
                          {this.new ?                          
                            <GroupItem colCount={6}>  
                              <ButtonItem horizontalAlignment="left" location="right"
                                buttonOptions={this.buttonSaveOptions}
                              />
                            </GroupItem>                  
                          :''}
                        </Form>                    
                      
                      </form>
                    </React.Fragment>
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      );   
  }

  handleSubmit(e) {
    let demo=this.state.demo;

    this.save(demo, "Richiesta demo memorizzata correttamente", "L");
    
    e.preventDefault();
  }

  fileChange(e){
    let stato= this.state;      

    let files=e.component.option('value');

    stato.demo.demoAllegati=[];

    for (let key in files){
      common.file.getBase64(files[key], (result) => {
        let allegato={};
        allegato.name=files[key]['name'];
        allegato.size=files[key]['size'];          
        allegato.type=files[key]['type'];          
        allegato.content=result;         

        stato.demo.demoAllegati.push(allegato);
      });   
   }

    this.setState(stato);          
  }

}

export default DemoGet;