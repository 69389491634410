/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";

export class UserCard extends Component {
  render() {
    return (
      <div className={this.props.addClass !==undefined ? "card card-user "+ this.props.addClass : "card card-user"}>
        <div className="image">
          <img src={this.props.bgImage} alt="..." />
        </div>
        <div className="content">
          <div className="author">
            {(this.props.image==="" || this.props.image===undefined) ? 
              <img className="avatar border-gray" src={this.props.avatar} alt="..."/>
            : <img  className="avatar border-gray" src={"data:image/png;base64," +  this.props.image} alt=""/>}                        
            <h4 className="title">
              {this.props.name}
              <br />
              <small>{this.props.userName}</small>
            </h4>
          </div>
          <p className="description text-center">{this.props.description}</p>
        </div>
        <hr />
        <div className="text-center">{this.props.socials}</div>
      </div>
    );
  }
}

export default UserCard;


/*
<img
className="avatar border-gray"
src={this.props.avatar}
alt="..."
/>
*/