import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import Loader from "../../myComp/Loader";
import Calendar from 'devextreme-react/calendar';

import {common} from 'react_library';
import PlusComponent from "../../myComp/PlusComponent";


class CourseList extends PlusComponent {
    listData=[];

    constructor(props) {
      super(props, "course");
   
      this.state = {
        list: [],
        currentValue: new Date(),
        selectedDate: '',
      };  
    }

    componentDidMount() {
      this.getList();
    }  

    render(){     
        const { list } = this.state;
        this.listData=[];

        return (
          <div className="main-content">
              <Loader visible={this.state.loadPanelVisible}></Loader>
              <Grid fluid>
                <Row>
                  <Col md={4}>                           
                    <div className ="calendar">
                      <Calendar
                        id="calendar-container"
                        value={this.state.currentValue}
                        disabled={false}
                        onValueChanged={this.onCurrentValueChanged.bind(this)}
                        zoomLevel="month"
                        firstDayOfWeek={0}
                        cellRender={CustomCell.bind(this)} 
                        />
                    </div>
                    <br /><br />
                    <span className="btn btn-warning" onClick={this.getAll.bind(this)}>Tutti</span>
                    
                    <br /><br />
                    <h3>Programmazione</h3>  
                    <div className="listEvents">
                      <div>
                        {list!==undefined ? list.map((value, index) => {
                          let uuid= value["uuid"];
                          let titolo = value["corsoTitolo"];
                          let corsoDataInizio = value["corsoDataInizio"];
                          let corsoDataFine = value["corsoDataFine"];
                                              
                          let dataInizio = common.date.writePreviewDateByDB(corsoDataInizio);                  
                          let corsoData = dataInizio + " - " + common.date.writePreviewDateByDB(corsoDataFine);
                                      
                          if (this.state.selectedDate === "" || this.state.selectedDate === dataInizio) {
                            return (
                                <div key={index}>
                                  <div className="linkSpan" onClick={() => this.goToKey(uuid)}><span>{corsoData}</span><br></br>{titolo}</div>
                                </div>
                            )                                  
                          }
                          else{
                            return (<div key={index}></div>);
                          }    
                        }) :"" }                                       
                      </div>  
                    </div>
                  </Col>
                  <Col md={8}>        
                    {list.length>0 ?
                      <div className="timeline__page">
                          <div className="section__timeline">  
                            <ul className="timeline">
                              {list!==undefined ? list.map((value, index) => {
                                let uuid= value["uuid"];
                                let titolo = value["corsoTitolo"];
                                let corsoTipologia = value["corsoTipologia_str"];
                                let enabledIscrizione = value["enabledIscrizione"];                            
                                let corsoDataInizio = value["corsoDataInizio"];
                                let corsoDataFine = value["corsoDataFine"];
                                let descrizione=value["corsoDescrizione"];
                                let relatore=value["corsoUtenteRelatore_str"];
                                                    
                                let dataInizio = common.date.writePreviewDateByDB(corsoDataInizio);
                        
                                let corsoData = dataInizio + " - " + common.date.writePreviewDateByDB(corsoDataFine);
                                let corsoOra = common.date.writePreviewDateTimeByDB(corsoDataInizio) + " - " + common.date.writePreviewDateTimeByDB(corsoDataFine);
                        
                                let badgeClass = "bg-green";
                        
                                if (enabledIscrizione === -1)
                                    badgeClass = "bg-red";
                                    
                                this.listData.push({"dataInizio":dataInizio});

                                if (this.state.selectedDate === "" || this.state.selectedDate === dataInizio) {
                                  return (
                                      <li key={index}>                                      
                                        <div className={"timeline-badge primary " + badgeClass }>
                                          <i className='fa fa-user'></i>
                                        </div>
                                        <div className="timeline-panel">
                                          <div className='timeline-heading'><span className='timeline-title'>{titolo}</span></div>
                                          <div className='timeline-info'>
                                              <div className='timeline-info__item'>
                                                <i className='fa fa-bookmark'></i>
                                                <p>{corsoTipologia}</p>
                                              </div>
                                              <div className='timeline-info__item'>
                                                <i className='fa fa-calendar'></i>
                                                <p>{corsoData}</p> 
                                              </div>
                                              <div className='timeline-info__item'>
                                                <i className='fa fa-clock-o'></i>
                                                <p>{corsoOra}</p>
                                              </div>
                                          </div>
                                          <div className='timeline-body'><p>{descrizione}</p></div>
                                          <div className='timeline-price'><h3>Relatore: <span>{relatore}</span></h3></div>
                                          <div className='timeline-link' hidden={this.checkFunctionality("COURSE_DETAIL")}>
                                            <span className="linkSpan" onClick={() => this.goToKey(uuid)}>Visualizza 
                                            <i className='fa fa-arrow-right' aria-hidden='true'></i></span>
                                          </div>
                                        </div>
                                      </li>
                                  )                                  
                                }
                                else{
                                  return (<li key={index}></li>);
                                }    
                              }) :"" }                                       
                            </ul>       
                          </div>
                      </div>
                    :
                      <div className="alert alert-danger" role="alert">
                          <strong>Attenzione!</strong>
                          Nessun evento presente
                      </div>                                                                                        
                    }
                  </Col>
                </Row>
              </Grid>
              </div>
        );
    }      

    getAll(){
      this.setState({
        selectedDate:"",
        currentValue: new Date()
      });
    }

    onCurrentValueChanged(e) {      
      this.setState({
        selectedDate: common.date.writePreviewDate(e.value),
        currentValue: e.value,
      });
    }

}

function CustomCell(cell) {
  let selectedDate=common.date.writePreviewDate(cell.date);
  let hiddenPointer=true;
  let classPointer="";  

  if(common.array.existObjectByKey(this.listData, "dataInizio", selectedDate)){
    hiddenPointer=false;
    classPointer="eventPointerBold";
  }

  return (
    <span className={classPointer}>
      { cell.text }
      <span className="eventPointer" hidden={hiddenPointer}>&nbsp;</span>
    </span>
  );
}


export default CourseList;