import React from 'react';
import {Col} from "react-bootstrap";

import UserCard from "components/Card/UserCard.jsx";

import errorImg from "assets/img/error.png";
import errorBg from "assets/img/error_bg.jpg";


class ErrorPage extends React.Component {
  render() {
    return (
      <div className="main-content">
        <Col md={12}>
          <UserCard
            bgImage={errorBg}
            avatar={errorImg}
            name="Errore nel caricamento della pagina"                      
            description=""
            socials=""
          />
        </Col>
      </div>
    );
  }
}

export default ErrorPage;