/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import {common} from 'react_library';

//import Button from "components/CustomButton/CustomButton.jsx";
import Form, {
  ButtonItem,
  SimpleItem,
  Label,
  GroupItem,
} from 'devextreme-react/form';

class LoginRestorePage extends Component {
  constructor(props) {
    super(props);

    this.buttonOptions = {
      text: 'Recupera',
      useSubmitBehavior: true,    
      class: 'btn btn-neutral'
    };

    this.state = this.initialState;

    this.handleSubmit = this.handleSubmit.bind(this);      
  }

  get initialState(){
    return {      
      login: {
        'username':'',
        'email':'',
        'aziendaId':""
      },      
    };
  } 

  async componentDidMount() {      
    let aziendaId= await common.service.getAziendaId();
    let login = this.state.login;
    login.aziendaId=aziendaId;
    this.setState({login:login});
  }

  render() {
    const login =  this.state.login;
    return (
      <Grid>
        <Row>
          <Col md={8} mdOffset={2}>
            <div className="header-text">
              <h2>Hai dimenticato le tue credenziali?</h2>
              <h4>Recupera la password</h4>
              <hr />
            </div>
          </Col>
          <Col md={4} mdOffset={2}>
            <div className="recuperaPassword">
             <form action="" onSubmit={this.handleSubmit}>                 
                <Form
                formData={login}
                readOnly={false}
                showColonAfterLabel={true}
                showValidationSummary={false}
                labelLocation="top"
                validationGroup="loginData"
              >         
                <GroupItem>
                  <SimpleItem dataField="username" editorType="dxTextBox">
                    <Label text="USERNAME"/>                    
                  </SimpleItem>  
                </GroupItem>    
                <GroupItem caption="Oppure">               
                  <SimpleItem dataField="email" editorType="dxTextBox">
                    <Label text="E-MAIL"/>                                           
                  </SimpleItem>             
                </GroupItem>                                                       

                <ButtonItem horizontalAlignment="center" 
                    buttonOptions={this.buttonOptions}
                  />
                        
              </Form>                  
            </form>
            </div>
            
          </Col>
          
        </Row>
      </Grid>
    );
  }

  handleSubmit(e) {
    let login=this.state.login;
    let thisClass=this;

    if(login.username === "" && login.email==="")
    {
      common.notify.fn_error("Almeno uno dei due perametri è obbligatorio");
    }
    else{
      common.service.postData(this,'/access/restoreCredential', {data:login})
        .then(data => {          
          common.notify.notifyServerResponse(thisClass,data, "Recupero credenziali effettuato corretamente. A breve riceverai una mail con le credenziali.");          
      });
    }

    e.preventDefault();
  }

}

export default LoginRestorePage;
