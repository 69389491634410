import React from 'react';
import Form, {
    ButtonItem,
    SimpleItem,
    Label,
    RequiredRule,GroupItem,EmailRule
  } from 'devextreme-react/form';
import { Grid, Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";
import CardError from "components/Card/CardError";
import List from 'devextreme-react/list';
import {common} from 'react_library';
import ArrayStore from 'devextreme/data/array_store';
import PlusComponent from "../../myComp/PlusComponent";
import File from "../../myComp/File";

import 'devextreme-react/autocomplete';
//import { Group } from 'devextreme-react/diagram';

class AccountGet extends PlusComponent {
  parent=null;
  dataSource=[];
  dataSourceContatti=[];
  tasks=[];
  contatti=[];

  constructor(props) {
    super(props, "account");

    const query = new URLSearchParams(this.props.location.search);
    this.parent = query.get('parent');  
    this.compilaDatiContatto = this.compilaDatiContatto.bind(this);  

    this.onSelectedItemsChange = this.onSelectedItemsChange.bind(this);
  }

  componentDidMount(){
    this.getData(true, {"data":{parent:this.parent}});
  }
  
  refreshState(data){    
    let stato = this.state;
    stato.account=data.default[0];
    stato.accountLogo=stato.account.wrmLoginFoto;
    stato.codiceUtente= stato.account.codiceUtente;
    this.contatti=stato.account.contatti;
        
    if(this.new){
      for (let key in stato.account.ruoli){
        stato.selectedItems.push(stato.account.ruoli[key]["uuid"]);
      }    
    }
    else if(stato.account["ruoliUtente"]!==undefined){
      for (let key in stato.account["ruoliUtente"]){
          let ruolo = stato.account["ruoliUtente"][key];
          stato.selectedItems.push(ruolo["uuid"]);
      }
    }

    this.dataSource = new ArrayStore({
      data: stato.account.ruoli,
      key: 'uuid'
    });

    
    this.dataSourceContatti = new ArrayStore({
      data: this.contatti,
      key: 'uuid',
    });    

    this.setState(stato);
  }

  get initialState(){       
    return {
      selectedItems:[],
      account: {     
      },
      accountLogo:[],
    };
  }

  render() {    
    if(this.parent!=null)
    {
      const {
        account
      } = this.state;


      let visibleAccount= false;
      if(common.session.fn_getUserUuid()!==account["uuid"] && account["uuid"]!==undefined){
        visibleAccount=true;
      }
        
      this.contattiOption={
        dataSource:this.dataSourceContatti,
        displayExpr:"iNominativo",
        valueExpr:"uuid" ,
        value:account["wrmLoginContactId"],
        onValueChanged:this.compilaDatiContatto,        
      };

      if(this.new && this.state.codiceUtente!=undefined){
        account["wrmLoginUserName"]= this.state.codiceUtente; 
      }

      return (
        
        <div className="main-content">
          <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title=""
                  ctAllIcons              
                  content={    
                    <React.Fragment>                                   
                                                    
                      <form action="" onSubmit={this.handleSubmit} encType="multipart/form-data">
                        <Form
                          formData={account}
                          readOnly={false}
                          showColonAfterLabel={true}
                          showValidationSummary={false}
                          labelLocation="top"
                          validationGroup="accountData"
                        >
                                              
                        <GroupItem colCount={2} >
                          <GroupItem colCount={2} caption="Dati">  
                            <SimpleItem dataField="wrmLoginUserName" editorType="dxTextBox">
                              <Label text="Username"/>
                              <RequiredRule message="Username obbligatorio" />                
                            </SimpleItem>  

                            <SimpleItem dataField="wrmLoginPassword" editorType="dxTextBox">
                              <Label text="Password"/>
                              <RequiredRule message="Password obbligatoria" />                
                            </SimpleItem>  

                            <SimpleItem dataField="wrmLoginContactId" editorType="dxSelectBox" editorOptions={this.contattiOption}>
                              <Label text="Contatto"/>                              
                            </SimpleItem> 

                            <SimpleItem dataField="wrmLoginName" editorType="dxTextBox">
                              <Label text="Nome"/>
                            </SimpleItem>  

                            <SimpleItem dataField="wrmLoginSurname" editorType="dxTextBox">
                              <Label text="Cognome"/>                            
                            </SimpleItem>       

                            <SimpleItem dataField="wrmLoginEmail" editorType="dxTextBox">
                              <Label text="E-mail"/>     
                              <EmailRule message="Email non valida" />                       
                            </SimpleItem>  

                            <SimpleItem dataField="wrmLoginEnabled" editorType="dxCheckBox" visible={visibleAccount} >
                              <Label text="Abilitato"/>                            
                            </SimpleItem>     
                          </GroupItem>                          
                          <GroupItem colCount={3} visible={visibleAccount} caption="Ruoli">                                
                              <List
                                dataSource={this.dataSource}
                                height={250}
                                keyExpr="uuid"
                                showSelectionControls={true}
                                selectionMode="all"
                                selectedItemKeys={this.state.selectedItems}
                                displayExpr="ruoloDescrizione"
                                onOptionChanged={this.onSelectedItemsChange}
                                >
                              </List>
                            </GroupItem>                            
                          </GroupItem>

                          <GroupItem colCount={1} >
                            <GroupItem colCount={1} caption="Foto profilo">
                              <File 
                                changeFile={this.fileFormChange} 
                                deleteFile={this.fileFormDelete}
                                allegati={this.state.accountLogo}
                                name="accountLogo"
                                buttonName="Carica immagine"
                                multiple={false}
                                accept="image/*"     
                                type=""                    
                              >                
                              </File>       
                            </GroupItem>
                          </GroupItem>
                                                    
                          <GroupItem colCount={6}>  
                            <ButtonItem horizontalAlignment="left" location="right"
                              buttonOptions={this.buttonSaveOptions}
                            />
                          </GroupItem>                  
                        </Form>                    
                      
                      </form>
                    </React.Fragment>
                    }
                  />
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      );
    }
    else{
      return (
        <CardError title="Accesso" message="Utente principale non definito"></CardError>
        );

    }

  }

  onSelectedItemsChange(args) {    
    if(args.name === 'selectedItemKeys') {            
      this.setState({
        selectedItems: args.value
      });
    }
  }

  compilaDatiContatto(args){
    if(args.event!=null){
      let account= this.state.account;    
     
          
      if(account.wrmLoginContactId!=undefined){
        let contatto= common.array.getObjectByKey(this.contatti, "uuid", account.wrmLoginContactId);

        account.wrmLoginName= contatto.pfNome;
        account.wrmLoginSurname=contatto.pfCognome;
        account.wrmLoginEmail= contatto.email;

        this.setState({
          account: account
        })
      }
    }

  
  }


  handleSubmit(e) {
    let account=this.state.account;
    let thisClass=this;
    
    let data ={};
    data.wrmLoginUserName=account.wrmLoginUserName;
    data.wrmLoginPassword=account.wrmLoginPassword;
    data.wrmLoginName=account.wrmLoginName;
    data.wrmLoginSurname=account.wrmLoginSurname;
    data.wrmLoginEnabled=(account.wrmLoginEnabled ? 1 : 0);
    data.ruoliAccount = this.state.selectedItems;
    data.wrmLoginEmail=account.wrmLoginEmail;
    data.wrmLoginFoto=this.state.accountLogo;
    data.wrmLoginBase = 0;
    data.wrmLoginParent= this.parent;
    data.wrmLoginContactId= account.wrmLoginContactId;

    let action="";

    if(this.new){
      action= "newAccess";      
    }
    else {
      action="updateAccess/"+account["uuid"];
    }

    if(data.wrmLoginUserName.indexOf(this.state.codiceUtente)!=0){
      common.notify.fn_error("Lo username deve iniziare per '"+this.state.codiceUtente+"'");
    }
    else{    
      common.service.postData(this,'/account/'+action, {data:data})
          .then(data => {
            common.notify.notifyServerResponse(thisClass,data, "Account memorizzato correttamente", function(){thisClass.resetPage(thisClass)});          
      });     
    }
    e.preventDefault();
  }

  resetPage(){
    this.props.history.push('/portal/user-page');   
  }
}

export default AccountGet;