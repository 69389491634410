import React from 'react';
import {Col} from "react-bootstrap";

import UserCard from "components/Card/UserCard.jsx";

import blockImg from "assets/img/block.png";
import deniedBg from "assets/img/denied_bg.jpg";


class ErrorPermission extends React.Component {
  render() {
    return (
      <div className="main-content">
        <Col md={12}>
          <UserCard
            bgImage={deniedBg}
            avatar={blockImg}
            name="Attenzione"                      
            description="Non hai i permessi per accedere alla pagina selezionata"
            socials=""
          />
        </Col>
      </div>
    );
  }
}

export default ErrorPermission;