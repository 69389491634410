import React from 'react';

export default function RenderVideoItem(data) {
  return (
    <div className="dashboardVideo">      
      <h1>{data.widgetTitolo}</h1>
      {data.permissionRemove ?
          <span className="spanRemove"><i className="fa fa-minus pointer" onClick={() => data.component.removeFromDashboardConfirm(data.uuid, data.actionRemoveDashboard)}></i></span>
        : ''}
      <div className="embed-responsive embed-responsive-16by9">
          <iframe title={data.widgetUrl} className='embed-responsive-item' src={data.widgetUrl}></iframe>
      </div>
    </div>
  );
}