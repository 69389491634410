import React from 'react';
import PlusComponent from "../../myComp/PlusComponent";
import ContentView from "../../myComp/ContentView";

class ContactList extends PlusComponent {
  constructor(props) {
    super(props, "contentContact");         
  }

  render(){     
    return (
        <div>
          <ContentView {...this.props} title="Contatti" controller="contentContact"></ContentView>
        </div>
      )
    }
}

export default ContactList;