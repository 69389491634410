import React from "react";
import Form, {
  ButtonItem,
  SimpleItem,
  Label,
  GroupItem,
  RequiredRule
} from 'devextreme-react/form';
import Rating from "react-rating";
import PlusComponent from "./PlusComponent";
import {common} from 'react_library';


class FormDetail extends PlusComponent {
    constructor(props) {
      super(props, "");
    }

    get initialState(){
      return {
        formDetail: {"details":{}},   
        numberOfRows:[""],   
      };
    }

    render(){
      return (
        <div>
          <div hidden={this.props.max<=this.state.numberOfRows.length}>
            <button className="btn btn-secondary btn-right" onClick={this.addRow.bind(this)}>Aggiungi</button>
          </div>

          <form action="" onSubmit={this.handleSubmit} encType="multipart/form-data">
            <Form
              formData={this.state.formDetail}
              readOnly={false}
              showColonAfterLabel={true}
              showValidationSummary={false}
              labelLocation="top"
              validationGroup="formData"                      
            >
            
            {this.state.numberOfRows!==undefined ? this.state.numberOfRows.map((v, i) => {
                return (
                  <GroupItem key={i} colSpan={5}>     
                    {this.props.form!==undefined ? this.props.form.map((value, index) => {
                        let uuid= value["uuid"];
                        let label = value["fieldLabel"];
                        var fieldType = value["fieldType"];
                        var required = value["fieldRequired"];

                        let initial="";
                        if(this.state.formDetail.details[i]!==undefined){
                          initial=this.state.formDetail.details[i][uuid];
                        }
                        
                        if(fieldType==="I"){
                          if(required){
                            return (   
                              <SimpleItem key={index} dataField={"details["+i+"]["+uuid+"]"} editorType="dxTextBox">
                                <Label text={label}/>
                                <RequiredRule message="Campo obbligatorio" />
                              </SimpleItem>  
                            )
                          }
                          else{
                            return (   
                              <SimpleItem key={index} dataField={"details["+i+"]["+uuid+"]"} editorType="dxTextBox">
                                <Label text={label}/>                            
                              </SimpleItem>  
                            )
                          }
                      }
                      else{
                        return (<GroupItem key={index}>
                          <Label text={label} />          
                          <Rating initialRating={initial} onChange={this.changeRate.bind(this, i, uuid)}/>
                        </GroupItem>);
                      }

                      }) :"" }                                                                                                   
                    </GroupItem>
                )}) : ""
              }
              
              <GroupItem>
                <ButtonItem horizontalAlignment="left"
                  buttonOptions={this.buttonSaveOptions}
                />
              </GroupItem>

            </Form>                      
          </form>       
        </div>
        );
    } 

    changeRate(formId, labelId, value){
      let formDetail= this.state.formDetail;

      if(formDetail.details[formId]===undefined){
        formDetail.details[formId]={};
      }
      formDetail.details[formId][labelId]= value.toString();

      this.setState({formDetail: formDetail});
    }
      
    handleSubmit(e) 
    {
      let forms=[];

      let form= this.state.formDetail.details;
    
      for (let key in form){
        let details = form[key];
        let data={};

        data["formId"]=this.props.formUuid;
        data["entity"]=this.props.entity;
        data["recordId"]=this.props.recordId;
        data["formType"]=this.props.formType;
        data["rows"]=details;

        forms.push(data);        
      }      

      e.preventDefault();
      
      if(forms.length===0){
        common.notify.fn_error("Compilare i campi prima di procedere con il salvataggio");
      }
      else{
        this.props.onSave(forms);      
        this.setState({formDetail: {"details":{}},numberOfRows:[""]})
      }     
      
    }

    addRow(){
      let numeroRighe= this.state.numberOfRows;
      numeroRighe.push("");
      this.setState({numberOfRows: numeroRighe});
    }
}

export default FormDetail;