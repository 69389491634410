"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  /**
   *  @description Nasconde il loader nella pagina
   *  @param {Object} component : componente di riferimento
   */
  hideLoadPanel: function hideLoadPanel(component) {
    var state = component.state;
    state.loadPanelVisible = false;
    component.setState(state);
  },

  /**
   *  @description Visualizza il loader nella pagina
   *  @param {Object} component : componente di riferimento
   */
  showLoadPanel: function showLoadPanel(component) {
    var state = component.state;
    state.loadPanelVisible = true;
    component.setState(state);
  }
};
exports.default = _default;