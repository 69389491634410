import React from 'react';
import { Grid, Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";
import {common} from 'react_library';
import Loader from "../../myComp/Loader";
import FormDetail from "../../myComp/FormDetail";
import PlusComponent from "../../myComp/PlusComponent";
import img from "../../assets/img/eventi.jpg";

class CourseGet extends PlusComponent {
  API_VALUTA="";
  API_ISCRIVITI="";

  constructor(props) {
    super(props, "course");

    this.API_VALUTA= "/"+this.controller+"/evaluate";
    this.API_ISCRIVITI= "/"+this.controller+"/registration";

  }

  componentDidMount(){  
    this.getData(true);    
  }

  refreshState(data){    
    let stato = this.state;
    console.log(data);
    stato.course=data.default[0];
    this.setState(stato);
  }

  get initialState(){       
    return {
      course: {               
      },
    };
  }

  render() {        
      const {course} = this.state;                  
      
      if(course.uuid!==undefined){
      let programma= course.corsoProgramma.trim();
      let timerGiorni="00";
      let timerOre="00";
      let timerMinuti="00";
      let timerSecondi="00";

      timerGiorni = course.timerGiorni > 0 ? timerGiorni= course.timerGiorni : "00";
      timerOre = course.timerOre > 0 ? timerOre= course.timerOre : "00";
      timerMinuti = course.timerMinuti > 0 ? timerMinuti= course.timerMinuti : "00";
           
      let iscriviti= course.enabledIscrizione;
      let valuta = course.enabledValutazione;
      let dataInizio=common.date.writePreviewDateTimeByDB(course.corsoDataInizio);
      let dataFine=common.date.writePreviewDateTimeByDB(course.corsoDataFine);
      
      
      return (
        <div className="main-content">
          <Loader visible={this.state.loadPanelVisible}></Loader>
          <div className="content">
            <Grid fluid>
              <Row className="rowContent">
                <Col md={6}>
                  <Card
                      title={course.corsoTitolo}
                      category={course.corsoTipologiaNome}
                      ctAllIcons              
                      stats={
                        <div>
                          <i className="fa fa-clock-o" /> {dataInizio} - {dataFine}<br></br>
                          <i className="fa fa-user" /> {course.corsoRelatoreNome}
                        </div>
                      }
                      content={    
                        <div>
                          <div><img src={img} alt=""></img></div>  
                          <p dangerouslySetInnerHTML={{__html: course.corsoDescrizione}}></p>
                        </div>
                      }>                                            
                  </Card>   

                  <div hidden={programma==="" || programma===undefined}>
                    <Card
                      title="Programma"
                      ctAllIcons              
                      content={    
                        <p dangerouslySetInnerHTML={{__html: programma}}></p>
                      }>                      
                    </Card>                     
                  </div>


                  {course.corsiAllegati!==undefined && course.corsiAllegati.length>0 ? 
                    <Card
                        title="Allegati"
                        ctAllIcons              
                        content={    
                          <div id="files">
                            {course.corsiAllegati.map((allegato, indexAllegato)=>{
                              let type=allegato["type"];
                              let content=allegato["content"];
                              let nome=allegato["descrizione"];
                              let link= "data:"+type+";base64," + content;                                                                            
                              
                              return ( 
                              <div key={indexAllegato} className=" widgetContent">                                                
                                <a href={link} download={nome} className="btn btnContent">{nome.toUpperCase()}</a>                                              
                              </div>);

                            })
                          }                           
                          </div>
                        }>                      
                    </Card> 
                  :""}                            
                                   
                </Col>
                <Col md={6}>
                  <Card
                    title=""
                    ctAllIcons              
                    content={    
                      <div id="event__timer">
                          <span>{timerGiorni}</span> days 
                          <span>{timerOre}</span> hr 
                          <span>{timerMinuti}</span> min 
                          <span>{timerSecondi}</span> sec 
                      </div>
                    }>                      
                  </Card>    
                                                  
                  {iscriviti===true && !this.checkFunctionality("COURSE_REGISTRATION") ? 
                    <Card
                      title="Iscriviti"
                      ctAllIcons              
                      content={    
                        <FormDetail titolo="Iscriviti" {...this.props} formType={course.formIscrizioneType} form={course.formIscrizione} formUuid={course.formIscrizioneUuid} entity="corsi" recordId={course.uuid} max={3} onSave={this.iscriviti.bind(this)} ></FormDetail>
                      }>                      
                    </Card>
                  : ""}       


                  {valuta && !this.checkFunctionality("COURSE_EVALUATE") ?
                    <Card
                      title="Valuta"
                      ctAllIcons              
                      content={    
                        <FormDetail {...this.props} titolo="Valuta" form={course.formValutazione} formType={course.formValutazioneType} formUuid={course.formValutazioneUuid} entity="corsi" recordId={course.uuid} max={1} onSave={this.valuta.bind(this)}></FormDetail>
                      }>                      
                    </Card>                    
                  : ""}                   
                </Col>
              </Row>
              
              
                                                   
            </Grid>
          </div>
        </div>
      );   
      }
      else return(<div></div>);
  }

  iscriviti(data){
    let thisClass= this;
    common.service.postData(this, this.API_ISCRIVITI, {data:data})
      .then(result => {
        common.notify.notifyServerResponse(thisClass, result, "Iscrizione effettuata correttamente", function(){
          thisClass.getData(true); 
      });
    });  
  }

  valuta(data){
    let thisClass= this;
    common.service.postData(this, this.API_VALUTA, {data:data})
      .then(result => {
        common.notify.notifyServerResponse(thisClass, result, "Valutazione inserita correttamente", function(){
          thisClass.getData(true); 
      });
    });  
  }
}

export default CourseGet;