import { Component } from "react";
import {common} from 'react_library';

class PlusComponent extends Component {
  API_GET = '/get/';
  API_NEW = '/new';
  API_LIST = '/listP';
  API_GRID = '/gridP';
  API_UPDATE='/update/';
  API_FORM='/getFormP/';
  API_DASHBOARD='/dashboardP/';
  API_DELETE='/delete/';

  new = true;
  uuid= null;
  controller="";
  disableOption={};
  buttonSaveOptions={};
  dateBoxOptions={};

  constructor(props, controller) {
    super(props);
    this.props.changeTitle("");

    this.controller=controller;    
    this.API_GET= "/"+controller+this.API_GET;
    this.API_NEW= "/"+controller+this.API_NEW;
    this.API_LIST= "/"+controller+this.API_LIST;
    this.API_GRID= "/"+controller+this.API_GRID;
    this.API_UPDATE= "/"+controller+this.API_UPDATE;
    this.API_FORM= "/"+controller+this.API_FORM;
    this.API_DASHBOARD= "/"+controller+this.API_DASHBOARD;
    this.API_DELETE="/"+controller+this.API_DELETE;
    
    const query = new URLSearchParams(this.props.location.search);
    this.uuid = query.get('uuid');  

    if(this.uuid!==null && this.uuid!==""){      
      this.new=false;
    }
    else{
      this.uuid="";
    }

    // Proprietà per disabilitare il campo nel caso di modifica form
    this.disableOption={
      disabled: !this.new,
    };


    // Proprietà di default per un bottone SAVE
    this.buttonSaveOptions = {
      text: 'Salva',
      type: 'success',
      elementAttr: { 
        class:"btn btn-success"
      },
      useSubmitBehavior: true
    };    
        
    // Proprità di default di una data scadenza
    this.dateScadenza = {
      displayFormat:"dd/MM/yyyy",
      useMaskBehavior:true,
      min: new Date(),
      showClearButton:true,        
      dateSerializationFormat :"yyyy-MM-dd 00:00:00",
      disabled: !this.new,
      invalidDateMessage:
        'La data deve essere nel seguente formato: dd/MM/yyyy'
    };

    this.state = this.initialState;   
    this.handleSubmit = this.handleSubmit.bind(this);  
   // this.handleFile = this.handleFile.bind(this);  
    this.toolbarAdd= this.toolbarAdd.bind(this);
    this.fileFormChange=this.fileFormChange.bind(this);
    this.fileFormDelete=this.fileFormDelete.bind(this);
  }


  /**
   * Cancella un documento dallo stato di una form
   * @param {string} nome : nome della variabile di stato
   * @param {integer} index : indice del documento
   */
  fileFormDelete(nome, index){
    let stato= this.state;      
    stato[nome][index]["mode"]="DELETE";
    this.setState({stato});
  }

  /**
   * Carica i file di un file uploader sullo stato
   * @param {string} nome  : nome della variabile di stato
   * @param {string} type  : key tipologia da caricare
   * @param {component} e : devexpress fileuploader
   */
  fileFormChange(nome, type, e){
    let stato= this.state;      

    let files=e.component.option('value');

    for (let key in files){
      common.file.getBase64(files[key], (result) => {
        let allegato={};
        allegato.name=files[key]['name'];
        allegato.size=files[key]['size'].toString();            
        allegato.type=files[key]['type'];   
        allegato.mode="INSERT";     
        allegato.keyTipologia=type;            
        allegato.content=result;         

        if(stato[nome]===undefined){
          stato[nome]=[];
        }
        stato[nome].push(allegato);
        this.setState(stato);         
      });   
   }

  }  

  /**
   * Dato l'uuid del record redirige alla pagina di dettaglio
   * @param {string} uuid 
   */
  goToKey(uuid){
    this.props.history.push('/portal/'+this.controller+'/get?uuid='+uuid);   
  }

  /**
   * Redirige alla pagina di lista
   */
  backList(data){    
    this.props.history.push("/portal/"+this.controller+"/listP");       
  }

  /**
   * Redirige alla pagina di griglia
   */
  backGrid(thisClass,data){    
    this.props.history.push("/portal/"+this.controller+"/gridP");       
  }

  /**
   * Estrae i dati della form o i dati del record (se settato l'uuid)
   * @param {boolean} form : se settato a true viene chiamato il metodo getFormP sul webservice anzichè il metodo GET. Il metodo get estrae solo 
   * il record pulito mentre il getFormP estrae il record e tutti gli oggetti collegati (Esempio combo ecc..)
   * @param {object} inputData: data da passare sul getData
   */
  getData(form = false, inputData={}, dashboard=false){
    let thisClass=this;       

    let action="";

    if(form){
      action=this.API_FORM; 
    }
    else if(dashboard){
      action=this.API_DASHBOARD;
    }
    else{
      action=this.API_GET;
    }
    
    if(form || this.uuid!=="" || dashboard){
      common.service.postData(this,action+this.uuid,inputData)
        .then(data => {        
          common.notify.notifyServerResponse(thisClass, data, "", function(data){thisClass.refreshState(data)});          
      });
    }
  }

  /**
   * Chiama il web service per estrarre la lista dei dati e compila lo STATE.LIST
   */
  getList(inputData={}){
    let thisClass= this;
    common.service.postData(this,this.API_LIST, inputData)
    .then(data => {        
      common.notify.notifyServerResponse(thisClass,data, "", function(data){        
        thisClass.refreshList(data)       
      });          
    });   
  } 


  /**
   * Chiama il web service per estrarre la griglia dei dati e compila lo STATE.GRID
   */
  getGrid(filterData={}){
    let thisClass= this;
    common.service.postData(this,this.API_GRID, {data: filterData})
    .then(data => {        
      common.notify.notifyServerResponse(thisClass,data, "", function(data){
        let stato = thisClass.state;
        stato.grid=data;
        thisClass.setState(stato);
      });          
    });   
  } 

  /**
   * Memorizza il dato, visualizza il messaggio di ok e redirige alla griglia o alla lista
   * @param {Object} data : dati da salvare
   * @param {string} message : messaggio di salvataggio corretto
   * @param {redirect} redirect : redirige su Griglia(G) o su Lista(L) o fa il refresh della pagina (R). 
   */
  save(data, message="", redirect="G"){
    let thisClass=this;       

    let action="";

    if(this.new){
      action= this.API_NEW;
    }
    else {
      action=this.API_UPDATE+this.uuid;
    }

    common.service.postData(this, action, {data:data})
        .then(result => {
          common.notify.notifyServerResponse(thisClass, result, message, function(){
            if(redirect==="G")
              thisClass.backGrid(thisClass, result);
            else if(redirect==="L")
              thisClass.backList(thisClass);
            else if(redirect==="R")
              thisClass.refreshPage(thisClass);
            });          
    });    
  }


  /**
   * Cancella il dato e lancia il refresh della pagina
   */
  delete(message=''){
    let thisClass=this;       

    let action="";
    action=this.API_DELETE+this.uuid;    

    common.service.postData(this, action, {})
        .then(result => {
          common.notify.notifyServerResponse(thisClass, result, message, function(){
            thisClass.refreshPage();     
          })
    });    
  }

  checkPermission(name){
    return !common.service.checkPermission(name);
  }

  checkFunctionality(name){
    return !common.service.checkFunctionality(name);
  }

  /**
   * Metodo per settare la toolbar con il tasto di add. Va lanciato al onToolbarPreparing
   */
  toolbarAdd(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        onClick: this.toolbarAddClick.bind(this)
      }
    });
  }

  /**
   * Setta i file sullo stato
   * @param {object} e : valore dei file in base64
   * @param {string} nomeProprieta : nome proprietà dello stato
   */
  /*handleFile(e, nomeProprieta){    
    let stato = this.state;
    stato[nomeProprieta]=e;
    this.setState(stato);
  }*/

  /**
   * Metodi da ridefinire
   */
  refreshState(data){
  }

  refreshList(data){
    let stato = this.state;
    if(data["default"]!==undefined){
      stato.list=data["default"];
    }else{
      stato.list=data;
    }
    this.setState(stato);
  }

  refreshPage(){

  }

  toolbarAddClick(e){

  }

  handleSubmit(e) {    
    e.preventDefault();
  }
  
  get initialState(){       
    return {};
  }
}

export default PlusComponent;