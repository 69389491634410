import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import Loader from "../../myComp/Loader";

import {common} from 'react_library';
import PlusComponent from "../../myComp/PlusComponent";


class VersionList extends PlusComponent {
    listData=[];

    constructor(props) {
      super(props, "version");
   
      this.state = {
        list: [],
        product:"",
      };  
    }

    componentDidMount() {
      this.getList();
    }  

    render(){     
        const { list } = this.state;

        return (
          <div className="main-content">
              <Loader visible={this.state.loadPanelVisible}></Loader>
              <Grid fluid>
                <Row>
                  <Col md={4}>                                               
                    <h3>Prodotti</h3>                            
                    {list.length>0 ?
                      <div id="products">
                        {list!==undefined ? list.map((value, index) => {
                            let titolo=value["titolo"];
                            let descrizione=value["descrizione"];
                            let prodottoid=value["prodottoid"];

                            let selectedClass="";
                            if(prodottoid===this.state.product.prodottoid){
                                selectedClass="selected";
                            }

                            return(
                                <div key={index} className={"mdc-card card-product "+selectedClass} onClick={this.getVersion.bind(this, prodottoid)}>
                                    <div className="card-body text-center">
                                        <h4>{titolo}</h4>
                                        {descrizione}
                                    </div>
                                </div>
                                );
                                    
                        }) :"" }                                       
                      </div>
                    : ""                                                                                  
                    }
                  </Col>


                  <Col md={8}>                                               
                    <h3>Versioni</h3>                            
                    <div className="mdc-card">
                        <div className="card-body">                      
                            <ul className="list-group list-group-fit" id="versions">
                                {this.state.product["versions"]!==undefined ? this.state.product["versions"].map((value, index) => {
                                    let uuid = value["uuid"];
                                    let isBeta = value["isBeta"];
                                    let nomeversione = value["nomeversione"];
                                    let titolo = value["titolo"];
                                    let installer = value["installer"];
                                    let datarilascio_str = value["datarilascio_str"];
                                    let descrizione=value["descrizione"];

                                    if (isBeta === "1")
                                        descrizione = "BETA VERSION " + descrizione;

                                    return(
                                        <div key={index}>
                                            <li className="list-group-item forum-thread">
                                                <div className="media align-items-center">
                                                    <div className="media-left">
                                                        <div className="forum-icon-wrapper">
                                                            <span className="forum-thread-icon">
                                                                <i className="fa fa-file-text"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <div className="d-flex align-items-center">
                                                            <span onClick={() => this.goToKey(uuid)} className="text-body" >
                                                                <p><strong>{nomeversione+" - "+titolo}</strong></p>
                                                            </span>
                                                            <small className="ml-auto text-muted">{datarilascio_str}</small>
                                                        </div>
                                                        {installer!=="" ? 
                                                            <p className="installer"><b>INSTALLER: </b> ver. {installer}</p>
                                                        : ""}

                                                        <p className="text-black-70">{descrizione}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </div>
                                        );
                                            
                                }) :"" }         
                            </ul>                              
                        </div>
                    </div>                    
                  </Col>
                </Row>
              </Grid>
              </div>
        );
    }      

    getVersion(prodottoid){
        let prodotto = common.array.getObjectByKey(this.state.list, "prodottoid", prodottoid);
        this.setState({"product": prodotto});
    }

}


export default VersionList;

















/*$(function () {

     
        divProducts.find(".card").click(function () {
            divVersions.parent().parent().hide();
            divProducts.find(".card").removeClass("selected");
            $(this).addClass("selected");
            divVersions.empty();

            var prodottoId = $(this).attr("rel");
            var selectString2 = "SELECT v.titolo as nomeversione, v.abilitaDownload, l.titolo, l.descrizione, l.uuid, l.rilasciaProgrammi, DATEFORMAT(l.datarilascio,'dd/MM/yyyy') as datarilascio_str, v.isBeta, v.isMaster, v.versioneId, " +
                                "(SELECT LIST(iv.titolo,' - ') FROM " +
                                    "PORTAL_installerProdotti ip " +
                                    "LEFT JOIN PORTAL_Prodotti p on ip.installerId=p.prodottoid " +
                                    "LEFT JOIN PORTAL_Versioni iv on iv.prodottoid=ip.installerId AND iv.versioneid=ip.installerVersioneId "+
                                    "WHERE ip.prodottoId= l.prodottoId and ip.prodottoVersioneId =v.versioneId AND p.abilitato= 1 AND iv.abilitato=1 and iv.abilitaDownload=1) " +
                                "as installer " +
                                "FROM SERVER_liveUpdate l, PORTAL_versioni v WHERE (v.isBeta = 0 or (v.isBeta = 1 and  (SELECT sp.partnerEnableBetaVersions FROM SERVER_partners sp WHERE sp.partnerERPId = '" + var_id_utente + "')  = 1     )) and v.isReleased = 1 and l.abilita = 1 and v.abilitato = 1 and l.isCustom = 0 and l.prodottoId = v.prodottoId and v.versioneid = l.versioneid and l.prodottoid = '" + prodottoId + "' ORDER BY datarilascio DESC";
            

            var jsonResult2 = fn_serviceBySelect_json(selectString2);
            var hasFields = false;

            $.each(jsonResult2, function (i, rec) {
                var uuid = rec["uuid"];
                var versioneId = rec["versioneId"];
                var isBeta = rec["isBeta"];
                var isMaster = rec["isMaster"];
                var nomeversione = rec["nomeversione"];
                var titolo = rec["titolo"];
                var abilitaDownload = rec["abilitaDownload"];
                var installer = rec["installer"];
                var datarilascio_str = rec["datarilascio_str"];



                var li = $(document.createElement("li")).addClass("list-group-item forum-thread");
                var div = $(document.createElement("div")).addClass("media align-items-center");
                var icon = $('<div class="media-left"><div class="forum-icon-wrapper"><span class="forum-thread-icon"><i class="material-icons">description</i></span></div></div>');

                var divMediaBody = $(document.createElement("div")).addClass("media-body");
                var line1 = $(document.createElement("div")).addClass("d-flex align-items-center");

                var title = $(document.createElement("a")).addClass("text-body")
                       .attr("data-prodotto", prodottoId).attr("data-versione", versioneId).attr("rel", uuid)
                       .attr("href", "./download/download-dettaglio.aspx?idrecord=" + uuid).attr("target", "_blank");
                var strong = $(document.createElement("strong")).html(nomeversione + " - " + titolo);
                title.append(strong);

                var data_str = '<small class="ml-auto text-muted">' + datarilascio_str + '</small>';
          

                var data = $(data_str);

                line1.append(title).append(data);
                
                if (isBeta == "1")
                    rec["descrizione"] = "BETA VERSION " + rec["descrizione"];

                var p = $(document.createElement("p")).addClass("text-black-70").html(rec["descrizione"]);


                divMediaBody.append(line1);

                if (installer != "") {
                    divMediaBody.append($(document.createElement("p")).attr("style","margin-bottom:0px;color: #725027;").html("<B>INSTALLER: </B> ver. " + installer));
                }

                divMediaBody.append(p);

               

                div.append(icon).append(divMediaBody);
                li.append(div);
                divVersions.append(li);

                hasFields = true;
            });                      

            if (hasFields)
                divVersions.parent().parent().show();
               
        })

    });
</script>

<style type="text/css">
    #WeCliPortal_Products .card-body {
    padding: 5px;
    }

    #WeCliPortal_Products #products {
        cursor: pointer;
    }

    #WeCliPortal_Products #products .selected {
        background-color: #fbecda !important;
    }

    #WeCliPortal_Products .forum-thread-icon {
       color: #fbecda;
       border:1px solid #fbecda;
    }

    #WeCliPortal_Products .list-group-item {
        padding-bottom: 20px;
        padding-top: 20px;
    }

    #WeCliPortal_Products .list-group {
        margin-bottom: 0px;
    }

</style>

<div>
    <div class="row" id="WeCliPortal_Products">
        <div class="col-md-4 col-lg-3">
            <h2>Prodotti</h2>
            <div id="products">

            </div>
        </div>
        <div class="col-md-8 col-lg-9">
            <h2>Versioni</h2>
            <div class="card" style="display:none">
                <div class="card-body">                      
                    <ul class="list-group list-group-fit" id="versions">
                    </ul>
                </div>
            </div>            
        </div>
    </div>
</div>*/