import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Loader from "../../myComp/Loader";
import FilterResults from 'react-filter-search';

import {common} from 'react_library';
import img from "assets/img/icon_p2_demo.jpg";
import PlusComponent from "../../myComp/PlusComponent";


class DemoList extends PlusComponent {
    constructor(props) {
      super(props, "demo");
   
      this.state = {
        list: [],
        value: ''
      };  
    }

    componentDidMount() {
      this.getList();
    }

    handleChange = event => {
      const { value } = event.target;
      this.setState({ value });
    };

    render(){     
        const { list, value } = this.state;

        return (
          <div className="main-content">
              <Loader visible={this.state.loadPanelVisible}></Loader>
              <Grid fluid>
                <Row>
                  <Col md={12}>                   
                        <div className="btnTopGrid" hidden={this.checkFunctionality("DEMO_NEW")}>                          
                          <NavLink
                                to={{pathname:"/portal/demo/get"}}
                                className="btn btn-secondary"
                                activeClassName="active"
                          >Nuova demo</NavLink>                                   
                        </div>       

                        <div className="search-form col-lg-12">
                          <input type="text" className="form-control" value={value} onChange={this.handleChange} placeholder="Cerca..."/>
                        </div>

                        <FilterResults
                          value={value}
                          data={list}
                          renderResults={results => (
                            <div>
                              {results!==undefined ? results.map((value, index) => {
                                  let uuid= value["demoId"];
                                  let richiestaDa = value["richiestaDa"];
                                  let richiesta = value["richiesta"];
                                  let ragionesociale = value["ragionesociale"];
                                  let data1 = common.date.writePreviewDateByDB(value["data1"]);
                                  let data2 = common.date.writePreviewDateByDB(value["data2"]);
                                  let stato = value["stato"];
                                  let dataConf= common.date.writePreviewDateByDB(value["dataconfermata"]);
                                  
                                  return (
                                    <div className="col-lg-4" key={uuid}>
                                      <div className="myCard card">
                                        <div className="card-header">
                                          <div className="media align-items-center">
                                            <div className="media-left">
                                              <img alt="" src={img} width={50} className="rounded-circle"></img>
                                              </div>
                                              <div className="media-body"><h4 className="card-title ragioneSociale">{ragionesociale}</h4>
                                              <p className="card-subtitle sottotitolo">RICHIESTO DA: {richiestaDa} PER IL {data1+" - "+data2}</p>
                                              </div>
                                          </div>
                                        </div>
                                        <div className="card-body">
                                          <span className="lblrichiesta testo">{richiesta}</span>
                                          {stato==="1" ?
                                            <span className="lbldataConf_str stato">{"Confermata per il "+dataConf}</span>
                                          : stato==="2" ? 
                                          <span className="lblNonConf_str stato">Non confermata</span>
                                          :''}                                        
                                        </div>
                                        <div className="card-footer">
                                          <div className="btnFull btn btn-secondary calendarButtons btnFunctions btnLink" onClick={() => this.goToKey(uuid)}><span>VISUALIZZA RICHIESTA</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }) : ""}
                            </div>
                            )}
                          />                                                                                                            
                  </Col>
                </Row>
              </Grid>
              </div>
        );
    }         
}

export default DemoList;