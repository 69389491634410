import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import UserCard from "components/Card/UserCard.jsx";
import {Grid,Row,Col} from "react-bootstrap";

import {common} from 'react_library';
import errorImg from "assets/img/error.png";
import errorBg from "assets/img/error_bg.jpg";

class LoginBackgroundPage extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = this.initialState;
  }

  get initialState(){
    return {     
      homePage:"",
      redirectHome:false,
      error:"",
      redirectLogin:false,
    };
  }


  async componentWillMount() {      
    this._isMounted = true;

    const isLoggedIn=common.session.fn_isLogged();
    let aziendaId= await common.service.getAziendaId();
    let homePage=await common.session.fn_getHomePage();
    
    let error="";

    if(isLoggedIn){  
        this.setRedirectHome(homePage);          
    }
    else{
      console.log(this.props);
      common.session.fn_saveSessionGetPost(this.props);

      let isGestionale=common.session.fn_isGestionale();      
      const isSetCliente= common.session.fn_isSetCliente();
      const credentialInBackground=common.session.fn_credentialInBackground();

      if(isGestionale){
        if(isSetCliente){
          await this.loginGestionale(aziendaId);              
        }        
        else{          
          error="Codice cliente obbligatorio per effettuare il login";
          let stato = this.state;
          stato.error=error;
          this.setState(stato);  
        }
        this.setRedirectLogin();          
      }
      else if(credentialInBackground){
        let username=common.session.fn_getUsernameGet();
        let password = common.session.fn_getPasswordGet();

        if(username!=="" && password!==""){
          await this.loginBackground(username, password, aziendaId);
        }
        else{
          error="Username e password obbligatorie per effettuare il login";
          let stato = this.state;
          stato.error=error;
          this.setState(stato);  
        }
        this.setRedirectLogin();
      }
      else{
        localStorage.clear();
      }
    }                   
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setRedirectHome(homePage){
    if(this._isMounted){
      let stato= this.state;
      stato.redirectHome=true;
      stato.homePage=homePage;    
      this.setState(stato);    
    }
  }

  setRedirectLogin(){
    if(this._isMounted){
      let stato=this.state;
      stato.redirectLogin=true;
      this.setState(stato);
    }
  }

  render() {    
    const redirectHome = this.state.redirectHome;
    const homePage=this.state.homePage;
    const error=this.state.error;
    
    if(redirectHome){    
      return <Redirect to={homePage}/>       
    }
    else if(error!=="" && error!==undefined){
        return (
          <Grid>
            <Row>
              <Col md={4} sm={6} mdOffset={4} smOffset={3}>
                <UserCard 
                  addClass="no-padding title-min"
                  bgImage={errorBg}
                  avatar={errorImg}
                  name={error}                     
                  description=""
                  socials=""
                />
              </Col>
            </Row>
        </Grid>
        );
    }
    else{
      return <Grid></Grid>
    }
   
  }

  async loginGestionale(aziendaId){
    let dataLogin= {
      'clienteId':common.session.fn_getClienteUuid(),    
      'aziendaId':aziendaId
    }
    
    await this.callLogin(dataLogin);
  }

  async loginBackground(username, password, aziendaId){
    let dataLogin= {
      'username':username,    
      'password':password,
      'aziendaId':aziendaId
    }
    
    await this.callLogin(dataLogin);
          
  }

  async callLogin(dataLogin){
    let thisClass=this;

    let data = await common.service.postData(this,'/access/getInfo', {data:dataLogin}, false);

    let message= common.notify.notifyServerResponse(thisClass,data, "", function(result){thisClass.afterLogin(result)}, '', false, true);       
    if(message!==""){
      let stato = this.state;
      stato.error=message;
      stato.redirectHome=false;
      this.setState(stato);
    }    
  }

  afterLogin(data){
    const serverResponse= data["default"];

//    serverResponse["password"]=this.state.login.password;

    // Setto in sessione le variabili di login
    common.session.fn_saveSession(serverResponse);

    // Location reload con refresh della pagina
    window.location.reload(true);
  }

}

export default LoginBackgroundPage;