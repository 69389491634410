/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {common} from 'react_library';
//import logo from "assets/img/logo.png";

class PagesHeader extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      width: window.innerWidth,
      sidebar:""
    };
  }
  // function that sets the class to active of the active page
  activeRoute(routeName) {
    return window.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // function that shows/hides sidebar on responsive
  mobileSidebarToggle(e) {
    document.documentElement.classList.toggle("nav-open");
  }
  updateWidth() {
    this.setState({ width: window.innerWidth });
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateWidth.bind(this));
    let sidebar=await common.service.getSidebar();
    this.setState({sidebar: sidebar});
  }
  
  render() {
    return (
      <Navbar
        collapseOnSelect
        inverse
        className="navbar-primary navbar-transparent navbar-absolute"
      >
        <Navbar.Header>
          <Navbar.Brand>
            <NavLink to={"/dashboard"} className="nav-link">              
              <div className="logo-img logo-auth">
                  <img src={process.env.PUBLIC_URL+"/logo.png"} alt="react-logo" />
              </div>              
              {this.state.width > 429
                ? this.state.sidebar
                :  this.state.sidebar
              }
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </Navbar.Header>

        {this.props.showButtons===true ?
          <Navbar.Collapse>
            <ul className="nav navbar-nav navbar-right">
              <li className={this.activeRoute("login")}>
                <NavLink to={"/auth/login"} className="nav-link">
                  <i className="fa fa-drivers-license-o" />
                  <p>Login</p>
                </NavLink>
              </li>
              <li className={this.activeRoute("restore-page")}>
                <NavLink to={"/auth/restore-page"} className="nav-link">
                  <i className="fa fa-user-circle-o" />
                  <p>Recupera credenziali</p>
                </NavLink>
              </li>
              <li className={this.activeRoute("lock-screen")}>
                <NavLink to={"/auth/lock-screen"} className="nav-link">
                  <i className="fa fa-lock" />
                  <p>Blocca schermo</p>
                </NavLink>
              </li>            
            </ul>
          </Navbar.Collapse> : ""
        }   
      </Navbar>
    );
  }
}

export default PagesHeader;

/**
 * <li>
              <NavLink to={"/dashboard"} className="nav-link">
                <i className="fa fa-th-list" />
                <p>Dashboard</p>
              </NavLink>
            </li>
 */